export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const GET_ALL_CUSTOMER = "GET_ALL_CUSTOMER";
export const CUSTOMER_ADD_ITEM = "CUSTOMER_ADD_ITEM";
export const CUSTOMER_UPDATE_ITEM = "CUSTOMER_UPDATE_ITEM";

export const CUSTOMER_SEND_MESSAGE = "CUSTOMER_SEND_MESSAGE";
export const CUSTOMER_IMPORT_CONTACTS = "CUSTOMER_IMPORT_CONTACTS";
export const CUSTOMER_DELETE_CONTACT_ITEM = "CUSTOMER_DELETE_CONTACT_ITEM";
export const CUSTOMER_GET_ALL_CONTACTS = "CUSTOMER_GET_ALL_CONTACTS";
export const CUSTOMER_CONTACTS_ADD_ITEM = "CUSTOMER_CONTACTS_ADD_ITEM";
export const CUSTOMER_CONTACTS_UPDATE_ITEM = "CUSTOMER_CONTACTS_UPDATE_ITEM";
export const CUSTOMER_DELETE_ALL_CONTACTS = "CUSTOMER_DELETE_ALL_CONTACTS";

export const CUSTOMER_AFFILIATE_UPDATE_ITEM = "CUSTOMER_AFFILIATE_UPDATE_ITEM";
export const CUSTOMER_AFFILIATE_ADD_ITEM = "CUSTOMER_AFFILIATE_ADD_ITEM";
export const CUSTOMER_AFFILIATE_GET_ITEMS = "CUSTOMER_AFFILIATE_GET_ITEMS";
export const CUSTOMER_AFFILIATE_DELETE_ITEM = "CUSTOMER_AFFILIATE_DELETE_ITEM";

export const CUSTOMER_ADS_DELETE_ITEM = "CUSTOMER_ADS_DELETE_ITEM";
export const CUSTOMER_ADS_GET_ITEMS = "CUSTOMER_ADS_GET_ITEMS";
export const CUSTOMER_ADS_ADD_ITEM = "CUSTOMER_ADS_ADD_ITEM";
export const CUSTOMER_ADS_UPDATE_ITEM = "CUSTOMER_ADS_UPDATE_ITEM";

export const CUSTOMER_SPONSORED_ADD_ITEM = "CUSTOMER_SPONSORED_ADD_ITEM";
export const CUSTOMER_SPONSORED_UPDATE_ITEM = "CUSTOMER_SPONSORED_UPDATE_ITEM";
export const CUSTOMER_SPONSORED_GET_ITEMS = "CUSTOMER_SPONSORED_GET_ITEMS";
export const CUSTOMER_SPONSORED_DELETE_ITEM = "CUSTOMER_SPONSORED_DELETE_ITEM";

export const CUSTOMER_EMAIL_ADD_TEMPLATE = "CUSTOMER_EMAIL_ADD_TEMPLATE";
export const CUSTOMER_EMAIL_GET_TEMPLATE = "CUSTOMER_EMAIL_GET_TEMPLATE";
export const CUSTOMER_EMAIL_UPDATE_TEMPLATE = "CUSTOMER_EMAIL_UPDATE_TEMPLATE";
export const CUSTOMER_EMAIL_DELETE_TEMPLATE = "CUSTOMER_EMAIL_DELETE_TEMPLATE";
export const CUSTOMER_EMAIL_MAKE_PREVIEW = "CUSTOMER_EMAIL_MAKE_PREVIEW";

export const MAILLIST_ADDNEW = "MAILLIST_ADDNEW";
export const MAILLIST_UPDATE = "MAILLIST_UPDATE";
export const MAILLIST_GET_ALL = "MAILLIST_GET_ALL";
export const MAILLIST_DELETE = "MAILLIST_DELETE";
export const MAILLIST_ADDNEW_MEMBER = "MAILLIST_ADDNEW_MEMBER";
export const MAILLIST_MEMBER_DELETE = "MAILLIST_MEMBER_DELETE";