<template>
  <AdminContainer>
    <div class="wrapper">
      <div class="wrapper-header">
        <div class="tabs">
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'contact' }"
            @click="switchTab('contact')"
          >
            Customer massaging
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'affiliate' }"
            @click="switchTab('affiliate')"
          >
            Customer affiliate
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'ads' }"
            @click="switchTab('ads')"
          >
            Customer ads
          </button>
        </div>
      </div>
      <div class="wrapper-body">
        <Contacts v-if="tab_switch == 'contact'"></Contacts>
        <Affilate v-if="tab_switch == 'affiliate'"></Affilate>
        <Ads v-if="tab_switch == 'ads'"></Ads>
      </div>
    </div>
  </AdminContainer>
</template>
<script>
import AdminContainer from "./AdminContainer.vue";
import Affilate from "./CustomerView/affiliate.vue";
import Contacts from "./CustomerView/contacts.vue";
import Ads from "./CustomerView/ads.vue";
export default {
  components: {
    AdminContainer,
    Affilate,
    Contacts,
    Ads,
  },
  data: () => ({
    tab_switch: "contact",
  }),
  watch: {},
  mounted() {},
  methods: {
    switchTab(tab) {
      let vm = this;
      vm.tab_switch = tab;
    },
  },
};
</script>
<style lang="scss" >
.wrapper {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .wrapper-header {
    width: 100%;
    height: 70px;
    background: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .tabs {
      display: flex;
      .tab-item {
        border: none;
        margin-right: 15px;
        text-transform: uppercase;
        background: transparent;
        &.active {
          background: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          color: #8dc73f;
        }
        &:hover {
          color: #8dc73f;
        }
      }
    }
  }
  .wrapper-body {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
}
</style>
