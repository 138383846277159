<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <h3>CAGURA PRODUCTS</h3>
      <div class="call-actions">
        <button class="btn-success" @click="addNewProduct()">
          <img src="@/assets/images/icons/plus.svg" /> CREATE PRODUCTS
        </button>
      </div>
    </div>
    <div class="body-widget">
      <div class="table-widget" v-if="my_products_data.length">
        <table>
          <tr>
            <th>Name</th>
            <th>Brand</th>
            <th>Price</th>
            <th></th>
          </tr>
          <tr v-for="(item, i) in my_products_data" :key="i">
            <td>
              <img width="40" :src="$store.state.ASSETS_BASE_URL +
                'assets/uploaded/' +
                item.main_image
                " />
              {{ item.product_name }}
            </td>
            <td>{{ item.brand }}</td>
            <td>
              <div class="price" v-if="item.discount" :class="{ strikethrough: item.discount }">
                <strike>FRW {{ $formatMoney(item.price) }}</strike>
              </div>

              <div class="price" v-else>FRW {{ $formatMoney(item.price) }}</div>

              <div class="price" v-if="item.discount">
                {{
                  "FRW " +
                  $formatMoney(item.price - (item.price * item.discount) / 100)
                }}
              </div>
            </td>
            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" @click.prevent="editProduct(item)">
                    <img src="@/assets/images/icons/edit.svg" /> Edit Product
                  </a>

                  <a class="dropdown-item" @click.prevent="deleteProduct(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete product
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>

      <div class="empty-widget" v-if="my_products_data.length == 0 && !is_loading">
        <a-empty image="assets/images/icons/empty.svg" :image-style="{ height: '116px' }">
          <span slot="description">You don't have any product, Create one !!!</span>
        </a-empty>
      </div>
      <div class="loader-widget" style="height: 300px" v-if="is_loading">
        <div class="loading-panel">
          <a-spin :spinning="true"></a-spin>
          <span class="text">Loading...</span>
        </div>
      </div>
    </div>
    <!-- Add product modal -->
    <add-product v-if="show_add_product_modal" @onSubmit="onProductSubmit()" :value="selected_item"
      @onClose="show_add_product_modal = false" />
  </div>
</template>

<script>
import AddProduct from "../../components/modals/AddProduct.vue";

export default {
  name: "my_products",
  components: {
    AddProduct,
  },
  data() {
    return {
      show_add_product_modal: false,
      show_contact_form: false,
      product_link: "",
      price_range_marks: {
        500: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500",
        },
        500000: {
          style: {
            color: "#4b4a4a",
          },
          label: "FRW 500,000",
        },
      },
      show_filter_widget: false,
      filter: {
        price: {
          min: 0,
          max: 500000,
        },
        categories: null,
        sort: {
          price: "up",
          popularity: "down",
        },
      },
      my_products_data: [],
      store_products_data: [],
      search_product_input: "",
      is_loading: false,
      my_products_categories: [],
      selected_item: null,
      show_share_product_link_modal: false,
    };
  },
  created() { },
  mounted() {
    let vm = this;
    vm.getAllProducts();
    vm.getProductCategories();
  },
  methods: {
    onCopy: function (e) {
      let vm = this;
      vm.$notify({
        group: "status",
        type: "success",
        title: "Important message",
        text: "Link copied",
      });
    },
    onError: function (e) {
      vm.$notify({
        group: "status",
        type: "warn",
        title: "Important message",
        text: "Failed to copy",
      });
    },
    getSharelableLink(item, type) {
      let vm = this;
      let product_name = item.product_name.toLowerCase().replace(/\s/g, "_");
      let product_id = vm.$encodeProductId(item.id);
      vm.product_link =
        vm.$store.state.BASE_URL +
        "product/view/" +
        product_id +
        "/" +
        product_name;
      vm.show_share_product_link_modal = true;
    },
    deleteProduct(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete all information related to this product, But shop from it will not be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteProduct(item);
          }
        },
      });
    },
    confirmDeleteProduct(item) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("PRODUCT_DELETE", {
          user_id: item.user_id,
          id: item.id,
        })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.getAllProducts();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("PRODUCT_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    addNewProduct() {
      let vm = this;
      vm.show_add_product_modal = true;
      vm.selected_item = null;
    },
    editProduct(item) {
      let vm = this;
      vm.selected_item = null;
      vm.selected_item = item;
      vm.show_add_product_modal = true;
    },
    onProductSubmit() {
      let vm = this;
      vm.show_add_product_modal = false;
      vm.getAllProducts();
    },
    filterProduct() {
      let vm = this;
      vm.my_products_data = vm.store_products_data
        .filter((item) => {
          let cat_product_ids = vm.filter.categories
            ? vm.filter.categories
            : [item.id];
          return (
            cat_product_ids.includes(item.id) &&
            parseInt(item.price) >= vm.filter.price.min &&
            parseInt(item.price) <= vm.filter.price.max
          );
        })
        .sort((a, b) => {
          return vm.filter.sort.price == "down"
            ? parseInt(a.price) - parseInt(b.price)
            : parseInt(b.price) - parseInt(a.price);
        });
    },
    onPriceFilterChange(value) {
      let vm = this;
      vm.filter.price.min = value[0];
      vm.filter.price.max = value[1];
      vm.filterProduct();
    },
    searchProductBasedCategory(item) {
      let vm = this;
      vm.my_products_categories.forEach((item) => {
        item.active = false;
      });
      item.active = !item.active;
      vm.filter.categories = item.product_ids.map((item) => item.product_id);
      vm.filterProduct();
    },
    softByPopularity() {
      let vm = this;
      vm.filter.sort.popularity == "up"
        ? (vm.filter.sort.popularity = "down")
        : (vm.filter.sort.popularity = "up");
      vm.filterProduct();
    },
    softByPrice() {
      let vm = this;
      vm.filter.sort.price == "up"
        ? (vm.filter.sort.price = "down")
        : (vm.filter.sort.price = "up");
      vm.filterProduct();
    },
    getProductCategories() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_MY_CATEGORIES")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_products_categories = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "active", false);
              return item;
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_MY_CATEGORIES", response);
        });
    },
    getAllProducts() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("PRODUCT_GET_ALL_PRODUCT")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_products_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              return item;
            });
            vm.store_products_data = vm.my_products_data;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("PRODUCT_GET_ALL_PRODUCT", response);
        });
    },
    filterFormatter(val) {
      let vm = this;
      return `FRW ${vm.$formatMoney(val)}`;
    },
    searchProduct(query) {
      let vm = this;
      vm.my_products_data = vm.store_products_data.filter((item) => {
        if (
          item.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.brand.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          item.price <= query
        ) {
          return true;
        }
        return false;
      });
    },
  },
  watch: {
    search_product_input: function (query) {
      let vm = this;
      vm.searchProduct(query);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
