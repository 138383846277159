import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import AccountLogin from '../views/AccountLogin.vue'
import NotFound from '../views/NotFound.vue'

// Admin pages
import Welcome from "../views/Welcome.vue"
import Dashboard from '../views/Dashboard.vue';
import Nofications from "../views/Nofications.vue";
import AffiliateItems from "../views/affiliateItems.vue";
import AffiliateItem from "../views/AffiliateItem.vue";
import Shops from "../views/Shops.vue";
import Products from "../views/Products.vue";
import Contacts from "../views/Contacts.vue";
import Deals from "../views/Deals.vue";
import CustomerView from "../views/CustomerView.vue";
import Settings from "../views/Settings.vue";
import Hack from "../views/hack.vue";
import Mailing from "../views/Mailing.vue";
import Story from "../views/Story.vue";
import Reports from "../views/Reports.vue";

Vue.use(VueRouter)
Vue.use(VueHead);
const routes = [{
    path: '/login',
    name: 'login',
    component: AccountLogin,
},
{
    path: '/',
    name: 'welcome',
    component: Welcome,
},
{
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
},
{
    path: '/nofications',
    name: 'nofications',
    component: Nofications,
},
{
    path: '/affiliate',
    name: 'affiliate',
    component: AffiliateItems,
},
{
    path: '/affiliate/view/:id?',
    name: 'affiliate-item',
    component: AffiliateItem,
},
{
    path: '/shops',
    name: 'shops',
    component: Shops,
},
{
    path: '/products',
    name: 'products',
    component: Products,
},
{
    path: '/story',
    name: 'story',
    component: Story
},
{
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
},
{
    path: '/mailing',
    name: 'mailing',
    component: Mailing,
},
{
    path: '/reports',
    name: 'reports',
    component: Reports,
},
{
    path: "/deals",
    name: 'deals',
    component: Deals,
},
{
    path: "/customer/view/:id",
    name: 'customer-view',
    component: CustomerView
},
{
    path: "/settings",
    name: 'settings',
    component: Settings
},
{
    path: "/hack",
    name: 'hack',
    component: Hack
},

{
    path: '*',
    component: NotFound,
    name: 'notfound',
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    duplicateNavigationPolicy: 'ignore',
})

export default router