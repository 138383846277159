<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <h3>CAGURA SHOPS</h3>
      <div class="call-actions">
        <button class="btn-success" @click="addNewShop()">
          <img src="@/assets/images/icons/plus.svg" /> CREATE SHOP
        </button>
      </div>
    </div>
    <div class="body-widget">
      <div class="table-widget" v-if="my_shops_data.length > 0">
        <table>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th></th>
          </tr>
          <tr v-for="(item, index) in my_shops_data" :key="index">
            <td>
              <img width="40" :src="$store.state.SITE_ASSETS_BASE_URL +
                'assets/uploaded/' +
                item.shop_logo
                " />{{ item.shop_name }}
            </td>
            <td>
              <span class="location">
                <img src="@/assets/images/icons/map-pin.svg" />
                {{ item.location_name }}
              </span>
            </td>
            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" @click.prevent="editShop(item)">
                    <img src="@/assets/images/icons/edit.svg" /> Edit Shop
                  </a>
                  <a class="dropdown-item" @click.prevent="deleteShop(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete Shop
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>
      <div class="empty-widget" v-if="my_shops_data.length == 0 && !is_loading">
        <a-empty image="assets/images/icons/empty.svg" :image-style="{ height: '116px' }">
          <span slot="description">You don't have any shop, Create one !!!</span>
        </a-empty>
      </div>
      <div class="loader-widget" style="height: 300px" v-if="is_loading">
        <div class="loading-panel">
          <a-spin :spinning="true"></a-spin>
          <span class="text">Loading...</span>
        </div>
      </div>
    </div>

    <!-- Add shop modal -->
    <add-shop v-if="show_add_shop_modal" :value="selected_shop_item" @onSubmit="onSubmitShop()"
      @onClose="show_add_shop_modal = false" />
  </div>
</template>

<script>
import AddShop from "../../components/modals/AddShop.vue";

export default {
  name: "cagura-shop",
  components: {
    AddShop,
  },
  data() {
    return {
      show_add_shop_modal: false,
      is_loading: false,
      my_shops_data: [],
      selected_shop_item: null,
    };
  },
  mounted() {
    let vm = this;
    vm.getShops();
  },
  methods: {
    onSubmitShop() {
      let vm = this;
      vm.show_add_shop_modal = false;
      vm.getShops();
    },
    getShops() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SHOP_GET_ALL")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.my_shops_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              return item;
            });
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SHOP_GET_ALL", response);
        });
    },
    addNewShop() {
      let vm = this;
      vm.show_add_shop_modal = true;
      vm.selected_shop_item = null;
    },
    editShop(item) {
      let vm = this;
      vm.show_add_shop_modal = true;
      vm.selected_shop_item = null;
      vm.selected_shop_item = item;
    },
    deleteShop(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? This will delete all information related to this shop, But products from it will not be deleted`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteShop(item);
          }
        },
      });
    },
    confirmDeleteShop(item) {
      let vm = this;
      vm.$store.commit("START_LOADER");
      vm.$store
        .dispatch("SHOP_DELETE", {
          user_id: item.user_id,
          id: item.id,
        })
        .then((response) => {
          vm.$store.commit("STOP_LOADER");
          if (response.data.status == "ok") {
            vm.getShops();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          } else {
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.Message,
            });
          }
        })
        .catch(({ response }) => {
          vm.$store.commit("STOP_LOADER");
          console.error("SHOP_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
