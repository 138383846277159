import {
    MOVIE_ADD_ITEM,
    MOVIE_UPDATE_ITEM,
    GET_ALL_MOVIES_DATA,
    DELETE_MOVIE,
    SEASONS_ADD_ITEM,
    SEASONS_UPDATE_ITEM,
    GET_MOVIES_SEASONS,
    DELETE_MOVIES_SEASON_ITEM,
    MOVIE_EPISODE_ADD_ITEM,
    MOVIE_EPISODE_UPDATE_ITEM,
    GET_ALL_MOVIES_EPISODE_DATA,
    DELETE_MOVIE_EPISODE,
    MOVIES_MOVER_TO_CDN_SERVER,
    MOVIES_STOP_PROCESS_ON_SERVER,
    MOVIES_DELETE_FILES,
    MOVIES_SAVE_UPLOADED_MOVIE_INFO,
    MOVIES_DELETE_CDN_FILE,
    MOVIES_DELETE_DB_INFO,
    STREAMING_ADD_ITEM,
    STREAMING_GET_ALL,
    STREAMING_UPDATE_ITEM,
    STREAMING_DELETE_ITEM,
    STREAMING_PROCESS_STREAMING,
    STREAMING_STOP_PROCESS_STREAMING,
    STREAMING_DELETE_PROCESSED_FILES,
    MOVIES_ADD_CATEGORY,
    MOVIES_UPDATE_CATEGORY,
    MOVIES_GET_ALL_CATEGORIES,
    MOVIES_DELETE_CATEGORY,
    MOVIES_GET_ALL_NON_CATEGORIZED,
    MOVIES_ADD_CATEGORIZED_MOVIE,
    MOVIES_REMOVE_CATEGORIZED_MOVIE,
    MOVIES_PROCESS_WITHDRAW
} from "../actions/movie";

import {
    API_BASE_URL,
    API_KITCHEN_BASE_URL,
    MOVIE_ADD_ITEM_URL,
    MOVIE_UPDATE_ITEM_URL,
    GET_ALL_MOVIES_DATA_URL,
    DELETE_MOVIE_URL,
    SEASONS_ADD_ITEM_URL,
    SEASONS_UPDATE_ITEM_URL,
    GET_MOVIES_SEASONS_URL,
    DELETE_MOVIES_SEASON_ITEM_URL,
    MOVIE_EPISODE_ADD_ITEM_URL,
    MOVIE_EPISODE_UPDATE_ITEM_URL,
    GET_ALL_MOVIES_EPISODE_DATA_URL,
    DELETE_MOVIE_EPISODE_URL,
    MOVIES_MOVER_TO_CDN_SERVER_URL,
    MOVIES_STOP_PROCESS_ON_SERVER_URL,
    MOVIES_DELETE_FILES_URL,
    MOVIES_SAVE_UPLOADED_MOVIE_INFO_URL,
    MOVIES_DELETE_CDN_FILE_URL,
    MOVIES_DELETE_DB_INFO_URL,
    STREAMING_ADD_ITEM_URL,
    STREAMING_GET_ALL_URL,
    STREAMING_UPDATE_ITEM_URL,
    STREAMING_DELETE_ITEM_URL,
    STREAMING_PROCESS_STREAMING_URL,
    STREAMING_STOP_PROCESS_STREAMING_URL,
    STREAMING_DELETE_PROCESSED_FILES_URL,
    MOVIES_ADD_CATEGORY_URL,
    MOVIES_UPDATE_CATEGORY_URL,
    MOVIES_GET_ALL_CATEGORIES_URL,
    MOVIES_DELETE_CATEGORY_URL,
    MOVIES_GET_ALL_NON_CATEGORIZED_URL,
    MOVIES_ADD_CATEGORIZED_MOVIE_URL,
    MOVIES_REMOVE_CATEGORIZED_MOVIE_URL,
    MOVIES_PROCESS_WITHDRAW_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [MOVIES_PROCESS_WITHDRAW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_PROCESS_WITHDRAW_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_REMOVE_CATEGORIZED_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_REMOVE_CATEGORIZED_MOVIE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_ADD_CATEGORIZED_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_ADD_CATEGORIZED_MOVIE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_GET_ALL_NON_CATEGORIZED]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_GET_ALL_NON_CATEGORIZED_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_DELETE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_DELETE_CATEGORY_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_GET_ALL_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_GET_ALL_CATEGORIES_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_ADD_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_ADD_CATEGORY_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_UPDATE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_UPDATE_CATEGORY_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_DELETE_PROCESSED_FILES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_DELETE_PROCESSED_FILES_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_STOP_PROCESS_STREAMING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_STOP_PROCESS_STREAMING_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_PROCESS_STREAMING]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_PROCESS_STREAMING_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_GET_ALL_URL,
            method: "GET",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [STREAMING_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STREAMING_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_DELETE_CDN_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_KITCHEN_BASE_URL + MOVIES_DELETE_CDN_FILE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_DELETE_DB_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_DELETE_DB_INFO_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_SAVE_UPLOADED_MOVIE_INFO]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIES_SAVE_UPLOADED_MOVIE_INFO_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_DELETE_FILES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_KITCHEN_BASE_URL + MOVIES_DELETE_FILES_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_STOP_PROCESS_ON_SERVER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_KITCHEN_BASE_URL + MOVIES_STOP_PROCESS_ON_SERVER_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIES_MOVER_TO_CDN_SERVER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_KITCHEN_BASE_URL + MOVIES_MOVER_TO_CDN_SERVER_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIE_EPISODE_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_EPISODE_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIE_EPISODE_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_EPISODE_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [GET_ALL_MOVIES_EPISODE_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_MOVIES_EPISODE_DATA_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [DELETE_MOVIE_EPISODE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_MOVIE_EPISODE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [SEASONS_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEASONS_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [SEASONS_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEASONS_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [GET_MOVIES_SEASONS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_MOVIES_SEASONS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [DELETE_MOVIES_SEASON_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_MOVIES_SEASON_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [DELETE_MOVIE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_MOVIE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [GET_ALL_MOVIES_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_MOVIES_DATA_URL,
            method: "GET",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIE_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MOVIE_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MOVIE_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};