<template>
  <AdminContainer>
    <div class="wrapper">
      <c-button
        class="btn-success"
        @click="saveCategories()"
        :loading="save_category_loading"
        v-if="categories.length > 0"
      >
        Save Categories
      </c-button>
      <br />
      <c-button
        class="btn-success"
        @click="manipulateData()"
        :loading="save_manipulate_loading"
      >
        Re Order Data
      </c-button>
      <br />
      <c-button
        class="btn-success"
        @click="saveShopData()"
        v-if="shops.length > 0"
        :loading="save_shop_loading"
      >
        Save shop data
      </c-button>
    </div>
  </AdminContainer>
</template>
<script>
import AdminContainer from "../views/AdminContainer.vue";
export default {
  components: {
    AdminContainer,
  },
  data: () => ({
    dashboard_data: null,
    categories: [],
    products: [],
    shops: [],
    page_number: 1,
    save_category_loading: false,
    save_manipulate_loading: false,
    save_shop_loading: false,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getCategories();
    //vm.getProducts();
    vm.getShops();
  },
  methods: {
    saveShopData() {
      let vm = this;
      vm.$store
        .dispatch("HACK_SAVE_SHOP_DATA", {
          shop: vm.shops,
        })
        .then((response) => {
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Something went wrong",
          });
          console.log("HACK_SAVE_SHOP_DATA", { error });
        });
    },
    getShops() {
      let vm = this;
      vm.$store
        .dispatch("HACK_GET_SHOP_DATA")
        .then((response) => {
          vm.shops = response.data.body.shops;
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Something went wrong",
          });
          console.log("HACK_GET_SHOP_DATA", { error });
        });
    },
    manipulateData() {
      let vm = this;
      vm.save_manipulate_loading = true;
      vm.$store
        .dispatch("HACK_MANIPULATE_DATA")
        .then((response) => {
          console.log(response.data);
          vm.save_manipulate_loading = false;
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.save_manipulate_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Something went wrong",
          });
          console.log("HACK_MANIPULATE_DATA", { error });
        });
    },
    getProducts() {
      let vm = this;
      vm.$store
        .dispatch("HACK_GET_PRODUCTS", {
          headers: {
            browserType: "opera",
            countryId: 1,
            deviceType: "Web",
            languageCode: "en",
            reqId: "3c88af5e70eff7305.278.52.13",
            reqTimestamp: "2011-01-14 11:58:57",
          },
          page_number: vm.page_number,
          page_size: 300,
          user_action: "N",
        })
        .then((response) => {
          response.data.body.forEach((item) => {
            vm.products.push(item);
          });
          if (vm.page_number == 17) {
            vm.saveAllData(vm.products);
          }
          if (vm.page_number <= 16) {
            vm.getProducts();
            vm.page_number += 1;
          }
          console.log(vm.products);
        })
        .catch((error) => {
          console.log("HACK_GET_PRODUCTS", { error });
        });
    },
    saveAllData(data) {
      let vm = this;
      vm.$store
        .dispatch("HACK_SAVE_PRODUCTS_DATA", {
          data: JSON.stringify(data),
        })
        .then((response) => {
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Something went wrong",
          });
          console.log("HACK_SAVE_PRODUCTS_DATA", { error });
        });
    },
    getCategories() {
      let vm = this;
      vm.$store
        .dispatch("HACK_GET_CATEGORIES")
        .then((response) => {
          vm.categories = response.data.body.map((item) => {
            let obj = {
              id: item.id,
              image_url: item.image_url,
              name: item.name,
              parent_id: null,
              ref: "c",
            };
            return obj;
          });
          response.data.body.map((par) => {
            par.subcategories.forEach((item) => {
              let obj = {
                id: item.id,
                image_url: item.image_url,
                name: item.name,
                parent_id: par.id,
                ref: "c",
              };
              vm.categories.push(obj);
            });
          });
        })
        .catch((error) => {
          console.log("HACK_GET_CATEGORIES", { error });
        });
    },
    saveCategories() {
      let vm = this;
      vm.save_category_loading = true;
      vm.$store
        .dispatch("HACK_SAVE_CATEGORIES", {
          categories: vm.categories,
        })
        .then((response) => {
          vm.save_category_loading = false;
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.save_category_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "Important message",
            text: "Something went wrong",
          });
          console.log("HACK_SAVE_CATEGORIES", { error });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  .card-items {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    grid-gap: 20px;
    justify-content: space-between;
    .item {
      border: 1px solid #e4e3e3;
      border-radius: 5px;
      background: #fbfbfb;
      height: 100px;
      display: flex;
      align-items: center;
      padding: 0px 20px;
      .card-icon {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        .fa {
          color: #fff;
          font-size: 30px;
        }
      }
      .card-content {
        padding: 0px 7px;
        h4 {
          font-size: 13px;
          margin: 0px;
          font-weight: 800;
          text-transform: uppercase;
        }
        span {
          font-size: 14px;
          margin: 0px;
        }
      }
    }
  }
  .statistics-items {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 20px;
    justify-content: space-between;
    .statistic-item {
      border: 1px solid rgb(103, 119, 239);
      border-radius: 5px;
      .item-header {
        height: 40px;
        background: rgb(103, 119, 239);
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      }
      .item-body {
        height: 320px;
        overflow-y: auto;
        ul.user {
          padding-left: 0px;
          margin-bottom: 0px;
          li {
            display: flex;
            align-items: center;
            padding: 10px 10px;
            border-bottom: 1px solid #ddd;
            .names {
              display: flex;
              align-items: center;
              margin-right: 10px;
              img {
                height: 40px;
                width: 40px;
                margin-right: 10px;
              }
            }
          }
        }
        ul.orders {
          padding-left: 0px;
          margin-bottom: 0px;
          li {
            display: flex;
            padding: 10px 10px;
            border-bottom: 1px solid #ddd;
            flex-direction: column;
            .names {
              display: flex;
              align-items: center;
              margin-right: 10px;
              b {
                padding-left: 4px;
              }
              img {
                height: 40px;
                width: 40px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .order-statistics {
    margin: 20px 0px;
    border: 1px solid #47c363;
    border-radius: 5px;
    .item-header {
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }
  }
}
</style>
