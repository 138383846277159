<script>
import { hasDeepValue, has } from "has-deep-value";
import moment from "moment";
const queryString = require("query-string");
import SHA256 from "crypto-js/sha256";
import CryptoJS from "crypto-js";

export default {
  mounted() { },
  methods: {
    moment,
    hasDeepValue,
    $formatMoney(amount, decimalCount = 0, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    },
    $cutText(str, maxLen, separator = " ") {
      if (str.length <= maxLen) return str;
      return str.substr(0, str.lastIndexOf(separator, maxLen)) + " ...";
    },
    $fetchFromObject(obj, prop) {
      let vm = this;
      if (typeof obj === "undefined") return false;
      var _index = prop.indexOf(".");
      if (_index > -1) {
        return vm.$fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }
      if (obj[prop] && obj[prop] != "null" && obj[prop] != "") {
        return true;
      } else {
        return false;
      }
    },
    $hasKey(obj, keyString) {
      let vm = this;
      if (vm.hasDeepValue(obj, keyString)) {
        if (vm.$fetchFromObject(obj, keyString)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    $isRouter(name) {
      if (this.$route.name === name) {
        return true;
      } else {
        return false;
      }
    },
    $getUserAccessToken() {
      let vm = this;
      if (vm.$localStorage.get("user_data")) {
        let user_data = JSON.parse(vm.$localStorage.get("user_data"));
        return user_data.access_token;
      } else {
        return "";
      }
    },
    $getLoggedUser() {
      let vm = this;
      if (vm.$localStorage.get("user_data")) {
        let user_data = JSON.parse(vm.$localStorage.get("user_data"));
        return user_data;
      } else {
        return false;
      }
    },
    $saveLoginInfo(data) {
      let vm = this;
      vm.$localStorage.set("user_data", JSON.stringify(data));
      vm.$store.commit("SET_TOKEN", data.access_token);
    },
    $setRedirect(path) {
      let vm = this;
      vm.$localStorage.set("redirect_path", path);
    },
    $appRedirect() {
      let vm = this;
      if (vm.$localStorage.get("redirect_path")) {
        vm.$router.push({ name: vm.$localStorage.get("redirect_path") });
        vm.$localStorage.remove("redirect_path");
      } else {
        vm.$router.push({ name: "welcome" });
      }
    },
    $isFieldsValidated(form, rules, length = 10000) {
      let vm = this;
      let is_error = false;
      Object.keys(rules).forEach((key, index) => {
        if (index < length - 1) {
          if (rules[key].run(form[key])) {
            let error_message = rules[key].run(form[key]);
            if (error_message) {
              if (!is_error) {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: error_message,
                });
              }
              is_error = true;
            }
          }
        }
      });
      return !is_error;
    },
    $nameInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
    checkPermission(permission_item) {
      let vm = this;
      let name = permission_item;
      let per_index = permission_item.replace(" ", "_").toLowerCase();
      if (vm.$localStorage.get("permission_list")) {
        let permission_list = JSON.parse(
          vm.$localStorage.get("permission_list")
        ).map((item) => {
          return item.unique_index;
        });
        if (!permission_list.includes(per_index)) {
          vm.$store
            .dispatch("SAVE_PERMISSION_ITEM", {
              name: name,
              index: per_index,
            })
            .then((response) => { })
            .catch(({ response }) => { });
        }
      }
      if (vm.$getLoggedUser()) {
        let user_permission = vm.$getLoggedUser().user.permission.map((item) => {
          return item.unique_index;
        });
        if (vm.$getLoggedUser().user.email == "ajahwin@gmail.com") {
          return true;
        } else {
          return user_permission.includes(per_index);
        }
      } else {
        return false;
      }
    },
    addCountries(url, a, b) {
      var tempUrl = url;
      if (a != null) {
        var tempUrlOne = new URL(tempUrl);
        tempUrl +=
          (tempUrlOne.search == "" ? "?" : "&") + "token_countries=" + a;
      }
      if (b != null) {
        var tempUrlTwo = new URL(tempUrl);
        tempUrl +=
          (tempUrlTwo.search == "" ? "?" : "&") +
          "token_countries_blocked=" +
          b;
      }
      return tempUrl;
    },
    BunnCDNSignUrl(
      url,
      securityKey,
      expirationTime = 3600,
      userIp,
      isDirectory = false,
      pathAllowed,
      countriesAllowed,
      countriesBlocked
    ) {
      var parameterData = "",
        parameterDataUrl = "",
        signaturePath = "",
        hashableBase = "",
        token = "";
      var expires = Math.floor(new Date() / 1000) + expirationTime;
      var url = this.addCountries(url, countriesAllowed, countriesBlocked);
      var parsedUrl = new URL(url);
      var parameters = new URL(url).searchParams;
      if (pathAllowed != null) {
        signaturePath = pathAllowed;
        parameters.set("token_path", signaturePath);
      } else {
        signaturePath = parsedUrl.pathname;
      }
      parameters.sort();
      if (Array.from(parameters).length > 0) {
        parameters.forEach(function (value, key) {
          if (parameterData.length > 0) {
            parameterData += "&";
          }
          parameterData += key + "=" + value;
          parameterDataUrl += "&" + key + "=" + queryString.stringify(value);
        });
      }
      hashableBase =
        securityKey +
        signaturePath +
        expires +
        (userIp != null ? userIp : "") +
        parameterData;
      token = SHA256(hashableBase).toString(CryptoJS.enc.Base64);
      token = token
        .replace(/\n/g, "")
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
      if (isDirectory) {
        return (
          parsedUrl.protocol +
          "//" +
          parsedUrl.host +
          "/bcdn_token=" +
          token +
          parameterDataUrl +
          "&expires=" +
          expires +
          parsedUrl.pathname
        );
      } else {
        return (
          parsedUrl.protocol +
          "//" +
          parsedUrl.host +
          parsedUrl.pathname +
          "?token=" +
          token +
          parameterDataUrl +
          "&expires=" +
          expires
        );
      }
    },
    token(length = 1) {
      let token = "";
      for (let i = 0; i < length; i++) {
        token += Math.random().toString(36).substr(2);
      }
      return token;
    },
    serialize(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    getUrlPath(url) {
      var el = document.createElement("a");
      el.href = url;
      return el.pathname;
    },
    getFileName(url) {
      return url.split("/").pop();
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim();
    },
    processResponce(responce) {
      let vm = this;
      let encryption = vm.Encryption();
      var decrypted = encryption.decrypt(
        responce,
        "C*gtbU(|SnTgjf}#9b))GT.]5Bmfg)Y0YDwkTm.uYAHn{Fi78A6m@fcjfB/|YzF"
      );
      return decrypted;
    },
  },
};
</script>
