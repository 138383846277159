<template>
  <AdminContainer>
    <div class="wrapper">
      <div class="wrapper-header">
        <div class="tabs">
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'all_products' }"
            @click="switchTab('all_products')"
          >
            All Products
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'categorized_product' }"
            @click="switchTab('categorized_product')"
          >
            Categorized Products
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'sponsored' }"
            @click="switchTab('sponsored')"
          >
            Sponsored
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'popular_products' }"
            @click="switchTab('popular_products')"
          >
            POPULAR PRODUCTS
          </button>
        </div>
      </div>
      <div class="wrapper-body">
        <AllProducts v-if="tab_switch == 'all_products'"></AllProducts>
        <CategorizedProducts
          v-if="tab_switch == 'categorized_product'"
        ></CategorizedProducts>
        <Sponsored v-if="tab_switch == 'sponsored'"></Sponsored>
        <PopularProducts
          v-if="tab_switch == 'popular_products'"
        ></PopularProducts>
      </div>
    </div>
  </AdminContainer>
</template>
<script>
import AdminContainer from "../views/AdminContainer.vue";
import AllProducts from "../views/Products/AllProducts.vue";
import CategorizedProducts from "../views/Products/CategorizedProducts.vue";
import Sponsored from "../views/Products/Sponsored.vue";
import PopularProducts from "../views/Products/PopularProducts.vue";
export default {
  components: {
    AdminContainer,
    AllProducts,
    CategorizedProducts,
    Sponsored,
    PopularProducts,
  },
  data: () => ({
    tab_switch: "popular_products",
  }),
  watch: {},
  mounted() {},
  methods: {
    switchTab(tab) {
      let vm = this;
      vm.tab_switch = tab;
    },
  },
};
</script>
<style lang="scss" >
.wrapper {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .wrapper-header {
    width: 100%;
    height: 70px;
    background: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .tabs {
      display: flex;
      .tab-item {
        border: none;
        margin-right: 15px;
        text-transform: uppercase;
        background: transparent;
        &.active {
          background: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          color: #8dc73f;
        }
        &:hover {
          color: #8dc73f;
        }
      }
    }
  }
  .wrapper-body {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
}
</style>
