<template>
  <AdminContainer>
    <div class="wrapper-container">
      <div class="container-widget-header">
        <h3>CUSTOMERS</h3>
        <div class="call-actions">
          <button class="btn-success" @click="addNew()">
            <img src="@/assets/images/icons/plus.svg" /> CREATE CUSTOMER
          </button>
        </div>
      </div>
      <div class="body-widget">
        <div class="table-widget">
          <table>
            <tr>
              <th>Customer Name</th>
              <th>Customer Address</th>
              <th>Deal Status</th>
              <th>Deal Price</th>
              <th></th>
            </tr>
            <tr v-for="(item, index) in customer_data" :key="index">
              <td>{{ item.customer_name }}</td>
              <td>{{ item.customer_address }}</td>
              <td>{{ item.deal_status }}</td>
              <td>{{ item.deal_price }}/{{ item.deal_price_unity }}</td>
              <td>
                <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                  class="shop-menu">
                  <button class="dropdown-toggle">
                    <img src="@/assets/images/icons/menu.svg" />
                  </button>
                  <div slot="dropdown" class="drop-down-menu">
                    <a class="dropdown-item" @click.prevent="viewItem(item)">
                      <span class="fa fa-eye"></span> View
                    </a>
                    <a class="dropdown-item" @click.prevent="editItem(item)">
                      <img src="@/assets/images/icons/edit.svg" /> Edit
                    </a>
                    <a class="dropdown-item" @click.prevent="deleteItem(item)">
                      <img src="@/assets/images/icons/trash.svg" /> Delete
                    </a>
                  </div>
                </dropdown-menu>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Add Modal -->
      <div class="overlay" v-if="show_form">
        <div class="form-widget">
          <div class="header">
            <h3 v-if="!form.id">Add new customer</h3>
            <h3 v-else>Update customer</h3>
          </div>
          <div class="body">
            <div class="input-group">
              <label>Customer name</label>
              <div class="input-widget">
                <input type="text" v-model="form.customer_name" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group">
              <label>Customer address</label>
              <div class="input-widget">
                <input type="text" v-model="form.customer_address" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group">
              <label>Job description</label>
              <div class="input-widget">
                <textarea type="text" v-model="form.job_description" class="bg-cagura-textarea-form-control"></textarea>
              </div>
            </div>

            <div class="input-group">
              <label>Deal Status</label>
              <div class="input-widget">
                <select v-model="form.deal_status" class="bg-cagura-form-control">
                  <option value="pending">Pending</option>
                  <option value="processing">Processing</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
            </div>

            <div class="input-group">
              <label>Deal price</label>
              <div class="input-widget">
                <input type="number" v-model="form.deal_price" class="bg-cagura-form-control" />
              </div>
            </div>

            <div class="input-group">
              <label>Deal price unit</label>
              <div class="input-widget">
                <input type="text" v-model="form.price_unit" class="bg-cagura-form-control" />
              </div>
            </div>

            <div class="input-group">
              <label>Start Date</label>
              <div class="input-widget">
                <a-date-picker v-model="start_date" size="large" @change="onChangeStartDate" />
              </div>
            </div>
            <div class="input-group">
              <label>End Date</label>
              <div class="input-widget">
                <a-date-picker v-model="end_date" size="large" @change="onChangeEndDate" />
              </div>
            </div>
          </div>
          <div class="footer">
            <c-button :loading="loading" @click="submitForm()" class="btn-success">Save</c-button>
            <button @click="show_form = false" class="btn-default">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AdminContainer>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    loading: false,
    form: {
      id: null,
      customer_name: "",
      customer_address: "",
      job_description: "",
      deal_status: "",
      deal_price: 0,
      price_unit: "",
      start_date: "",
      end_date: "",
    },
    customer_data: [],
    start_date: "",
    end_date: "",
    show_form: false,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getContacts();
  },
  methods: {
    viewItem(item) {
      let vm = this;
      vm.$router.push({ path: `/customer/view/${item.id}` });
    },
    onChangeEndDate(date, dateString) {
      let vm = this;
      vm.form.end_date = dateString;
    },
    onChangeStartDate(date, dateString) {
      let vm = this;
      vm.form.start_date = dateString;
    },

    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("DELETE_CUSTOMER", { id: id })
        .then((response) => {
          vm.getContacts();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("DELETE_CUSTOMER", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.customer_name = item.customer_name;
      vm.form.customer_address = item.customer_address;
      vm.form.job_description = item.job_description;
      vm.form.deal_status = item.deal_status;
      vm.form.deal_price = item.deal_price;
      vm.form.price_unit = item.deal_price_unity;
      vm.form.start_date = item.deal_start_date;
      vm.form.end_date = item.deal_end_date;
      vm.start_date = vm.moment(item.deal_start_date);
      vm.end_date = vm.moment(item.deal_end_date);
      vm.show_form = true;
    },
    getContacts() {
      let vm = this;
      vm.$store
        .dispatch("GET_ALL_CUSTOMER")
        .then((response) => {
          vm.customer_data = response.data.return.map((item) => {
            vm.$store.state.eventBus.$set(item, "is_menu_open", false);
            return item;
          });
        })
        .catch(({ response }) => {
          console.log("GET_ALL_CUSTOMER", { error });
        });
    },
    submitForm() {
      let vm = this;
      vm.loading = true;
      if (!vm.form.id) {
        vm.$store
          .dispatch("CUSTOMER_ADD_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getContacts();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_ADD_ITEM", { error });
          });
      } else {
        vm.$store
          .dispatch("CUSTOMER_UPDATE_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getContacts();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_UPDATE_ITEM", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_form = true;
      vm.form = {
        id: null,
        customer_name: "",
        customer_address: "",
        job_description: "",
        deal_status: "",
        deal_price: 0,
        price_unit: "",
        start_date: "",
        end_date: "",
      };
    },
    onAffiliateImageSelected(file) {
      let vm = this;
      vm.form.main_image = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .call-actions {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
