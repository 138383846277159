<template>
  <div class="main-container">
    <!-- Navigation -->
    <div class="navigation" v-if="$getLoggedUser()">
      <div class="container">
        <div class="brand">
          <img class="logo" src="@/assets/images/logo.png" />
        </div>
        <div class="nav-options">
          <div class="btn-container">
            <button class="nav-button">
              <img width="19" src="" />
            </button>
          </div>
          <div class="btn-container">
            <!-- <span class="not-number">0</span> -->
            <!-- <button class="nav-button">
              <img width="21" src="@/assets/images/icons/mail.svg" />
            </button> -->
          </div>
          <div class="btn-container">
            <!-- <span class="not-number">0</span> -->
            <!-- <button class="nav-button">
              <img width="23" src="@/assets/images/icons/ring.svg" />
            </button> -->
          </div>
          <div class="btn-container">
            <!-- <button ref="settings-menu" class="nav-button" @click="show_settings_menu = true">
              <img class="profile-logo" />
            </button> -->
            <button class="nav-button" ref="settings-menu" @click="show_settings_menu = !show_settings_menu">
              <div class="empty-profile">
                {{
                  $nameInitials(
                    $getLoggedUser().user.first_name +
                    " " +
                    $getLoggedUser().user.last_name
                  )
                }}
              </div>
            </button>
            <div class="settings-drop-down" v-if="show_settings_menu" v-closable="{
              exclude: ['settings-menu'],
              handler: 'hideSettingsMenu',
            }">
              <div class="profile-widget">
                {{
                  $getLoggedUser().user.first_name +
                  " " +
                  $getLoggedUser().user.last_name
                }}
              </div>
              <!-- <div class="setting-item">
                <span class="fa fa-user icon"></span> <span>Profile</span>
              </div> -->
              <div class="setting-item" @click="logOut()">
                <span class="fa fa-lock icon"></span> <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-widget">
      <div class="menu-list">
        <ul>
          <li class="item" v-if="checkPermission('Dashboard Menu')" :class="{ active: $route.name == 'dashboard' }"
            @click="navigateTo('dashboard')">
            <i class="fa fa-tachometer icon"></i>
            <span class="text">Dashboard</span>
          </li>
          <li class="item" v-if="checkPermission('Shops Menu')" :class="{ active: $route.name == 'shops' }"
            @click="navigateTo('shops')">
            <i class="fa fa-shopping-bag icon"></i>
            <span class="text">Shops</span>
          </li>
          <li class="item" v-if="checkPermission('Products Menu')" :class="{ active: $route.name == 'products' }"
            @click="navigateTo('products')">
            <i class="fa fa-amazon icon"></i>
            <span class="text">Products</span>
          </li>
          <li class="item" v-if="checkPermission('Story')" :class="{ active: $route.name == 'story' }"
            @click="navigateTo('story')">
            <i class="fa fa-circle icon"></i>
            <span class="text">Story</span>
          </li>
          <li class="item" v-if="checkPermission('Reports')" :class="{ active: $route.name == 'reports' }"
            @click="navigateTo('reports')">
            <i class="fa fa-bullhorn icon"></i>
            <span class="text">Report</span>
          </li>
          <li class="item" v-if="checkPermission('Affiliate Menu')" :class="{
            active:
              $route.name == 'affiliate' || $route.name == 'affiliate-item',
          }" @click="navigateTo('affiliate')">
            <i class="fa fa-globe icon"></i>
            <span class="text">Affiliate</span>
          </li>
          <li class="item" v-if="checkPermission('Mailing')" :class="{ active: $route.name == 'mailing' }"
            @click="navigateTo('mailing')">
            <i class="fa fa-envelope icon"></i>
            <span class="text">Mailing</span>
          </li>
          <li class="item" v-if="checkPermission('Contacts Menu')" :class="{ active: $route.name == 'contacts' }"
            @click="navigateTo('contacts')">
            <i class="fa fa-phone icon"></i>
            <span class="text">Contacts</span>
          </li>
          <li class="item" v-if="checkPermission('Deals Menu')" :class="{
            active: $route.name == 'deals' || $route.name == 'customer-view',
          }" @click="navigateTo('deals')">
            <i class="fa fa-money icon"></i>
            <span class="text">Deals</span>
          </li>
          <li class="item" v-if="checkPermission('App Notifications Menu')"
            :class="{ active: $route.name == 'nofications' }" @click="navigateTo('nofications')">
            <i class="fa fa-bell icon"></i>
            <span class="text">App Notifications</span>
          </li>
          <li class="item" v-if="checkPermission('Settings Menu')" :class="{
            active: $route.name == 'settings',
          }" @click="navigateTo('settings')">
            <i class="fa fa-cog icon"></i>
            <span class="text">Settings</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="body-container">
      <slot> </slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {
    let vm = this;
    if (!vm.$getLoggedUser()) {
      vm.$router.push({ name: "login" });
    }
  },
  data: () => ({
    show_settings_menu: false,
  }),
  watch: {},
  methods: {
    navigateTo(name) {
      let vm = this;
      vm.$router.push({ name: name });
    },
    hideSettingsMenu() {
      let vm = this;
      vm.show_settings_menu = false;
    },
    logOut() {
      let vm = this;
      vm.$store.dispatch("AUTH_LOGOUT");
      vm.$localStorage.remove("user_data");
      vm.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="scss">
.main-container {
  .navigation {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 60px;
    background: #fff;
    display: flex;
    justify-content: center;
    z-index: 9999999999999;
    border-bottom: 1px solid #e8e8e8;

    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .brand {
        .logo {
          width: 150px;
        }
      }

      .search-widget {
        width: 340px;
        background: #fff;
        border-radius: 2px;

        .search-group {
          width: 100%;
          display: flex;
          padding: 0px 8px;

          input {
            height: 35px;
            border: none;
            background: transparent;
            width: 100%;
            padding-left: 10px;
            border: none;
            outline: none;

            &::placeholder {
              color: rgb(194, 193, 193);
            }
          }

          .search {
            background: transparent;
            border: none;
            width: 22px;
          }
        }
      }

      .nav-options {
        display: flex;

        .btn-container {
          position: relative;
          margin-left: 10px;

          .not-number {
            position: absolute;
            top: -10px;
            right: 0px;
            background: #8dc73f;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }

          .nav-button {
            background: transparent;
            border: none;
            display: flex;
            align-items: center;

            img.profile-logo {
              width: 23px;
              border-radius: 50%;
            }

            .empty-profile {
              height: 30px;
              width: 30px;
              border: 3px solid #8dc73f;
              font-size: 11px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: -5px;
              background: #000;
              color: #fff;
            }
          }

          .settings-drop-down {
            position: absolute;
            top: 28px;
            right: 8px;
            width: 200px;
            z-index: 9999;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ddd;

            .profile-widget {
              padding: 20px;
              text-align: center;
            }

            .setting-item {
              padding: 10px 13px;
              font-size: 13px;
              cursor: pointer;
              border-bottom: 1px solid #ddd;

              .icon {
                margin-right: 10px;
              }

              &:hover {
                background: #ddd;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-widget {
    position: fixed;
    top: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 230px;
    background: #fff;
    z-index: 1000;
    padding-top: 60px;
    border-right: 1px solid #e8e8e8;

    .menu-list {
      ul {
        list-style: none;
        padding-left: 0px;

        li.item {
          padding: 15px 15px;
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            color: #000;
            font-size: 13px;
            font-weight: 500;
          }

          .icon {
            font-size: 14px;
            margin-right: 10px;
            color: rgb(116, 116, 116);
            margin-top: 0px;
          }

          &.active {
            background: rgb(240, 240, 240);

            span {
              color: rgb(70, 70, 70);
            }

            .icon {
              color: rgb(70, 70, 70);
            }
          }

          &:hover {
            background: rgb(240, 240, 240);
            transition: 100ms ease-in;

            span {
              color: rgb(70, 70, 70);
            }

            .icon {
              color: rgb(70, 70, 70);
            }
          }
        }

        li.divider {
          border-bottom: 4px solid #57585a;
        }
      }
    }
  }

  .body-container {
    position: fixed;
    left: 230px;
    right: 0px;
    bottom: 0px;
    top: 60px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
  }
}
</style>
