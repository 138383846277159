import { render, staticRenderFns } from "./affiliateItems.vue?vue&type=template&id=260689f4&scoped=true"
import script from "./affiliateItems.vue?vue&type=script&lang=js"
export * from "./affiliateItems.vue?vue&type=script&lang=js"
import style0 from "./affiliateItems.vue?vue&type=style&index=0&id=260689f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_react-dom@16.14.0_react@16.14.0_vue-te_edd25fqebwqakxcdi5p4zj75om/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "260689f4",
  null
  
)

export default component.exports