export const SHOP_ADD_NEW = "SHOP_ADD_NEW";
export const SHOP_UPDATE = "SHOP_UPDATE";
export const SHOP_DELETE = "SHOP_DELETE";
export const SHOP_SEARCH_CATEGORIES = "SHOP_SEARCH_CATEGORIES";
export const SHOP_GET_ALL_CATEGORIES = "SHOP_GET_ALL_CATEGORIES";
export const SHOP_GET_SINGLE_SHOP = "SHOP_GET_SINGLE_SHOP";
export const SHOP_SEND_MESSAGE = "SHOP_SEND_MESSAGE";
export const SHOP_GET_ALL = "SHOP_GET_ALL";
export const SHOP_GET_ALL_PRODUCT = "SHOP_GET_ALL_PRODUCT";
export const SHOP_GET_ALL_PRODUCT_CATEGORIES = "SHOP_GET_ALL_PRODUCT_CATEGORIES";
export const SHOP_POPULAR_DELETE = "SHOP_POPULAR_DELETE";
export const SHOP_POPULAR_GET_ALL = "SHOP_POPULAR_GET_ALL";
export const SHOP_POPULAR_ADD_NEW = "SHOP_POPULAR_ADD_NEW";
export const SHOP_POPULAR_UPDATE = "SHOP_POPULAR_UPDATE";
