import {
    HACK_GET_CATEGORIES,
    HACK_SAVE_CATEGORIES,
    HACK_GET_PRODUCTS,
    HACK_SAVE_PRODUCTS_DATA,
    HACK_MANIPULATE_DATA,
    HACK_GET_SHOP_DATA,
    HACK_SAVE_SHOP_DATA
} from "../actions/hack";

import {
    CATCHYZ_API_BASE_URL,
    API_BASE_URL,
    HACK_GET_CATEGORIES_URL,
    HACK_SAVE_CATEGORIES_URL,
    HACK_GET_PRODUCTS_URL,
    HACK_SAVE_PRODUCTS_DATA_URL,
    HACK_MANIPULATE_DATA_URL,
    HACK_GET_SHOP_DATA_URL,
    HACK_SAVE_SHOP_DATA_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [HACK_SAVE_SHOP_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + HACK_SAVE_SHOP_DATA_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [HACK_GET_SHOP_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: CATCHYZ_API_BASE_URL + HACK_GET_SHOP_DATA_URL,
            method: "GET",
            data: payload,
        });
    },
    [HACK_MANIPULATE_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + HACK_MANIPULATE_DATA_URL,
            method: "GET",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [HACK_SAVE_PRODUCTS_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + HACK_SAVE_PRODUCTS_DATA_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [HACK_GET_PRODUCTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: CATCHYZ_API_BASE_URL + HACK_GET_PRODUCTS_URL,
            method: "POST",
            data: payload,
        });
    },
    [HACK_SAVE_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + HACK_SAVE_CATEGORIES_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [HACK_GET_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: CATCHYZ_API_BASE_URL + HACK_GET_CATEGORIES_URL,
            method: "GET",
            data: payload,
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};