<template>
  <div class="wrapper">
    <div class="wrapper-option">
      <c-button class="btn-success" :loading="validate_loading" @click="validateUser()">VALIDATE USER</c-button>
    </div>
    <div class="table-widget">
      <table>
        <tr>
          <th>Names</th>
          <th>Views</th>
          <th>Phone</th>
          <th>Amount</th>
          <th>Status</th>
          <th></th>
        </tr>
        <tr v-for="(item, index) in user_data" :key="index" :class="{ is_pause: item.is_paused }">
          <td>
            <div class="names">
              <img v-if="
                item.profile_image &&
                item.account_type == 'facebook' &&
                item.account_type == 'google'
              " :src="$store.state.SITE_ASSETS_BASE_URL +
                  'assets/uploaded/' +
                  item.profile_image
                  " />
              <img v-else-if="item.profile_image" :src="$store.state.SITE_ASSETS_BASE_URL +
                'assets/uploaded/' +
                item.profile_image
                " />
              <img v-else src="@/assets/images/icons/avatar.svg" alt="" />
              {{ item.first_name }} {{ item.last_name }}
            </div>
          </td>
          <td>{{ item.views_count }}</td>
          <td>{{ item.phone }}</td>
          <td>FRW {{ item.final_win_price }}</td>
          <td>{{ item.status }}</td>
          <td>
            <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="false"
              class="shop-menu">
              <button class="dropdown-toggle">
                <img src="@/assets/images/icons/menu.svg" />
              </button>
              <div slot="dropdown" class="drop-down-menu">
                <a class="dropdown-item" @click.prevent="PayUser(item)">
                  <span class="fa fa-money"></span> Pay
                </a>
                <a class="dropdown-item" v-if="!item.is_paused" @click.prevent="changeUserStatus(item, 'lock')">
                  <span class="fa fa-lock"></span> Lock User
                </a>
                <a class="dropdown-item" v-else @click.prevent="changeUserStatus(item, 'unlock')">
                  <span class="fa fa-unlock"></span> UnLock User
                </a>
              </div>
            </dropdown-menu>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    user_data: [],
    validate_loading: false,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.initData();
  },
  methods: {
    changeUserStatus(item, type) {
      let vm = this;
      let id = vm.$route.params.id;
      vm.$store
        .dispatch("AFFILIATE_CHANGE_USER_STATUS", {
          id: item.subscribed_affiliate_item_id,
          type: type,
        })
        .then((response) => {
          vm.getAffiriateUser(id);
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: error.response.data.message,
          });
          console.log("AFFILIATE_CHANGE_USER_STATUS", { error });
        });
    },
    validateUser() {
      let vm = this;
      vm.validate_loading = true;
      let id = vm.$route.params.id;
      vm.$store
        .dispatch("AFFILIATE_VALIDATE_USER", { id: id })
        .then((response) => {
          vm.validate_loading = false;
          vm.getAffiriateUser(id);
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.validate_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: error.response.data.message,
          });
          console.log("AFFILIATE_VALIDATE_USER", { error });
        });
    },
    initData() {
      let vm = this;
      if (vm.$route.params.id) {
        let id = vm.$route.params.id;
        vm.getAffiriateUser(id);
      }
    },
    confirmPayment(item) {
      let vm = this;
      let obj = {
        user_id: item.id,
        affiliate_id: vm.$route.params.id,
        phone: item.phone,
      };
      vm.$store
        .dispatch("AFFILIATE_PAY_WIN", obj)
        .then((response) => {
          vm.initData();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch((error) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: error.response.data.message,
          });
          console.log("AFFILIATE_PAY_WIN", { error });
        });
    },
    PayUser(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmPayment(item);
          }
        },
      });
    },
    checkWinBalance(item) {
      let vm = this;
      if (item.views_needed <= item.views_count) {
        return item.win_price;
      } else if (
        item.middle_views_needed <= item.views_count &&
        item.views_needed >= item.views_count
      ) {
        return item.middle_win_price;
      } else {
        return 0;
      }
    },
    getAffiriateUser(id) {
      let vm = this;
      vm.$store
        .dispatch("GET_AFFILIATE_PROGRAM_USER", { id: id })
        .then((response) => {
          vm.user_data = response.data.return
            .map((item) => {
              item.final_win_price = vm.checkWinBalance(item);
              item.is_menu_open = false;
              return item;
            })
            .sort((a, b) => {
              return parseInt(b.views_count) - parseInt(a.views_count);
            });
        })
        .catch((error) => {
          console.log("GET_AFFILIATE_PROGRAM_USER", { error });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  .wrapper-option {
    padding: 10px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 4px;
    z-index: 999999;
    right: 0px;
  }

  .table-widget {
    width: 100%;
    margin-top: 20px;
    padding: 0px 10px;

    table {
      width: 100%;

      tr {
        &.is_pause {
          background: rgb(78, 245, 209);
        }
      }

      th {
        height: 45px;
        background: #ddd;
        border: 1px solid #fff;
        padding: 15px;
      }

      td {
        border: 1px solid #ddd;
        padding: 15px;

        .affiliate-image {
          width: 40px;
          height: 40px;
        }

        .names {
          display: flex;
          align-items: center;
          margin-right: 10px;

          img {
            height: 40px;
            width: 40px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
