import {
    STORY_ADD_NEW,
    STORY_GET_ALL,
    STORY_UPDATE,
    STORY_DELETE
} from "../actions/story";

import {
    API_BASE_URL,
    STORY_ADD_NEW_URL,
    STORY_GET_ALL_URL,
    STORY_UPDATE_URL,
    STORY_DELETE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [STORY_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STORY_DELETE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [STORY_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STORY_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [STORY_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STORY_GET_ALL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [STORY_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + STORY_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};