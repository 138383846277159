<template>
  <div class="cagura-wrapper">
    <div class="content-widget">
      <h1 class="main-text">Page you are trying to find not found !!!</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "notfound",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .cagura-container {
    .cagura-body {
      .cagura-help-widget {
        height: 100%;
        padding-bottom: 50px;
        .help-widget {
          padding: 0px 20px;
          .help-widget-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0px;
            .call-actions {
              .btn-back {
                height: 36px;
                border: none;
                border-radius: 5px;
                background: transparent;
                outline: none;
                display: flex;
                align-items: center;
                img {
                  margin-right: 15px;
                }
              }
            }
          }
          .content-widget {
            .main-text {
              text-align: center;
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}
</style>

