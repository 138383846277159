<template>
  <AdminContainer>
    <div class="wrapper">
      <div class="wrapper-header">
        <div class="tabs">
          <button class="tab-item" :class="{ active: view == 'email_view' }" @click="switchTab('email_view')">
            Email Template
          </button>
          <button class="tab-item" :class="{ active: view == 'mail_list' }" @click="switchTab('mail_list')">
            Mail List
          </button>
        </div>
      </div>
      <div class="wrapper-body">
        <div class="wrapper-container">
          <div class="container-widget-header">
            <div class="views"></div>
            <div class="call-actions">
              <button v-if="view == 'email_view'" class="btn-success" @click="addNewEmailTemp()">
                <img src="@/assets/images/icons/plus.svg" /> CREATE TEMPLATE
              </button>
              <button v-if="view == 'mail_list'" class="btn-success" @click="addNewMailList()">
                <img src="@/assets/images/icons/plus.svg" /> CREATE MAILLIST
              </button>

              <button class="btn-success" @click="showSendForm()">
                SEND EMAIL
              </button>
              <input type="file" style="display: none" id="file" ref="file" accept=".vcf"
                v-on:change="handleFileUpload()" />
            </div>
          </div>
          <div class="body-widget">
            <!-- Maillist -->
            <div class="table-widget" v-if="maillist_data && view == 'mail_list'">
              <table>
                <tr>
                  <th>Maillist Name</th>
                  <th>Members</th>
                  <th></th>
                </tr>
                <tr v-for="(item, index) in maillist_data" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.members.length }}</td>
                  <td>
                    <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                      class="shop-menu">
                      <button class="dropdown-toggle">
                        <img src="@/assets/images/icons/menu.svg" />
                      </button>
                      <div slot="dropdown" class="drop-down-menu">
                        <a class="dropdown-item" @click.prevent="ViewMemberToMaillist(item)">
                          <span class="fa fa-user"></span> View Members
                        </a>
                        <a class="dropdown-item" @click.prevent="AddMemberToMaillist(item)">
                          <span class="fa fa-user"></span> Add Member
                        </a>
                        <a class="dropdown-item" @click.prevent="updateMailListItem(item)">
                          <img src="@/assets/images/icons/edit.svg" /> Update
                        </a>
                        <a class="dropdown-item" @click.prevent="deleteMailListItem(item)">
                          <img src="@/assets/images/icons/trash.svg" /> Delete
                        </a>
                      </div>
                    </dropdown-menu>
                  </td>
                </tr>
              </table>
            </div>

            <!-- Template -->
            <div class="table-widget" v-if="view == 'email_view'">
              <table>
                <tr>
                  <th>Template Name</th>
                  <th></th>
                </tr>
                <tr v-for="(item, index) in email_temp_data" :key="index">
                  <td>{{ item.name }}</td>

                  <td>
                    <div class="options">
                      <button @click="showMainTempEditor(item, 'main')" class="btn-default">
                        Main Template
                      </button>
                      <button class="btn-default" @click="showMainTempEditor(item, 'product')" v-if="item.has_products">
                        Product Temp
                      </button>
                      <button class="btn-default" @click="showMainTempEditor(item, 'shop')" v-if="item.has_shop">
                        Shop Temp
                      </button>
                      <button class="btn-default" @click="showMainTempEditor(item, 'movie')" v-if="item.has_movie">
                        Movie Temp
                      </button>
                      <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                        class="shop-menu">
                        <button class="dropdown-toggle">
                          <img src="@/assets/images/icons/menu.svg" />
                        </button>
                        <div slot="dropdown" class="drop-down-menu">
                          <a class="dropdown-item" @click.prevent="editEmailTempItem(item)">
                            <img src="@/assets/images/icons/edit.svg" /> Edit
                          </a>
                          <a class="dropdown-item" @click.prevent="deleteEmailTempItem(item)">
                            <img src="@/assets/images/icons/trash.svg" /> Delete
                          </a>
                        </div>
                      </dropdown-menu>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <!-- View Maillist Membes Modal -->
          <div class="overlay" v-if="view_maillist_member">
            <div class="form-widget">
              <div class="header">
                <h3>MailList Members</h3>
              </div>
              <div class="body">
                <div class="email-item" v-for="(item, index) in selectted_maillist.members" :key="index">
                  <span class="email">{{ item.email }}</span>
                  <div class="option">
                    <button class="btn-success" @click="deleteMailListMember(item, index)">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="footer">
                <button @click="view_maillist_member = false" class="btn-default">
                  Close
                </button>
              </div>
            </div>
          </div>

          <!-- Add Member Maillist Modal -->
          <div class="overlay" v-if="add_maillist_member">
            <div class="form-widget">
              <div class="header">
                <h3>Add MailList Member</h3>
              </div>
              <div class="body">
                <div class="input-group">
                  <label>Type</label>
                  <div class="input-widget">
                    <select type="text" v-model="maillist_member_form.type" class="bg-cagura-form-control">
                      <option value="email">Email</option>
                      <option value="range">Range</option>
                    </select>
                  </div>
                </div>
                <div class="input-group" v-if="maillist_member_form.type == 'email'">
                  <label>Email Member</label>
                  <div class="input-widget">
                    <input type="text" v-model="maillist_member_form.email" class="bg-cagura-form-control" />
                  </div>
                </div>
                <div class="input-group" v-else>
                  <label>Select Email range to copy from our system</label>
                  <div class="input-widget">
                    <input type="text" v-model="maillist_member_form.start" class="bg-cagura-form-control"
                      placeholder="Start" />
                  </div>
                  <div class="input-widget">
                    <input type="text" v-model="maillist_member_form.end" placeholder="End"
                      class="bg-cagura-form-control" />
                  </div>
                </div>
              </div>
              <div class="footer">
                <c-button :loading="maillist_member_loading" @click="submitMailListMemberForm()"
                  class="btn-success">Save</c-button>
                <button @click="add_maillist_member = false" class="btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <!-- Add new mail list modal -->
          <div class="overlay" v-if="show_mail_list_form">
            <div class="form-widget">
              <div class="header">
                <h3 v-if="!maillist_form.id">Add MailList</h3>
                <h3 v-else>Update MailList</h3>
              </div>
              <div class="body">
                <div class="input-group">
                  <label>Name</label>
                  <div class="input-widget">
                    <input type="text" v-model="maillist_form.name" class="bg-cagura-form-control" />
                  </div>
                </div>
              </div>
              <div class="footer">
                <c-button :loading="maillist_loading" @click="submitMailListForm()" class="btn-success">Save</c-button>
                <button @click="show_mail_list_form = false" class="btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <!-- Add new email temp -->
          <div class="overlay" v-if="show_new_email_temp_form">
            <div class="form-widget">
              <div class="header">
                <h3 v-if="!email_temp_form.id">Add Email Temp</h3>
                <h3 v-else>Update Email Temp</h3>
              </div>
              <div class="body">
                <div class="input-group">
                  <label>Template name</label>
                  <div class="input-widget">
                    <input type="text" v-model="email_temp_form.name" class="bg-cagura-form-control" />
                  </div>
                </div>

                <div class="input-group">
                  <label>Will Have Product?</label>
                  <div class="input-widget">
                    <input type="checkbox" v-model="email_temp_form.has_products" />
                  </div>
                </div>

                <div class="input-group">
                  <label>Will Have Shop?</label>
                  <div class="input-widget">
                    <input type="checkbox" v-model="email_temp_form.has_shop" />
                  </div>
                </div>

                <div class="input-group">
                  <label>Will Have Movies?</label>
                  <div class="input-widget">
                    <input type="checkbox" v-model="email_temp_form.has_movie" />
                  </div>
                </div>

                <div class="input-group">
                  <label>Add Addional info input</label>
                  <div class="input-widget">
                    <table border="">
                      <tr v-for="(item, index) in temp_keys_holder" :key="index">
                        <td>
                          <span>{{ item.name }}</span>
                        </td>
                        <td>
                          <button @click="removeTempKey(index)">&times;</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input v-model="temp_key_name" type="text" placeholder="Name" />
                        </td>
                        <td>
                          <button @click="addNewKey()">Add</button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="footer">
                <c-button :loading="loading" @click="submitEmailTempForm()" class="btn-success">Save</c-button>
                <button @click="show_new_email_temp_form = false" class="btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <!-- Mail temp Editor -->
          <div class="overlay" v-if="show_mail_temp_editor">
            <div class="form-widget form-mail-edito">
              <div class="header">
                <h3>Email Template Editor</h3>
                <div class="options">
                  <div class="keys-btn">
                    <button class="btn-default" @click="show_email_temp_key = !show_email_temp_key">
                      Show keys
                    </button>
                    <div class="email-temp-keys-widget" v-if="show_email_temp_key">
                      <ul>
                        <li v-for="(item, index) in temp_keys_holder" :key="index">
                          {{ item.key }}
                        </li>
                      </ul>
                      <!-- == -->
                      <hr />
                      Product Key
                      <ul>
                        <li>product_name</li>
                        <li>brand</li>
                        <li>price</li>
                        <li>discount_price</li>
                        <li>discount</li>
                        <li>main_image</li>
                        <li>description</li>
                        <li>link</li>
                      </ul>
                      <hr />
                      Shop Key
                      <ul>
                        <li>shop_name</li>
                        <li>shop_logo</li>
                        <li>cover_image</li>
                        <li>phone_number</li>
                        <li>email</li>
                        <li>location_name</li>
                        <li>link</li>
                      </ul>
                      <hr />
                      Movies Key
                      <ul>
                        <li>title</li>
                        <li>narrator</li>
                        <li>cover</li>
                        <li>rated_age</li>
                        <li>released_year</li>
                        <li>big_cover</li>
                        <li>description</li>
                        <li>link</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="body">
                <div class="code-preview" v-if="update_temp_type == 'main'">
                  <div class="code">
                    <CodeEditor v-model="email_temp_form.content_temp"></CodeEditor>
                  </div>
                  <div class="preview">
                    <iframe v-if="email_temp_form.content_temp != ''" frameborder="0" height="100%" width="100%"
                      class="main_temp_prev">
                    </iframe>
                  </div>
                </div>
                <div class="code-preview" v-if="update_temp_type == 'product'">
                  <div class="code">
                    <CodeEditor v-model="email_temp_form.product_temp"></CodeEditor>
                  </div>
                  <div class="preview">
                    <iframe v-if="email_temp_form.product_temp != ''" frameborder="0" height="100%" width="100%"
                      class="main_temp_prev">
                    </iframe>
                  </div>
                </div>
                <div class="code-preview" v-if="update_temp_type == 'shop'">
                  <div class="code">
                    <CodeEditor v-model="email_temp_form.shop_temp"></CodeEditor>
                  </div>
                  <div class="preview">
                    <iframe v-if="email_temp_form.shop_temp != ''" frameborder="0" height="100%" width="100%"
                      class="main_temp_prev">
                    </iframe>
                  </div>
                </div>
                <div class="code-preview" v-if="update_temp_type == 'movie'">
                  <div class="code">
                    <CodeEditor v-model="email_temp_form.movie_temp"></CodeEditor>
                  </div>
                  <div class="preview">
                    <iframe v-if="email_temp_form.movie_temp != ''" frameborder="0" height="100%" width="100%"
                      class="main_temp_prev">
                    </iframe>
                  </div>
                </div>
              </div>
              <div class="footer">
                <c-button :loading="loading" @click="submitEmailTempForm()" class="btn-success">Save</c-button>
                <button @click="show_mail_temp_editor = false" class="btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <!-- Send sms modal -->
          <div class="overlay" v-if="show_message_form">
            <div class="form-widget" :class="{ 'form-send': message_form.type == 'email' }">
              <div class="header">
                <h3>Send Message</h3>
              </div>
              <div class="body">
                <div class="message-container">
                  <div :class="{
                    form: message_form.type == 'email',
                    main_form: message_form.type != 'email',
                  }">
                    <div class="input-group" v-if="message_form.type == 'email'">
                      <label>Select MailList</label>
                      <div class="input-widget">
                        <select type="text" v-model="message_form.maillist_id" class="bg-cagura-form-control">
                          <option v-for="(item, index) in maillist_data" :key="index" :value="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group" v-if="message_form.type == 'email'">
                      <label>Subject</label>
                      <div class="input-widget">
                        <input type="text" v-model="message_form.subject" class="bg-cagura-form-control" />
                      </div>
                    </div>

                    <div class="input-group" v-if="message_form.type == 'email'">
                      <label>Select Template</label>
                      <div class="input-widget">
                        <ul>
                          <li v-for="(item, index) in email_temp_data" :key="index">
                            <input @change="selectEmailTemp(item)" name="temp" type="radio" />
                            <span>{{ item.name }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="input-group" v-if="
                      message_form.type == 'email' &&
                      message_form.temp_keys.length > 0
                    ">
                      <label>Fill all Email keys</label>
                      <div class="input-widget">
                        <table border="" style="width: 100%">
                          <tr v-for="(item, index) in message_form.temp_keys" :key="index">
                            <td>
                              <span>{{ item.key }}</span>
                            </td>
                            <td>
                              <textarea style="width: 100%" v-model="item.value" type="text"
                                placeholder="Content"></textarea>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="input-group" v-if="
                      message_form.type == 'email' &&
                      selected_temp &&
                      selected_temp.has_products
                    ">
                      <label>Select Product</label>
                      <div class="input-widget">
                        <table border="" style="width: 100%">
                          <tr v-for="(item, index) in message_form.product" :key="index">
                            <td>
                              <img :src="$store.state.ASSETS_BASE_URL +
                                'assets/uploaded/' +
                                item.main_image
                                " style="object-fit: cover; margin-right: 10px" width="30" height="30" />
                              {{ item.product_name }}
                            </td>
                            <td>
                              <button @click="removeProductToTemp(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <selectProductsModal @onSubmit="(product) => {
                                  addProductToTemp(product);
                                }
                                "></selectProductsModal>
                            </td>
                            <td></td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="input-group" v-if="
                      message_form.type == 'email' &&
                      selected_temp &&
                      selected_temp.has_shop
                    ">
                      <label>Select Shop</label>
                      <div class="input-widget">
                        <table border="" style="width: 100%">
                          <tr v-for="(item, index) in message_form.shop" :key="index">
                            <td>
                              <img :src="$store.state.ASSETS_BASE_URL +
                                'assets/uploaded/' +
                                item.shop_logo
                                " style="object-fit: cover; margin-right: 10px" width="30" height="30" />
                              {{ item.shop_name }}
                            </td>
                            <td>
                              <button @click="removeShopToTemp(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <select style="width: 160px" v-model="selected_shops_id">
                                <option v-for="(item, index) in cagura_shops" :key="index" :value="item.id">
                                  {{ item.shop_name }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <button @click="addShopToTemp()">Add</button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div class="input-group" v-if="
                      message_form.type == 'email' &&
                      selected_temp &&
                      selected_temp.has_movie
                    ">
                      <label>Select Movies</label>
                      <div class="input-widget">
                        <table border="" style="width: 100%">
                          <tr v-for="(item, index) in message_form.movies" :key="index">
                            <td>
                              <img :src="$store.state.ASSETS_BASE_URL +
                                'assets/uploaded/' +
                                item.cover
                                " style="object-fit: cover; margin: 10px" width="70" height="40" />
                              {{ item.title }}
                            </td>
                            <td>
                              <button @click="removeMovieToTemp(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <select style="width: 160px" v-model="selected_movies_id">
                                <option v-for="(item, index) in cagura_movies" :key="index" :value="item.id">
                                  {{ item.title }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <button @click="addMoviesToTemp()">Add</button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="preview-email" v-if="message_form.type == 'email'">
                    <iframe frameborder="0" height="100%" width="100%" class="email_send_prev">
                    </iframe>
                  </div>
                </div>
              </div>
              <div class="footer">
                <c-button :loading="send_loading" @click="sendMessage()" class="btn-success">Send</c-button>
                <button @click="show_message_form = false" class="btn-default">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminContainer>
</template>

<script>
import CodeEditor from "../components/partials/CodeEditor.vue";
export default {
  components: {
    CodeEditor,
  },
  data: () => ({
    menu: false,
    loading: false,
    show_form: false,
    contacts_data: [],
    file: "",
    show_message_form: false,
    message_form: {
      type: "",
      template_id: null,
      subject: "",
      maillist_id: "",
      temp_keys: [],
      product: [],
      shop: [],
      movies: [],
      has_products: false,
      has_shop: false,
      has_movie: false,
      message: "",
      customer_id: null,
    },
    send_loading: false,
    view: "email_view",
    email_temp_form: {
      id: null,
      name: null,
      content_temp: "<h1>Main temp</h1>",
      product_temp: "<h1>Product temp </h1>",
      shop_temp: "<h1>Shop Temp</h1>",
      movie_temp: "<h1>Movie Temp</h1>",
      has_products: false,
      has_shop: false,
      has_movie: false,
      temp_keys: "",
    },
    temp_key_name: "",
    temp_keys_holder: [],
    show_new_email_temp_form: false,
    email_temp_data: [],
    show_mail_temp_editor: false,
    show_email_temp_key: false,
    selected_temp: null,
    cagura_shops: [],
    cagura_products: [],
    cagura_movies: [],
    selected_products_id: null,
    selected_shops_id: null,
    selected_movies_id: null,
    update_temp_type: "main",
    //Maillist
    show_mail_list_form: false,
    maillist_data: [],
    maillist_loading: false,
    maillist_form: {
      id: null,
      name: "",
    },
    selectted_maillist: null,
    add_maillist_member: false,
    maillist_member_loading: false,
    maillist_member_form: {
      id: null,
      maillist_id: null,
      type: "email",
      email: "",
      start: 0,
      end: 1,
    },
    view_maillist_member: false,
  }),
  watch: {
    "email_temp_form.content_temp"(val) {
      let vm = this;
      vm.renderHtml(val, ".main_temp_prev");
    },
    "email_temp_form.product_temp"(val) {
      let vm = this;
      vm.renderHtml(val, ".main_temp_prev");
    },
    "email_temp_form.shop_temp"(val) {
      let vm = this;
      vm.renderHtml(val, ".main_temp_prev");
    },
    "email_temp_form.movie_temp"(val) {
      let vm = this;
      vm.renderHtml(val, ".main_temp_prev");
    },
    message_form: {
      handler(val) {
        let vm = this;
        clearTimeout(vm.preview_time_out);
        vm.preview_time_out = setTimeout(() => {
          vm.getEmailPreview(val);
        }, 1000);
      },
      deep: true,
    },
  },
  mounted() {
    let vm = this;
    vm.getTempEmails();
    vm.getMailList();
    vm.getShops();
    vm.getMovies();
  },
  methods: {
    getShops() {
      let vm = this;
      vm.$store
        .dispatch("SHOP_GET_ALL")
        .then((response) => {
          vm.cagura_shops = response.data.return;
        })
        .catch(({ response }) => {
          console.error("SHOP_GET_ALL", response);
        });
    },
    getMovies() {
      let vm = this;
      vm.$store
        .dispatch("GET_ALL_MOVIES_DATA")
        .then((response) => {
          vm.cagura_movies = response.data.return;
        })
        .catch(({ response }) => {
          console.log("GET_ALL_MOVIES_DATA", { error });
        });
    },
    switchTab(type) {
      let vm = this;
      vm.view = type;
    },
    deleteMailListMember(item, index) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("MAILLIST_MEMBER_DELETE", { id: item.id })
              .then((response) => {
                vm.selectted_maillist.members.splice(index, 1);
              })
              .catch(({ response }) => {
                console.error("MAILLIST_MEMBER_DELETE", response);
              });
          }
        },
      });
    },
    ViewMemberToMaillist(item) {
      let vm = this;
      vm.selectted_maillist = item;
      vm.view_maillist_member = true;
    },
    submitMailListMemberForm() {
      let vm = this;
      vm.maillist_member_loading = true;
      vm.$store
        .dispatch("MAILLIST_ADDNEW_MEMBER", vm.maillist_member_form)
        .then((response) => {
          vm.maillist_member_loading = false;
          vm.add_maillist_member = false;
          vm.getMailList();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          vm.maillist_member_loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("MAILLIST_ADDNEW_MEMBER", { error });
        });
    },
    AddMemberToMaillist(item) {
      let vm = this;
      vm.maillist_member_form.maillist_id = item.id;
      vm.add_maillist_member = true;
    },
    submitMailListForm() {
      let vm = this;
      if (!vm.maillist_form.id) {
        vm.maillist_loading = true;
        vm.$store
          .dispatch("MAILLIST_ADDNEW", vm.maillist_form)
          .then((response) => {
            vm.maillist_loading = false;
            vm.show_mail_list_form = false;
            vm.getMailList();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.maillist_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("MAILLIST_ADDNEW", { error });
          });
      } else {
        vm.maillist_loading = true;
        vm.$store
          .dispatch("MAILLIST_UPDATE", vm.maillist_form)
          .then((response) => {
            vm.maillist_loading = false;
            vm.show_mail_list_form = false;
            vm.getMailList();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.maillist_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("MAILLIST_UPDATE", { error });
          });
      }
    },
    updateMailListItem(item) {
      let vm = this;
      vm.maillist_form.id = item.id;
      vm.maillist_form.name = item.name;
      vm.show_mail_list_form = true;
    },
    addNewMailList() {
      let vm = this;
      vm.maillist_form = {
        id: null,
        name: "",
      };
      vm.show_mail_list_form = true;
    },
    deleteMailListItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("MAILLIST_DELETE", { id: item.id })
              .then((response) => {
                vm.getMailList();
              })
              .catch(({ response }) => {
                console.error("MAILLIST_DELETE", response);
              });
          }
        },
      });
    },
    getMailList() {
      let vm = this;
      vm.$store
        .dispatch("MAILLIST_GET_ALL")
        .then((response) => {
          vm.maillist_data = response.data.return.map((item) => {
            vm.$store.state.eventBus.$set(item, "is_menu_open", false);
            return item;
          });
        })
        .catch(({ response }) => {
          console.error("MAILLIST_GET_ALL", response);
        });
    },
    getEmailPreview(form) {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_EMAIL_MAKE_PREVIEW", form)
        .then((response) => {
          if (response.data.status == "ok") {
            vm.renderHtml(response.data.return, ".email_send_prev");
          }
        })
        .catch(({ response }) => {
          console.error("CUSTOMER_EMAIL_MAKE_PREVIEW", response);
        });
    },
    removeShopToTemp(index) {
      let vm = this;
      vm.message_form.shop.splice(index, 1);
    },
    addShopToTemp() {
      let vm = this;
      let data = vm.cagura_shops.find((item) => {
        return item.id == vm.selected_shops_id;
      });
      if (data) {
        vm.message_form.shop.push(data);
      }
    },
    removeMovieToTemp(index) {
      let vm = this;
      vm.message_form.movies.splice(index, 1);
    },
    addMoviesToTemp() {
      let vm = this;
      let data = vm.cagura_movies.find((item) => {
        return item.id == vm.selected_movies_id;
      });
      if (data) {
        vm.message_form.movies.push(data);
      }
    },
    removeProductToTemp(index) {
      let vm = this;
      vm.message_form.product.splice(index, 1);
    },
    addProductToTemp(data) {
      let vm = this;
      if (data) {
        vm.message_form.product.push(data);
      }
    },
    selectEmailTemp(item) {
      let vm = this;
      vm.selected_temp = item;
      vm.message_form.has_products = item.has_products;
      vm.message_form.has_shop = item.has_shop;
      vm.message_form.has_movie = item.has_movie;
      vm.message_form.template_id = item.id;
      let our_keys = JSON.parse(item.temp_keys);
      vm.message_form.temp_keys = [];
      our_keys.map((val) => {
        let obj = {
          key: val.key,
          value: "",
        };
        vm.message_form.temp_keys.push(obj);
      });
    },
    renderHtml(html, el) {
      setTimeout(() => {
        var iframe = document.querySelector(el);
        iframe =
          iframe.contentWindow ||
          iframe.contentDocument.document ||
          iframe.contentDocument;
        iframe.document.open();
        iframe.document.write(html);
        iframe.document.close();
      }, 200);
    },
    showMainTempEditor(item, type) {
      let vm = this;
      vm.email_temp_form.id = item.id;
      vm.email_temp_form.name = item.name;
      vm.email_temp_form.content_temp = item.content_temp;
      vm.email_temp_form.product_temp = item.product_temp;
      vm.email_temp_form.shop_temp = item.shop_temp;
      vm.email_temp_form.movie_temp = item.movie_temp;
      vm.email_temp_form.has_products = item.has_products;
      vm.email_temp_form.has_shop = item.has_shop;
      vm.email_temp_form.has_movie = item.has_movie;
      vm.email_temp_form.temp_keys = item.temp_keys;

      vm.temp_key_name = "";
      vm.temp_keys_holder = JSON.parse(item.temp_keys);
      vm.show_mail_temp_editor = true;
      vm.update_temp_type = type;

      if (type == "main") {
        vm.renderHtml(item.content_temp, ".main_temp_prev");
      } else if (type == "product") {
        vm.renderHtml(item.product_temp, ".main_temp_prev");
      } else if (type == "shop") {
        vm.renderHtml(item.shop_temp, ".main_temp_prev");
      } else if (type == "movie") {
        vm.renderHtml(item.movie_temp, ".main_temp_prev");
      }
    },
    editEmailTempItem(item) {
      let vm = this;
      vm.email_temp_form.id = item.id;
      vm.email_temp_form.name = item.name;
      vm.email_temp_form.content_temp = item.content_temp;
      vm.email_temp_form.product_temp = item.product_temp;
      vm.email_temp_form.shop_temp = item.shop_temp;
      vm.email_temp_form.movie_temp = item.movie_temp;
      vm.email_temp_form.has_products = item.has_products;
      vm.email_temp_form.has_shop = item.has_shop;
      vm.email_temp_form.has_movie = item.has_movie;
      vm.email_temp_form.temp_keys = item.temp_keys;

      vm.temp_key_name = "";
      vm.temp_keys_holder = JSON.parse(item.temp_keys);

      vm.show_new_email_temp_form = true;
    },
    confirmDeleteEmailTemp(id) {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_EMAIL_DELETE_TEMPLATE", { id: id })
        .then((response) => {
          vm.getTempEmails();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("CUSTOMER_EMAIL_DELETE_TEMPLATE", { error });
        });
    },
    deleteEmailTempItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteEmailTemp(item.id);
          }
        },
      });
    },
    getTempEmails() {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_EMAIL_GET_TEMPLATE")
        .then((response) => {
          vm.email_temp_data = response.data.return;
        })
        .catch(({ response }) => { });
    },
    submitEmailTempForm() {
      let vm = this;
      vm.loading = true;
      vm.email_temp_form.temp_keys = JSON.stringify(vm.temp_keys_holder);
      if (!vm.email_temp_form.id) {
        vm.$store
          .dispatch("CUSTOMER_EMAIL_ADD_TEMPLATE", vm.email_temp_form)
          .then((response) => {
            vm.loading = false;
            vm.getTempEmails();
            vm.show_new_email_temp_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_EMAIL_ADD_TEMPLATE", { error });
          });
      } else {
        vm.$store
          .dispatch("CUSTOMER_EMAIL_UPDATE_TEMPLATE", vm.email_temp_form)
          .then((response) => {
            vm.loading = false;
            vm.getTempEmails();
            vm.show_new_email_temp_form = false;
            vm.show_mail_temp_editor = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_EMAIL_UPDATE_TEMPLATE", { error });
          });
      }
    },
    addNewKey() {
      let vm = this;
      let obj = {
        name: vm.temp_key_name,
        key: vm.temp_key_name.toLowerCase().replaceAll(" ", "_"),
      };
      vm.temp_keys_holder.push(obj);
      vm.temp_key_name = "";
    },
    removeTempKey(index) {
      let vm = this;
      vm.temp_keys_holder.splice(index, 1);
    },
    addNewEmailTemp() {
      let vm = this;
      vm.email_temp_form = {
        id: null,
        name: null,
        content_temp: "<h1>Main temp</h1>",
        product_temp: "<h1>Product temp </h1>",
        shop_temp: "<h1>Shop Temp</h1>",
        movie_temp: "<h1>Movie Temp</h1>",
        has_products: false,
        has_shop: false,
        has_movie: false,
        temp_keys: "",
      };
      vm.temp_keys_holder = [];
      vm.show_new_email_temp_form = true;
    },
    showSendForm() {
      let vm = this;
      vm.message_form = {
        type: "email",
        template_id: null,
        subject: "",
        maillist_id: "",
        temp_keys: [],
        product: [],
        shop: [],
        movies: [],
        has_products: false,
        has_shop: false,
        has_movie: false,
        message: "",
      };
      vm.show_message_form = true;
    },
    sendMessage() {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? Everything is it fine? Check twice, If Everything is fine then send`,
        button: {
          no: "No",
          yes: "Send",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.send_loading = true;
            vm.$store
              .dispatch("CUSTOMER_SEND_MESSAGE", vm.message_form)
              .then((response) => {
                vm.send_loading = false;
                vm.show_message_form = false;
                vm.message = "";
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              })
              .catch(({ response }) => {
                vm.send_loading = false;
                console.error("CUSTOMER_SEND_MESSAGE", { response });
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.message,
                });
              });
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .call-actions {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .options {
            display: flex;
            align-items: center;

            button {
              margin-right: 8px;
            }
          }

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 61px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;
    z-index: 999999;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      &.form-mail-edito {
        width: 98% !important;
        height: 98% !important;
      }

      &.form-send {
        width: 98% !important;
        height: 98% !important;
      }

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }

        .options {
          position: relative;

          .keys-btn {
            .email-temp-keys-widget {
              position: absolute;
              top: 45px;
              right: 0px;
              background: #fff;
              box-shadow: 0px 0px 18px #000;
              padding: 10px;
              border-radius: 5px;
              height: 400px;
              overflow-y: auto;

              ul {
                padding-left: 0px;
                list-style: none;

                li {
                  padding: 5px 10px;
                }
              }
            }
          }
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
        display: flex;
        flex-direction: column;

        .email-item {
          display: flex;
          padding: 5px;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #ddd;
          margin-bottom: 10px;
          justify-content: space-between;
        }

        .message-container {
          height: 100%;
          display: flex;

          .form {
            width: 50%;
            padding: 0px 10px;
            overflow-y: auto;
          }

          .main_form {
            width: 100%;
          }

          .preview-email {
            width: 50%;
            overflow-y: auto;
            border: 2px solid #000;
          }
        }

        .code-preview {
          height: 100%;
          display: flex;

          .code {
            width: 50%;
          }

          .preview {
            width: 50%;
            overflow-y: auto;
            border: 2px solid #000;
          }
        }
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
