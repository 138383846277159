import {
    CONTACTS_ADD_ITEM,
    GET_ALL_CONTACTS,
    CONTACTS_UPDATE_ITEM,
    DELETE_CONTACT_ITEM,
    IMPORT_CONTACTS,
    SEND_MESSAGE
} from "../actions/contacts";

import {
    API_BASE_URL,
    CONTACTS_ADD_ITEM_URL,
    GET_ALL_CONTACTS_URL,
    CONTACTS_UPDATE_ITEM_URL,
    DELETE_CONTACT_ITEM_URL,
    IMPORT_CONTACTS_URL,
    SEND_MESSAGE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [SEND_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEND_MESSAGE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [IMPORT_CONTACTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + IMPORT_CONTACTS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    [DELETE_CONTACT_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_CONTACT_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CONTACTS_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CONTACTS_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_ALL_CONTACTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_CONTACTS_URL,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CONTACTS_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CONTACTS_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};