<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <h3>CUSTOMER AFFILIATE PROGRAM</h3>
      <div class="call-actions">
        <button class="btn-success" @click="addNew()">
          <img src="@/assets/images/icons/plus.svg" /> CREATE AFFILIATE
        </button>
      </div>
    </div>
    <div class="body-widget">
      <div class="table-widget">
        <table>
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Link</th>
            <th></th>
          </tr>
          <tr v-for="(item, index) in affiliate_data" :key="index">
            <td>{{ item.title }}</td>
            <td>
              <img class="affiliate-image" :src="$store.state.ASSETS_BASE_URL + 'assets/uploaded/' + item.image
                " />
            </td>
            <td>{{ item.link }}</td>
            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="false"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" @click.prevent="viewItem(item)">
                    <span class="fa fa-eye"></span> View
                  </a>
                  <a class="dropdown-item" @click.prevent="editItem(item)">
                    <img src="@/assets/images/icons/edit.svg" /> Edit
                  </a>
                  <a class="dropdown-item" @click.prevent="deleteItem(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Add Modal -->
    <div class="overlay" v-if="show_affiliate_form">
      <div class="form-widget">
        <div class="header">
          <h3 v-if="!form.id">Add new affiliate item</h3>
          <h3 v-else>Update affiliate item</h3>
        </div>
        <div class="body">
          <div class="input-group">
            <label>Select Affiliate item</label>
            <div class="input-widget">
              <select v-model="form.affiliate_id" class="bg-cagura-form-control">
                <option :value="item.id" v-for="(item, index) in affiliate_select_data" :key="index">
                  {{ item.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-group">
            <label>User needed</label>
            <div class="input-widget">
              <input type="text" v-model="form.needed_user" class="bg-cagura-form-control" />
            </div>
          </div>
        </div>
        <div class="footer">
          <c-button :loading="loading" @click="submitForm()" class="btn-success">Save</c-button>
          <button @click="show_affiliate_form = false" class="btn-default">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    loading: false,
    form: {
      id: null,
      customer_id: null,
      affiliate_id: null,
      needed_user: 0,
    },
    start_date: "",
    end_date: "",
    show_affiliate_form: false,
    affiliate_data: [],
    affiliate_select_data: [],
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getAffiliateItem();
    vm.getCustomerAffiliateItem();
  },
  methods: {
    onChangeEndDate(date, dateString) {
      let vm = this;
      vm.form.end_date = dateString;
    },
    onChangeStartDate(date, dateString) {
      let vm = this;
      vm.form.start_date = dateString;
    },
    viewItem(item) {
      let vm = this;
      vm.$router.push({ path: `/affiliate/view/${item.id}` });
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_AFFILIATE_DELETE_ITEM", { id: id })
        .then((response) => {
          vm.getCustomerAffiliateItem();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CUSTOMER_AFFILIATE_DELETE_ITEM", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.cus_aff_id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.customer_id = vm.$route.params.id;
      vm.form.affiliate_id = item.id;
      vm.form.needed_user = item.needed_user;
      vm.show_affiliate_form = true;
    },
    getAffiliateItem() {
      let vm = this;
      vm.$store
        .dispatch("AFFILIATE_GET_ITEMS")
        .then((response) => {
          vm.affiliate_select_data = response.data.return;
        })
        .catch(({ response }) => {
          console.log("AFFILIATE_GET_ITEMS", { error });
        });
    },
    getCustomerAffiliateItem() {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_AFFILIATE_GET_ITEMS", {
          customer_id: vm.$route.params.id,
        })
        .then((response) => {
          vm.affiliate_data = response.data.return;
        })
        .catch(({ response }) => {
          console.log("CUSTOMER_AFFILIATE_GET_ITEMS", { error });
        });
    },
    submitForm() {
      let vm = this;
      vm.loading = true;
      if (!vm.form.id) {
        vm.$store
          .dispatch("CUSTOMER_AFFILIATE_ADD_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getCustomerAffiliateItem();
            vm.show_affiliate_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_AFFILIATE_ADD_ITEM", { error });
          });
      } else {
        vm.$store
          .dispatch("CUSTOMER_AFFILIATE_UPDATE_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getCustomerAffiliateItem();
            vm.show_affiliate_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_AFFILIATE_UPDATE_ITEM", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_affiliate_form = true;
      vm.form = {
        id: null,
        customer_id: null,
        affiliate_id: null,
        needed_user: 0,
      };
      if (vm.$route.params.id) {
        vm.form.customer_id = vm.$route.params.id;
      }
    },
    onAffiliateImageSelected(file) {
      let vm = this;
      vm.form.main_image = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
