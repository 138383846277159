export const MOVIE_ADD_ITEM = "MOVIE_ADD_ITEM";
export const MOVIE_UPDATE_ITEM = "MOVIE_UPDATE_ITEM";
export const GET_ALL_MOVIES_DATA = "GET_ALL_MOVIES_DATA";
export const DELETE_MOVIE = "DELETE_MOVIE";

export const SEASONS_ADD_ITEM = "SEASONS_ADD_ITEM";
export const SEASONS_UPDATE_ITEM = "SEASONS_UPDATE_ITEM";
export const GET_MOVIES_SEASONS = "GET_MOVIES_SEASONS";
export const DELETE_MOVIES_SEASON_ITEM = "DELETE_MOVIES_SEASON_ITEM";

export const MOVIE_EPISODE_ADD_ITEM = "MOVIE_EPISODE_ADD_ITEM"
export const MOVIE_EPISODE_UPDATE_ITEM = "MOVIE_EPISODE_UPDATE_ITEM";
export const GET_ALL_MOVIES_EPISODE_DATA = "GET_ALL_MOVIES_EPISODE_DATA";
export const DELETE_MOVIE_EPISODE = "DELETE_MOVIE_EPISODE";

export const MOVIES_MOVER_TO_CDN_SERVER = "MOVIES_MOVER_TO_CDN_SERVER";
export const MOVIES_STOP_PROCESS_ON_SERVER = "MOVIES_STOP_PROCESS_ON_SERVER";
export const MOVIES_DELETE_FILES = "MOVIES_DELETE_FILES";
export const MOVIES_SAVE_UPLOADED_MOVIE_INFO = "MOVIES_SAVE_UPLOADED_MOVIE_INFO";
export const MOVIES_DELETE_CDN_FILE = "MOVIES_DELETE_CDN_FILE";
export const MOVIES_DELETE_DB_INFO = "MOVIES_DELETE_DB_INFO";

export const STREAMING_ADD_ITEM = "STREAMING_ADD_ITEM";
export const STREAMING_UPDATE_ITEM = "STREAMING_UPDATE_ITEM";
export const STREAMING_GET_ALL = "STREAMING_GET_ALL";
export const STREAMING_DELETE_ITEM = 'STREAMING_DELETE_ITEM';
export const STREAMING_PROCESS_STREAMING = "STREAMING_PROCESS_STREAMING";
export const STREAMING_STOP_PROCESS_STREAMING = "STREAMING_STOP_PROCESS_STREAMING";
export const STREAMING_DELETE_PROCESSED_FILES = "STREAMING_DELETE_PROCESSED_FILES";

export const MOVIES_ADD_CATEGORY = "MOVIES_ADD_CATEGORY";
export const MOVIES_UPDATE_CATEGORY = "MOVIES_UPDATE_CATEGORY";
export const MOVIES_GET_ALL_CATEGORIES = "MOVIES_GET_ALL_CATEGORIES";
export const MOVIES_DELETE_CATEGORY = "MOVIES_DELETE_CATEGORY";
export const MOVIES_GET_ALL_NON_CATEGORIZED = "MOVIES_GET_ALL_NON_CATEGORIZED";
export const MOVIES_ADD_CATEGORIZED_MOVIE = "MOVIES_ADD_CATEGORIZED_MOVIE";
export const MOVIES_REMOVE_CATEGORIZED_MOVIE = "MOVIES_REMOVE_CATEGORIZED_MOVIE";

export const MOVIES_PROCESS_WITHDRAW = "MOVIES_PROCESS_WITHDRAW";