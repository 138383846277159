import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuescroll from "vuescroll/dist/vuescroll-native";
import "vuescroll/dist/vuescroll.css";
import "./scss/main.scss";
import {
    Select,
    Progress,
    Alert,
    Input,
    Icon,
    DatePicker,
    Button,
    Tabs,
    Empty,
    Spin,
    TimePicker,
    Checkbox,
    Rate,
    Popconfirm,
    Tooltip,
    Slider
} from 'ant-design-vue';
import "./scss/ant.scss";
require('vue2-animate/dist/vue2-animate.min.css')
import * as VueGoogleMaps from 'vue2-google-maps';
import AppMix from './mixin/appMix.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';
import VueLocalStorage from 'vue-localstorage';
import VueClosable from 'vue-closable';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CButton from './components/partials/CButton.vue';
import DropdownMenu from "@innologica/vue-dropdown-menu";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueClipboard from 'vue-clipboard2'
import VueContentLoading from 'vue-content-loading';
import Uploader from './components/modals/upload-helper';
import GoogleMap from './components/modals/map-helper';
import AdminContainer from "./views/AdminContainer.vue";
import MoviePlayerInput from "./components/modals/MoviePlayerInput.vue";
import Player from "./components/modals/Player.vue"
import selectProductsModal from "./components/modals/selectProductsModal.vue";
import feedImage from "./components/partials/feedImage.vue"




Vue.component('AdminContainer', AdminContainer);
Vue.component('VueContentLoading', VueContentLoading);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('CButton', CButton);
Vue.component('Loading', Loading);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('MoviePlayerInput', MoviePlayerInput);
Vue.component('Player', Player);
Vue.component('selectProductsModal', selectProductsModal);
Vue.component('feedImage', feedImage);


Vue.use(GoogleMap);
Vue.use(Uploader);
Vue.use(Progress);
Vue.use(Alert);
Vue.use(Input);
Vue.use(Icon);
Vue.use(DatePicker);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Spin);
Vue.use(Empty);
Vue.use(TimePicker);
Vue.use(Checkbox);
Vue.use(Rate);
Vue.use(Popconfirm);
Vue.use(Tooltip);
Vue.use(Slider);
Vue.use(Select);
Vue.use(VueConfirmDialog);
Vue.use(Loading);
Vue.use(VueClosable);
Vue.use(VueLocalStorage);
Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(VueClipboard);
Vue.mixin(AppMix);
Vue.use(VueGoogleMaps, {
    load: {
        key: store.state.GOOGLE_MAP_KEY,
        libraries: 'places',
    }
});
Vue.use(vuescroll, {
    ops: {
        bar: {
            showDelay: 200,
            onlyShowBarOnScroll: true,
            keepShow: true,
            background: '#8dc73f',
            opacity: 1,
            specifyBorderRadius: false,
            minSize: 0,
            size: '10px',
            disable: false
        }
    }
});

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false


// Setting for auth on load app
if (Vue.localStorage.get("user_data")) {
    let user_data = JSON.parse(Vue.localStorage.get("user_data"));
    store.commit("SET_TOKEN", user_data.access_token);
    store.dispatch("VERIFY_TOKEN", {
        token: user_data.access_token
    }).then(response => {
        if (response.data.status == 'ok') {
            store.commit("SET_TOKEN", user_data.access_token);
        } else if (response.data.status == 'remove') {
            Vue.localStorage.remove("user_data");
            router.push({ name: 'login' });
        }
    });
}

// Validate every axios request
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    try {
        if (error.response.status === 401) {
            store.commit("SET_TOKEN", null);
            Vue.localStorage.remove("user_data");
            router.push({ name: 'login' });
        }
    } catch (e) { }
    return Promise.reject(error);
});

// Router validation
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (Vue.localStorage.get("user_data")) {
            let user_data = JSON.parse(Vue.localStorage.get("user_data"));
            store.commit("SET_TOKEN", user_data.access_token);
            store.dispatch("VERIFY_TOKEN", {
                token: user_data.access_token
            }).then(response => {
                if (response.data.status == 'ok') {
                    store.commit("SET_TOKEN", user_data.access_token);
                } else if (response.data.status == 'remove') {
                    Vue.notify({
                        group: "status",
                        type: "warn",
                        title: "Error message",
                        text: 'Your session expired, Please login again',
                    });
                    store.commit("SET_TOKEN", null);
                    Vue.localStorage.remove("user_data");
                    router.push({ name: 'login' });
                }
            });
        } else {
            Vue.notify({
                group: "status",
                type: "warn",
                title: "Error message",
                text: 'Your session expired, Please login again',
            });
            store.commit("SET_TOKEN", null);
            Vue.localStorage.remove("user_data");
            router.push({ name: 'login' });
        }
    } else {
        next()
        // make sure to always call next()!
    }

});


if (document.querySelector('.l-progress-bar')) {
    document.querySelector('.l-progress-bar').style.width = "100%";
    document.querySelector('#loader-overlay').style.display = "none";
}

const el = document.querySelector('#app');
if (el) {
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
    if (process.env.ENV !== "production") {
        console.log('Vue app is running in development mode');
    }
}