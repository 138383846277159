export const ACCOUNT_LOGIN = "ACCOUNT_LOGIN";
export const NOTIFICATION_GET_ALL = "NOTIFICATION_GET_ALL";
export const NOTIFICATION_MAKE_IT_SEEN = "NOTIFICATION_MAKE_IT_SEEN";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_ALL_CAGURA_USER = "GET_ALL_CAGURA_USER";

export const ADMIN_USER_DELETE_ITEM = "ADMIN_USER_DELETE_ITEM";
export const ADMIN_USER_GET_USERS = "ADMIN_USER_GET_USERS";
export const ADMIN_USER_ADD_NEW = "ADMIN_USER_ADD_NEW";
export const ADMIN_USER_UPDATE = "ADMIN_USER_UPDATE";

export const SAVE_PERMISSION_ITEM = "SAVE_PERMISSION_ITEM";
export const ADMIN_GET_PERMSSION_LIST = "ADMIN_GET_PERMSSION_LIST";
export const ADMIN_CHANGE_PERMSSION = "ADMIN_CHANGE_PERMSSION";

export const REPORT_GET_REPORTED_MOVIES = "REPORT_GET_REPORTED_MOVIES";
export const REPORT_DELETE_REPORTED_ITEM = "REPORT_DELETE_REPORTED_ITEM";
export const REPORT_DELETE_REPORTED_POST = "REPORT_DELETE_REPORTED_POST";
export const REPORT_DELETE_POST = "REPORT_DELETE_POST";
export const REPORT_GET_BAD_POST = "REPORT_GET_BAD_POST";
export const REPORT_PUT_ONLINE_POST = "REPORT_PUT_ONLINE_POST";