<template>
  <AdminContainer>
    <div class="wrapper">
      <div class="wrapper-header">
        <div class="tabs">
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'all_shops' }"
            @click="switchTab('all_shops')"
          >
            All Shops
          </button>
          <button
            class="tab-item"
            :class="{ active: tab_switch == 'popular_shops' }"
            @click="switchTab('popular_shops')"
          >
            Popular Shops
          </button>
        </div>
      </div>
      <div class="wrapper-body">
        <AllShops v-if="tab_switch == 'all_shops'"></AllShops>
        <PopularShops v-if="tab_switch == 'popular_shops'"></PopularShops>
      </div>
    </div>
  </AdminContainer>
</template>
<script>
import AllShops from "../views/Shop/AllShops.vue";
import PopularShops from "../views/Shop/PopularShops.vue";
export default {
  components: {
    AllShops,
    PopularShops,
  },
  data: () => ({
    tab_switch: "all_shops",
  }),
  watch: {},
  mounted() {},
  methods: {
    switchTab(tab) {
      let vm = this;
      vm.tab_switch = tab;
    },
  },
};
</script>
<style lang="scss" >
.wrapper {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .wrapper-header {
    width: 100%;
    height: 70px;
    background: rgb(235, 235, 235);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .tabs {
      display: flex;
      .tab-item {
        border: none;
        margin-right: 15px;
        text-transform: uppercase;
        background: transparent;
        &.active {
          background: #fff;
          padding: 5px 10px;
          border-radius: 5px;
          color: #8dc73f;
        }
        &:hover {
          color: #8dc73f;
        }
      }
    }
  }
  .wrapper-body {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
}
</style>
