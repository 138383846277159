<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <h3>CATEGORIES</h3>
      <div class="call-actions">
        <button class="btn-success" @click="addNew()">
          <img src="@/assets/images/icons/plus.svg" /> ADD CATEGORY
        </button>
      </div>
    </div>
    <div class="body-widget">
      <div class="table-widget">
        <table>
          <tr>
            <th>Category name</th>
            <th>Image</th>
            <th>Prio</th>
            <th>Type</th>
            <th>Products Number</th>
            <th></th>
          </tr>
          <tr v-for="(item, index) in categories_data" :key="index" :class="{
            group: item.type == 'group',
            'in-group': item.type == 'in_group',
          }">
            <td>{{ item.name }}</td>
            <td>
              <img class="affiliate-image" :src="$store.state.ASSETS_BASE_URL + 'assets/uploaded/' + item.image
                " />
            </td>
            <td>{{ item.prio }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.products.length }}</td>
            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="false"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" v-if="item.type != 'group'" @click.prevent="viewItem(item)">
                    <span class="fa fa-eye"></span> View
                  </a>
                  <a class="dropdown-item" @click.prevent="editItem(item)">
                    <img src="@/assets/images/icons/edit.svg" /> Edit
                  </a>
                  <a class="dropdown-item" @click.prevent="deleteItem(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Add Modal -->
    <div class="overlay" v-if="show_form">
      <div class="form-widget">
        <div class="header">
          <h3 v-if="!form.id">Add new category</h3>
          <h3 v-else>Update category</h3>
        </div>
        <div class="body">
          <div class="input-group">
            <label>Type</label>
            <div class="input-widget">
              <select type="number" v-model="form.type" class="bg-cagura-form-control">
                <option value="list">List</option>
                <option value="in_group">In Group</option>
                <option value="group">Group</option>
              </select>
            </div>
          </div>
          <div class="input-group" v-if="form.type == 'group'">
            <label>Add Categories</label>
            <div class="input-widget">
              <table class="categories-table">
                <tr>
                  <th>Category name</th>
                  <th>Option</th>
                </tr>
                <tr v-for="(item, index) in categories_categories" :key="index">
                  <td>{{ item.name }}</td>
                  <td>
                    <button @click="removeSelectedCategory(index)">
                      Remove
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <select type="number" v-model="selected_category" class="bg-cagura-form-control">
                      <option value="">Select Category</option>
                      <template v-for="(item, index) in categories_data.filter(
                        (item) => item.type == 'in_group'
                      )">
                        <option :key="index" :value="item">
                          {{ item.name }}
                        </option>
                      </template>
                    </select>
                  </td>
                  <td><button @click="addselectedCategory()">Add</button></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="input-group">
            <label>Category name</label>
            <div class="input-widget">
              <input type="text" v-model="form.name" class="bg-cagura-form-control" />
            </div>
          </div>
          <div class="input-group">
            <label>Select Image</label>
            <div class="input-widget">
              <upload-trigger :open="false" :enableinput="false" :required="false" :multiple="false"
                location="assets/uploaded/" :url="$store.state.SITE_ASSETS_BASE_URL"
                :uploadurl="$store.state.BASE_URL + 'upload'" name="main-image" :value="form.image" @onFileSelected="(file) => {
                    if (file) {
                      form.image = file;
                    }
                  }
                  ">
                <div class="image-upload-widget">Choose Image to upload</div>
              </upload-trigger>
            </div>
          </div>
          <div class="input-group">
            <label>Prio</label>
            <div class="input-widget">
              <input type="number" v-model="form.prio" class="bg-cagura-form-control" />
            </div>
          </div>
          <div class="input-group" v-if="form.type == 'list' || form.type == 'in_group'">
            <label>Description</label>
            <div class="input-widget">
              <textarea v-model="form.description" class="bg-cagura-textarea-form-control"></textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <c-button :loading="category_loading" @click="submitCategory()" class="btn-success">Save</c-button>
          <button @click="show_form = false" class="btn-default">Cancel</button>
        </div>
      </div>
    </div>

    <!-- View products -->
    <div class="overlay" v-if="view_product_modal">
      <div class="form-widget">
        <div class="header" style="justify-content: space-between">
          <h3 v-if="!is_editing">View products</h3>
          <h3 v-else>Edit product</h3>
          <button @click="view_product_modal = false" class="btn-default">
            Close
          </button>
        </div>
        <div class="body" v-if="selected_category && !is_editing">
          <div class="product-item" v-for="(item, index) in selected_category.products" :key="index"
            :class="{ active: item.is_published }">
            <img class="product-image" :src="$store.state.ASSETS_BASE_URL +
              'assets/uploaded/' +
              item.main_image
              " />
            <div class="content">
              <h3 class="product-name">
                {{ item.product_name }} - [{{
                  item.is_published ? "Published" : "Not Published"
                }}]
              </h3>
              <p class="price">Frw {{ item.price }}</p>
              <div class="options">
                <button class="btn-default" @click="editProduct(item)">
                  Edit
                </button>
                <button @click="removeProduct(item)" class="btn-success">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="is_editing" class="body">
          <div class="product-item" v-if="selected_product">
            <img class="product-image" :src="$store.state.ASSETS_BASE_URL +
              'assets/uploaded/' +
              selected_product.main_image
              " />
            <div class="content">
              <h3 class="product-name">
                {{ selected_product.product_name }} - [{{
                  selected_product.is_published ? "Published" : "Not Published"
                }}]
              </h3>
              <p class="price">Frw {{ selected_product.price }}</p>
            </div>
          </div>
          <div class="input-group">
            <label>Status</label>
            <div class="input-widget">
              <select type="number" v-model="product_form.status" class="bg-cagura-form-control">
                <option :value="0">Not published</option>
                <option :value="1">Published</option>
              </select>
            </div>
          </div>
          <div class="input-group">
            <label>Prio</label>
            <div class="input-widget">
              <input type="number" v-model="product_form.prio" class="bg-cagura-form-control" />
            </div>
          </div>
        </div>
        <div class="footer" v-if="!is_editing">
          <selectProductsModal @onSubmit="onAddProduct" type="categorized"></selectProductsModal>
        </div>
        <div class="footer" v-else>
          <c-button :loading="product_loading" @click="submitProductEdit()" class="btn-success">Save</c-button>
          <button @click="is_editing = false" class="btn-default">Back</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    category_loading: false,
    form: {
      id: null,
      name: "",
      image: "",
      prio: 1,
      type: "list",
      categories_ids: "",
      description: "",
    },
    product_form: {
      id: null,
      status: 0,
      prio: 1,
    },
    show_form: false,
    categories_data: [],
    selected_category: "",
    categories_categories: [],
    view_product_modal: false,
    selected_category: null,
    selected_product: null,
    is_editing: false,
    product_loading: false,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getCategories();
  },
  methods: {
    removeSelectedCategory(index) {
      let vm = this;
      vm.categories_categories.splice(index, 1);
    },
    addselectedCategory() {
      let vm = this;
      vm.categories_categories.push(vm.selected_category);
    },
    confirmRemoveProduct(id) {
      let vm = this;
      vm.$store
        .dispatch("CATEGORIZED_PRODUCT_REMOVE_PRODUCT", { id: id })
        .then((response) => {
          vm.getCategories();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CATEGORIZED_PRODUCT_REMOVE_PRODUCT", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    removeProduct(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmRemoveProduct(item.categorized_id);
          }
        },
      });
    },
    submitProductEdit() {
      let vm = this;
      vm.product_loading = true;
      vm.$store
        .dispatch("CATEGORIZED_PRODUCT_UPDATE_PRODUCT", vm.product_form)
        .then((response) => {
          vm.product_loading = false;
          vm.is_editing = false;
          vm.getCategories();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          vm.product_loading = false;
          console.error("CATEGORIZED_PRODUCT_UPDATE_PRODUCT", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    editProduct(item) {
      let vm = this;
      vm.selected_product = item;
      vm.product_form.id = item.categorized_id;
      vm.product_form.status = item.is_published;
      vm.product_form.prio = item.prio;
      vm.is_editing = true;
    },
    onAddProduct(item) {
      let vm = this;
      vm.$store
        .dispatch("CATEGORIZED_PRODUCT_ADD_PRODUCT", {
          product_id: item.id,
          category_id: vm.selected_category.id,
        })
        .then((response) => {
          vm.selected_product_id = 0;
          vm.getCategories();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CATEGORIZED_PRODUCT_ADD_PRODUCT", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    viewItem(item) {
      let vm = this;
      vm.selected_category = item;
      vm.view_product_modal = true;
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("CATEGORIZED_PRODUCT_DELETE_ITEM", { id: id })
        .then((response) => {
          vm.getCategories();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CATEGORIZED_PRODUCT_DELETE_ITEM", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.name = item.name;
      vm.form.image = item.image;
      vm.form.type = item.type;
      vm.form.description = item.description;
      vm.form.categories_ids = JSON.stringify(item.categories);
      vm.categories_categories = item.categories;
      vm.form.prio = item.prio;
      vm.show_form = true;
    },
    getCategories() {
      let vm = this;
      vm.$store
        .dispatch("CATEGORIZED_PRODUCT_GET_ITEMS")
        .then((response) => {
          vm.categories_data = response.data.return
            .map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              item.products = item.products.sort((a, b) => {
                return b.prio - a.prio;
              });
              return item;
            })
            .sort((a, b) => {
              return b.prio - a.prio;
            });
          if (vm.selected_category) {
            vm.selected_category = vm.categories_data.find((item) => {
              return item.id == vm.selected_category.id;
            });
          }
        })
        .catch((error) => {
          console.log("CUSTOMER_SPONSORED_GET_ITEMS", { error });
        });
    },
    submitCategory() {
      let vm = this;
      vm.category_loading = true;
      if (vm.form.type == "group") {
        vm.form.categories_ids = JSON.stringify(
          vm.categories_categories.map((item) => item.id)
        );
      }
      if (!vm.form.id) {
        vm.$store
          .dispatch("CATEGORIZED_PRODUCT_ADD_ITEM", vm.form)
          .then((response) => {
            vm.category_loading = false;
            vm.show_form = false;
            vm.getCategories();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.category_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CATEGORIZED_PRODUCT_ADD_ITEM", { error });
          });
      } else {
        vm.$store
          .dispatch("CATEGORIZED_PRODUCT_UPDATE_ITEM", vm.form)
          .then((response) => {
            vm.category_loading = false;
            vm.show_form = false;
            vm.getCategories();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.category_loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CATEGORIZED_PRODUCT_UPDATE_ITEM", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_form = true;
      vm.form = {
        id: null,
        name: "",
        image: "",
        prio: 0,
        type: "list",
        description: "",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        tr {
          &.group {
            background: rgb(195, 247, 208);
          }

          &.in-group {
            background: rgb(158, 208, 255);
          }
        }

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;

        .product-item {
          display: flex;
          align-items: center;
          border: 1px solid #ddd;
          padding: 5px;
          margin-bottom: 5px;

          &.active {
            background: rgb(156, 243, 207);
          }

          .product-image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            margin-right: 10px;
          }

          .content {
            .options {
              display: flex;

              .btn-default {
                margin-right: 10px;
              }
            }
          }
        }
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}

.categories-table {
  width: 100%;

  tr {}

  td,
  th {
    padding: 5px;
    border: 1px solid #ddd;
  }
}
</style>
