<template>
  <div class="overlay">
    <vue-scroll>
      <div class="modal-wrapper">
        <div class="modal-widget">
          <div class="modal-header">
            <div class="modal-title" v-if="form.id">UPDATE YOUR SHOP</div>
            <div class="modal-title" v-else>CREATE A NEW SHOP</div>
            <div class="modal-tab-header">
              <div class="modal-tab-info">
                <h3 class="modal-tab-title">{{ tab_title }}</h3>
                <span class="modal-tab-desc">{{ tab_desc }}</span>
              </div>
              <div class="modal-tab-buttons">
                <div
                  class="button-item"
                  v-for="({ tab }, i) in tabs"
                  :key="i"
                  :class="{ active: tab.finish }"
                ></div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <vue-scroll>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutLeft"
                :duration="{ enter: 600, leave: 800 }"
              >
                <div class="tab modal-content" v-show="tabs[0].tab.active">
                  <div class="input-group">
                    <div class="input-widget">
                      <select
                        type="text"
                        class="bg-cagura-form-control"
                        name="shop_name"
                        v-model="form.user_id"
                        placeholder="Shop name"
                      >
                        <option :value="null">Select user</option>
                        <option
                          :value="item.id"
                          v-for="(item, index) in user_data"
                          :key="index"
                        >
                          {{ item.first_name }} {{ item.last_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="shop_name"
                        v-model="form.shop_name"
                        placeholder="Shop name"
                      />
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-widget">
                      <vue-tags-input
                        class="tag-input"
                        v-model="category_name"
                        :tags="categories"
                        :autocomplete-items="searchCategories"
                        @tags-changed="onCategoryAdded"
                        :is-duplicate="isDuplicate"
                        placeholder="Add Category (Ex: Clothes, Electronics)"
                      />
                    </div>
                  </div>

                  <div class="input-group">
                    <label>Working Days</label>
                    <div class="input-widget">
                      <div class="checkbox-wrapper">
                        <a-checkbox-group
                          v-model="form.working_days_checked_list"
                          :options="working_days_options"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="pair-input">
                    <div class="input-group">
                      <label>Opening Hour</label>
                      <div class="input-widget">
                        <a-time-picker
                          use12-hours
                          v-model="time_picker.opening_hour"
                          format="h:mm a"
                        />
                      </div>
                    </div>
                    <div class="input-group">
                      <label>Closing Hour</label>
                      <div class="input-widget">
                        <a-time-picker
                          use12-hours
                          v-model="time_picker.closing_hour"
                          format="h:mm a"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="input-group">
                    <label>Shop Logo</label>
                    <div class="input-widget">
                      <upload-trigger
                        :open="false"
                        :enableinput="false"
                        :required="false"
                        :multiple="false"
                        location="assets/uploaded/"
                        :url="$store.state.SITE_ASSETS_BASE_URL"
                        :uploadurl="$store.state.BASE_URL + 'upload'"
                        name="shop-logo"
                        :value="form.shop_logo"
                        @onFileSelected="onShopLogoSelected"
                      >
                        <div class="image-upload-widget">
                          Choose Image to upload
                        </div>
                      </upload-trigger>
                    </div>
                  </div>
                  <div class="input-group">
                    <label>Cover Image</label>
                    <div class="input-widget">
                      <upload-trigger
                        :open="false"
                        :enableinput="false"
                        :required="false"
                        :multiple="false"
                        location="/assets/uploaded/"
                        :url="$store.state.SITE_ASSETS_BASE_URL"
                        :uploadurl="$store.state.BASE_URL + '/upload'"
                        name="shop-cover"
                        :value="form.cover_image"
                        @onFileSelected="onShopCoverSelected"
                      >
                        <div class="image-upload-widget">
                          Choose Image to upload
                        </div>
                      </upload-trigger>
                    </div>
                  </div>
                </div>
              </transition>
              <transition
                name="bounce"
                enter-active-class="bounceInLeft"
                leave-active-class="bounceOutLeft"
                :duration="{ enter: 600, leave: 800 }"
              >
                <div class="tab modal-content" v-show="tabs[1].tab.active">
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="phone"
                        v-model="form.phone"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <div class="input-group">
                    <div class="input-widget">
                      <input
                        type="text"
                        class="bg-cagura-form-control"
                        name="email"
                        placeholder="Email"
                        v-model="form.email"
                      />
                    </div>
                  </div>

                  <div class="input-group">
                    <div class="input-widget">
                      <map-trigger @onSubmit="getLocation">
                        <input
                          type="text"
                          readonly
                          v-model="form.location_name"
                          class="bg-cagura-form-control"
                          name="location"
                          placeholder="Select location"
                        />
                        <a-icon class="location-icon" type="environment" />
                      </map-trigger>
                    </div>
                  </div>
                </div>
              </transition>
            </vue-scroll>
          </div>
          <div class="modal-footer">
            <button
              class="btn-default"
              v-show="tab_current_index > 0"
              @click="prevTab(tab_current_index)"
            >
              BACK
            </button>
            <button
              class="btn-default"
              v-show="tabs[0].tab.active"
              @click="closeModal()"
            >
              CANCEL
            </button>
            <button
              class="btn-success"
              v-show="tab_current_index < 1"
              @click="nextTab(tab_current_index)"
            >
              NEXT
            </button>
            <c-button
              class="btn-success"
              v-show="tabs[1].tab.active"
              :loading="is_loading"
              @click="addShop()"
            >
              <span v-if="!form.id">ADD SHOP</span>
              <span v-else>UPDATE SHOP</span>
            </c-button>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>
<script>
const working_days_options = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const working_days_defaultCheckedList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];
import VueTagsInput from "@johmun/vue-tags-input";
export default {
  name: "add-shop",
  components: {
    VueTagsInput,
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    searchCategories() {
      let vm = this;
      return vm.all_categories
        .filter((i) => {
          return (
            i.text.toLowerCase().indexOf(vm.category_name.toLowerCase()) !== -1
          );
        })
        .slice(0, 10);
    },
  },
  data() {
    return {
      tab_title: "Basic Information",
      tab_desc: "Start by giving us the basic information on the Shop",
      tab_current_index: 0,
      tabs: [
        {
          tab: {
            title: "Basic Information",
            desc: "Start by giving us the basic information on the Shop",
            active: true,
            finish: true,
          },
        },
        {
          tab: {
            title: "Contact Information",
            desc: "Help your clients to reach out to you easily !",
            active: false,
            finish: false,
          },
        },
      ],
      working_days_options,
      selected_location: "",
      category_name: "",
      categories: [],
      time_picker: {
        opening_hour: null,
        closing_hour: null,
      },
      form: {
        id: null,
        user_id: null,
        shop_name: "",
        categories: [],
        working_days_checked_list: working_days_defaultCheckedList,
        opening_hour: "",
        closing_hour: "",
        shop_logo: "",
        cover_image: "",
        phone: "",
        email: "",
        location_name: "",
        location_code: {},
      },
      is_loading: false,
      all_categories: [],
      user_data: [],
    };
  },
  watch: {
    form: {
      handler: function (data) {
        let vm = this;
        if (!vm.value) {
          if (!vm.onload) {
            vm.$localStorage.set("shop_data", JSON.stringify(data));
          } else {
            if (vm.$localStorage.get("shop_data")) {
              vm.form = JSON.parse(vm.$localStorage.get("shop_data"));
              if (vm.form.categories.length > 0) {
                vm.categories = vm.form.categories.map((item) => {
                  return {
                    text: item,
                  };
                });
              }
            }
          }
          vm.onload = false;
        }
      },
      deep: true,
    },
    "time_picker.opening_hour": function (time) {
      let vm = this;
      vm.form.opening_hour = vm.moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    "time_picker.closing_hour": function (time) {
      let vm = this;
      vm.form.closing_hour = vm.moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
    value: function (new_value) {
      let vm = this;
      if (new_value) {
        vm.processItemForUpdate(new_value);
      } else {
        vm.resetInput();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.onload = true;
    // Set time picker
    vm.time_picker.opening_hour = vm.moment(
      "01.01.2020 08:00",
      vm.moment.defaultFormat
    );
    vm.time_picker.closing_hour = vm.moment(
      "01.01.2020 18:00",
      vm.moment.defaultFormat
    );
    if (vm.value) {
      vm.processItemForUpdate(vm.value);
    } else {
      vm.resetInput();
    }
    vm.getAllCategories();
    vm.getCaguraUser();
  },
  methods: {
    getCaguraUser() {
      let vm = this;
      vm.$store
        .dispatch("GET_ALL_CAGURA_USER")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.user_data = response.data.return;
          }
        })
        .catch(({ response }) => {
          console.error("GET_ALL_CAGURA_USER", { response });
        });
    },
    getAllCategories() {
      let vm = this;
      vm.$store
        .dispatch("SHOP_GET_ALL_CATEGORIES")
        .then((response) => {
          if (response.data.status == "ok") {
            vm.all_categories = response.data.return.map((item) => {
              return {
                text: item.category_name,
              };
            });
          }
        })
        .catch(({ response }) => {
          console.error("SHOP_ADD_NEW", { response });
        });
    },
    processItemForUpdate(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.user_id = item.user_id;
      vm.form.shop_name = item.shop_name;
      vm.form.categories = item.categories.map((item) => item.category_name);
      vm.categories = item.categories.map((item) => {
        return {
          text: item.category_name,
        };
      });
      vm.form.working_days_checked_list = JSON.parse(item.working_days);
      vm.form.opening_hour = item.opening_hours;
      vm.form.closing_hour = item.closing_hours;
      vm.time_picker.opening_hour = vm.moment(
        item.opening_hours,
        "YYYY-MM-DD HH:mm:ss"
      );
      vm.time_picker.closing_hour = vm.moment(
        item.closing_hours,
        "YYYY-MM-DD HH:mm:ss"
      );
      vm.form.shop_logo = item.shop_logo;
      vm.form.cover_image = item.cover_image;
      vm.form.phone = item.phone_number;
      vm.form.email = item.email;
      vm.form.location_name = item.location_name;
      vm.form.location_code = item.location_code;
    },
    resetInput() {
      let vm = this;
      vm.time_picker.opening_hour = vm.moment(
        "01.01.2020 08:00",
        vm.moment.defaultFormat
      );
      vm.time_picker.closing_hour = vm.moment(
        "01.01.2020 18:00",
        vm.moment.defaultFormat
      );
      vm.form = {
        id: null,
        user_id: null,
        shop_name: "",
        categories: [],
        working_days_checked_list: working_days_defaultCheckedList,
        opening_hour: "",
        closing_hour: "",
        shop_logo: "",
        cover_image: "",
        phone: "",
        email: "",
        location_name: "",
        location_code: {},
      };
    },
    onShopCoverSelected(file_name) {
      let vm = this;
      vm.form.cover_image = file_name;
    },
    onShopLogoSelected(file_name) {
      let vm = this;
      vm.form.shop_logo = file_name;
    },
    isDuplicate(tags, tag) {
      let vm = this;
      if (
        tags
          .map((t) => t.text.toLowerCase())
          .indexOf(tag.text.toLowerCase()) !== -1
      ) {
        return true;
      }
      return false;
    },
    onCategoryAdded(new_category) {
      let vm = this;
      vm.categories = new_category;
      vm.form.categories = new_category.map((t) => t.text.toLowerCase());
    },
    addShop() {
      let vm = this;
      const rules = {
        user_id: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Please select user";
          },
        },
        shop_name: {
          run: (value) => {
            if (value.length >= 3 && value.length <= 20) {
              return false;
            }
            return "Shop name must have 3 - 20 charcters";
          },
        },
        categories: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "You have to add at least one category";
          },
        },
        working_days_checked_list: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "You have to add at least one shop working day";
          },
        },
        opening_hour: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop opening hour";
          },
        },
        closing_hour: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop closing hour";
          },
        },
        phone: {
          run: (value) => {
            if (value.length == 0) {
              return "Phone number can not be empty";
            } else {
              if (/^\d+$/.test(value)) {
                if (value.match(/\d/g).length === 10) {
                  return false;
                } else {
                  return "Phone number must be 10 digits";
                }
              } else {
                return "We don't accept any character on phone number, use only number";
              }
            }
          },
        },
        email: {
          run: (value) => {
            if (value.length == 0) {
              return "Shop email can not be empty";
            } else {
              const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(String(value).toLowerCase())) {
                return false;
              }
            }
            return "Shop email is invalid";
          },
        },
        location_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop location";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.form, rules)) {
        vm.is_loading = true;
        if (!vm.form.id) {
          // Add new shop
          vm.$store
            .dispatch("SHOP_ADD_NEW", vm.form)
            .then((response) => {
              vm.is_loading = false;
              if (response.data.status == "ok") {
                vm.$localStorage.remove("shop_data");
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
                vm.$emit("onSubmit", null);
              } else {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.Message,
                });
              }
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              console.error("SHOP_ADD_NEW", { response });
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
            });
        } else {
          // Update shop
          vm.$store
            .dispatch("SHOP_UPDATE", vm.form)
            .then((response) => {
              vm.is_loading = false;
              if (response.data.status == "ok") {
                vm.$localStorage.remove("shop_data");
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
                vm.$emit("onSubmit", null);
              } else {
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.Message,
                });
              }
            })
            .catch(({ response }) => {
              vm.is_loading = false;
              console.error("SHOP_UPDATE", { response });
              vm.$notify({
                group: "status",
                type: "warn",
                title: "OOPS!!!",
                text: response.data.message,
              });
            });
        }
      }
    },
    getLocation(data) {
      let vm = this;
      vm.form.location_name = data.name;
      vm.form.location_code = data.position;
    },
    closeModal() {
      this.$emit("onClose", null);
    },
    nextTab(num) {
      let vm = this;
      const rules = {
        user_id: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Please select user";
          },
        },
        shop_name: {
          run: (value) => {
            if (value.length >= 3 && value.length <= 20) {
              return false;
            }
            return "Shop name must have 3 - 20 charcters";
          },
        },
        categories: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "You have to add at least one category";
          },
        },
        working_days_checked_list: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "You have to add at least one shop working day";
          },
        },
        opening_hour: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop opening hour";
          },
        },
        closing_hour: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop closing hour";
          },
        },
        phone: {
          run: (value) => {
            if (value.length == 0) {
              return "Phone number can not be empty";
            } else {
              if (/^\d+$/.test(value)) {
                if (value.match(/\d/g).length === 10) {
                  return false;
                } else {
                  return "Phone number must be 10 digits";
                }
              } else {
                return "We don't accept any character on phone number, use only number";
              }
            }
          },
        },
        email: {
          run: (value) => {
            if (value.length == 0) {
              return "Shop email can not be empty";
            } else {
              const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (re.test(String(value).toLowerCase())) {
                return false;
              }
            }
            return "Shop email is invalid";
          },
        },
        location_name: {
          run: (value) => {
            if (value.length > 0) {
              return false;
            }
            return "Please select shop location";
          },
        },
      };
      if (num == 0) {
        if (!vm.$isFieldsValidated(vm.form, rules, 6)) {
          return;
        }
      }

      if (num < vm.tabs.length) {
        vm.tabs[num].tab.active = false;
        vm.tabs[num].tab.finish = true;
        if (vm.tabs[num + 1]) {
          vm.tabs[num + 1].tab.active = true;
          vm.tabs[num + 1].tab.finish = true;
          vm.tab_title = vm.tabs[num + 1].tab.title;
          vm.tab_desc = vm.tabs[num + 1].tab.desc;
          vm.tab_current_index += 1;
        }
      }
    },
    prevTab(num) {
      let vm = this;
      if (num > -1) {
        vm.tabs[num].tab.active = false;
        vm.tabs[num].tab.finish = false;
        if (vm.tabs[num - 1]) {
          vm.tabs[num - 1].tab.active = true;
          vm.tabs[num - 1].tab.finish = true;
          vm.tab_title = vm.tabs[num - 1].tab.title;
          vm.tab_desc = vm.tabs[num - 1].tab.desc;
          vm.tab_current_index -= 1;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  z-index: 999999999999999;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(#fff, 0.9);
  .modal-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 14px;
    .modal-widget {
      width: 650px;
      background: #fff;
      height: 700px;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 500px) {
        width: 100%;
        position: fixed;
        z-index: 999999999999999;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        height: auto;
      }
      .modal-header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 160px;
        padding-top: 64px;
        padding-right: 64px;
        padding-left: 64px;
        padding-bottom: 40px;
        @media screen and (max-width: 500px) {
          padding-right: 14px;
          padding-left: 14px;
          height: 135px;
          align-items: flex-start;
          padding-top: 18px;
          padding-bottom: 18px;
        }
        .modal-title {
          font-size: 24px;
          font-weight: 600;
          @media screen and (max-width: 500px) {
            font-size: 18px;
          }
        }
        .modal-tab-header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0px;
          @media screen and (max-width: 500px) {
            padding: 6px 0px;
          }
          .modal-tab-title {
            font-size: 16px;
          }
          .modal-tab-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .button-item {
              width: 24px;
              height: 4px;
              display: block;
              background: #f8efef;
              margin-left: 9px;
              transition: 500ms ease-in-out;
              &.active {
                background: #8dc73f;
                transition: 500ms ease-in-out;
              }
            }
          }
        }
      }
      .modal-body {
        height: calc(100% - 220px);
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 500px) {
          height: calc(100% - 205px);
        }
        .modal-content {
          padding-top: 10px;
          padding-right: 64px;
          padding-left: 64px;
          @media screen and (max-width: 500px) {
            padding-right: 14px;
            padding-left: 14px;
          }
          .input-group {
            .input-widget {
              .properties-table {
                width: 100%;
                .property-row {
                  display: grid;
                  grid-column-gap: 3px;
                  grid-template-columns: 50% 50%;
                  margin-bottom: 12px;
                  position: relative;
                  .remove-property-btn {
                    position: absolute;
                    top: -10px;
                    right: -15px;
                    z-index: 999;
                    height: 36px;
                    width: 32px;
                    background: transparent;
                    border: none;
                  }
                  .property-label {
                    input {
                      height: 50px;
                      width: 100%;
                      padding: 0px 15px;
                      background: #eeeeee;
                      border: none;
                      border-radius: 0px;
                      outline: none;
                      font-size: 16px;
                      font-weight: 500;
                    }
                  }
                  .property-input {
                  }
                }
                .properties-table-option {
                  .btn-add-property {
                    font-size: 16.6667px;
                    line-height: 20px;
                    color: #8dc73f;
                    background: #fff;
                    border: none;
                    margin-top: 11px;
                  }
                }
              }
            }
          }
          .select-all-input {
            padding-bottom: 10px;
            border-bottom: 3px solid #ddd;
            background: #ddd;
            display: flex;
            align-items: center;
            padding: 10px 10px;
            input {
              margin-right: 10px;
            }
          }
          .show-list {
            .shop-item {
              display: flex;
              align-items: center;
              border: 1px solid #ddd;
              margin: 10px 0px;
              padding: 10px 9px;
              input {
                margin-right: 10px;
              }
              label {
                font-size: 20px;
              }
            }
          }
        }
      }
      .modal-footer {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        padding-left: 20px;
        height: 60px;
        align-items: center;
        border-top: 1px solid #ddd;
        button {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>