<template>
  <div class="cagura-wrapper">
    <div class="movie-container">
      <div class="video-modal">
        <video controls id="cagura-movie-video"></video>
        <button @click="closePlayer()" class="btn-success">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js/dist/hls.min.js";
const SECONDS_PER_DAY = 86400;
const HOURS_PER_DAY = 24;
export default {
  name: "movie-view",
  components: {},
  props: {
    movie: {
      type: String,
      default: "",
    },
    streaming: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player_streaming_type: "hls",
      src: "",
      hls: null,
      dash: null,
      video_el: null,
      played_progress: "0%",
      buffered_progress: "0%",
      played_progress_value: 0,
      video_time_left: "00:00:00",
      is_playing: false,
      show_volume: false,
      show_video_progress_bar: true,
      volume_bar_width: "0px",
      video_volume_lever: 50,
      show_video_modal: false,
      current_speed: 1,
      fullscreen: false,
      show_controls_topnav: false,
      mouse_active_timeout: null,
      current_time: 0,
      buffered_time: 0,
      is_buffering: false,
      buffering_timeout: null,
      player_switch: true,
      is_ended: false,
      movie_item_data: null,
      is_loading_data: true,
      movie_episodes: [],
      ready_to_start: false,
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    if (vm.movie) {
      let url =
        "https://file-cagura.b-cdn.net/movies/" + vm.movie + "/master.m3u8";
      vm.src = url;
      vm.initPlayer();
    }
    if (vm.streaming) {
      vm.src = vm.streaming;
      vm.initPlayer();
    }
  },
  destroyed() {
    let vm = this;
    vm.pauseVideo();
    if (vm.hls) {
      vm.hls.destroy();
    }
  },
  methods: {
    closePlayer() {
      let vm = this;
      vm.show_video_modal = false;
      if (vm.hls) {
        vm.hls.destroy();
      }
      vm.$emit("onClose", null);
    },
    initPlayer() {
      let vm = this;
      vm.show_video_modal = true;
      setTimeout(() => {
        vm.hls = new Hls(vm.htlConfig());
        vm.getStarted(vm.src);
      }, 800);
    },

    secondsToHms(seconds) {
      const days = Math.floor(seconds / SECONDS_PER_DAY);
      const remainderSeconds = seconds % SECONDS_PER_DAY;
      const hms = new Date(remainderSeconds * 1000)
        .toISOString()
        .substring(11, 19);
      return hms.replace(/^(\d+)/, (h) =>
        `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, "0")
      );
    },
    getStarted(src) {
      let vm = this;
      vm.player_switch = true;
      vm.video_el = document.getElementById("cagura-movie-video");
      vm.video_el.addEventListener("progress", (ev) => {
        var duration = ev.target.duration;
        var buffered = ev.target.buffered.end(ev.target.buffered.length - 1);
        vm.buffered_time = buffered;
        if (duration > 0) {
          vm.buffered_progress = (buffered / duration) * 100 + "%";
          if (vm.buffered_time <= vm.current_time + 1) {
            vm.is_buffering = true;
          } else {
            vm.is_buffering = false;
          }
          if (vm.buffered_time >= 3) {
            vm.ready_to_start = true;
          }
        }
      });
      setInterval(() => {
        vm.is_playing = !vm.video_el.paused;
      }, 20);
      vm.video_el.addEventListener("timeupdate", (ev) => {
        var duration = vm.video_el.duration;
        if (duration > 0) {
          vm.played_progress_value = (vm.video_el.currentTime / duration) * 100;
          vm.played_progress = "calc(" + vm.played_progress_value + "% + 0px)";
          vm.video_time_left = vm.secondsToHms(
            vm.video_el.duration - vm.video_el.currentTime
          );
          vm.current_time = vm.video_el.currentTime;
          if (vm.buffered_time <= vm.current_time + 1) {
            vm.is_buffering = true;
          } else {
            vm.is_buffering = false;
          }
        }
      });

      vm.video_el.addEventListener(
        "ended",
        (e) => {
          vm.is_ended = true;
          vm.is_playing = false;
          vm.is_buffering = false;
          vm.pauseVideo();
          vm.video_el.currentTime = 0;
        },
        false
      );

      if (Hls.isSupported()) {
        vm.hls.loadSource(src);
        vm.hls.attachMedia(vm.video_el);
        vm.hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
          vm.video_el.play();
        });
        vm.hls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                vm.hls.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                vm.hls.recoverMediaError();
                break;
              default:
                vm.hls.destroy();
                break;
            }
          }
        });
      }
    },
    htlConfig() {
      let vm = this;
      return {
        autoStartLoad: true,
        startPosition: -1,
        debug: false,
        capLevelOnFPSDrop: false,
        capLevelToPlayerSize: false,
        defaultAudioCodec: undefined,
        initialLiveManifestSize: 1,
        maxBufferLength: 100,
        maxMaxBufferLength: 1000,
        backBufferLength: Infinity,
        maxBufferSize: 120 * 1000 * 1000,
        maxBufferHole: 0.5,
        highBufferWatchdogPeriod: 2,
        nudgeOffset: 0.1,
        nudgeMaxRetry: 3,
        maxFragLookUpTolerance: 0.25,
        liveSyncDurationCount: 3,
        liveMaxLatencyDurationCount: Infinity,
        liveDurationInfinity: false,
        enableWorker: true,
        enableSoftwareAES: true,
        manifestLoadingTimeOut: 10000,
        manifestLoadingMaxRetry: 1,
        manifestLoadingRetryDelay: 1000,
        manifestLoadingMaxRetryTimeout: 64000,
        startLevel: undefined,
        levelLoadingTimeOut: 10000,
        levelLoadingMaxRetry: 4,
        levelLoadingRetryDelay: 1000,
        levelLoadingMaxRetryTimeout: 64000,
        fragLoadingTimeOut: 20000,
        fragLoadingMaxRetry: 6,
        fragLoadingRetryDelay: 1000,
        fragLoadingMaxRetryTimeout: 64000,
        startFragPrefetch: false,
        testBandwidth: true,
        progressive: false,
        lowLatencyMode: true,
        fpsDroppedMonitoringPeriod: 5000,
        fpsDroppedMonitoringThreshold: 0.2,
        appendErrorMaxRetry: 3,
        enableWebVTT: true,
        enableIMSC1: true,
        enableCEA708Captions: true,
        stretchShortVideoTrack: false,
        maxAudioFramesDrift: 1,
        forceKeyFrameOnDiscontinuity: true,
        abrEwmaFastLive: 3.0,
        abrEwmaSlowLive: 9.0,
        abrEwmaFastVoD: 3.0,
        abrEwmaSlowVoD: 9.0,
        abrEwmaDefaultEstimate: 500000,
        abrBandWidthFactor: 0.95,
        abrBandWidthUpFactor: 0.7,
        abrMaxWithRealBitrate: false,
        maxStarvationDelay: 4,
        maxLoadingDelay: 4,
        minAutoBitrate: 0,
        emeEnabled: false,
        widevineLicenseUrl: undefined,
        drmSystemOptions: {},
        xhrSetup: (xhr, url) => {
          if (vm.movie) {
            let new_url = vm.BunnCDNSignUrl(
              url,
              "280b3630-aa66-4e8a-b02b-74c17ab0dfa0",
              240,
              null,
              false,
              null,
              "",
              ""
            );
            xhr.open("GET", new_url, true);
          }
        },
      };
    },
  },
};
</script>


<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .movie-container {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    padding-top: 60px;
    .video-modal {
      width: 700px;
      height: 493px;
      background: #fff;
      padding: 10px;
      border-radius: 5px;
      #cagura-movie-video {
        height: 400px;
        width: 100%;
        background: #000;
        object-fit: contain;
      }
    }
  }
}
</style>

