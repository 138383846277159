import {
    SHOP_ADD_NEW,
    SHOP_UPDATE,
    SHOP_DELETE,
    SHOP_SEARCH_CATEGORIES,
    SHOP_GET_ALL_CATEGORIES,
    SHOP_GET_SINGLE_SHOP,
    SHOP_SEND_MESSAGE,
    SHOP_GET_ALL,
    SHOP_GET_ALL_PRODUCT,
    SHOP_GET_ALL_PRODUCT_CATEGORIES,
    SHOP_POPULAR_DELETE,
    SHOP_POPULAR_GET_ALL,
    SHOP_POPULAR_ADD_NEW,
    SHOP_POPULAR_UPDATE
} from "../actions/shop";

import {
    API_BASE_URL,
    SHOP_ADD_NEW_URL,
    SHOP_UPDATE_URL,
    SHOP_DELETE_URL,
    SHOP_SEARCH_CATEGORIES_URL,
    SHOP_GET_ALL_CATEGORIES_URL,
    SHOP_GET_SINGLE_SHOP_URL,
    SHOP_SEND_MESSAGE_URL,
    SHOP_GET_ALL_URL,
    SHOP_GET_ALL_PRODUCT_URL,
    SHOP_GET_ALL_PRODUCT_CATEGORIES_URL,
    SHOP_POPULAR_DELETE_URL,
    SHOP_POPULAR_GET_ALL_URL,
    SHOP_POPULAR_ADD_NEW_URL,
    SHOP_POPULAR_UPDATE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [SHOP_POPULAR_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_POPULAR_DELETE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_POPULAR_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_POPULAR_GET_ALL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_POPULAR_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_POPULAR_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_POPULAR_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_POPULAR_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_GET_ALL_PRODUCT_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_GET_ALL_PRODUCT_CATEGORIES_URL + "/" + payload.shop_id,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_GET_ALL_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_GET_ALL_PRODUCT_URL + "/" + payload.shop_id,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_GET_ALL_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_SEND_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_SEND_MESSAGE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_GET_SINGLE_SHOP]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_GET_SINGLE_SHOP_URL + '/' + payload.id,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_GET_ALL_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_GET_ALL_CATEGORIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_SEARCH_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_SEARCH_CATEGORIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_DELETE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SHOP_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SHOP_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};