const getHostUrl = () => {
    let host = window.location.host;
    return host;
}
const isLocalHost = getHostUrl().startsWith("localhost");

const base_url = isLocalHost ? "http://127.0.0.1:8000/" : "https://api.cagura.rw/";

export const API_BASE_URL = base_url + 'admin/api/v1';
export const API_KITCHEN_BASE_URL = base_url + 'kitchen/api/v1';
export const BASE_URL = base_url;
export const GOOGLE_API_KEY = 'AIzaSyCSgy2lCrl7LrZoX5lvBDdP6ar0m6l6wb4';
export const GOOGLE_MAP_KEY = 'AIzaSyC4scenSTK8lTrQMFF1GwaB9JICHEras34';


/* ---------------------------------------------------------------
 * API LIST
 ----------------------------------------------------------------*/
// Auth api
export const ACCOUNT_LOGIN_URL = "/account/login";
export const VERIFY_TOKEN_URL = "/account/verify/token";

// Admin user
export const ADMIN_USER_DELETE_ITEM_URL = "/account/delete";
export const ADMIN_USER_GET_USERS_URL = "/account/get/all";
export const ADMIN_USER_ADD_NEW_URL = "/account/create";
export const ADMIN_USER_UPDATE_URL = "/account/update";
export const SAVE_PERMISSION_ITEM_URL = "/account/save/permission"
export const ADMIN_GET_PERMSSION_LIST_URL = "/account/get/permission/list"
export const ADMIN_CHANGE_PERMSSION_URL = "/account/change/permission"

// Mail
export const MAILLIST_ADDNEW_URL = "/maillist/add";
export const MAILLIST_UPDATE_URL = "/maillist/update";
export const MAILLIST_GET_ALL_URL = "/maillist/all";
export const MAILLIST_DELETE_URL = "/maillist/delete";
export const MAILLIST_ADDNEW_MEMBER_URL = "/maillist/member/add";
export const MAILLIST_MEMBER_DELETE_URL = "/maillist/member/delete";

// Upload api
export const UPLOAD_GET_ALL_FILES_URL = "upload/all-files";
export const UPLOAD_DELETE_FILE_URL = "upload/delete";
export const UPLOAD_SEARCH_FILE_URL = "upload/search";
export const UPLOAD_SEARCH_FILE_BY_TYPE_URL = "upload/search-by-type";
export const UPLOAD_UPDATE_FILE_URL = "upload/update-file";
export const GET_ALL_MOVIES_URL = "upload/get/movie"
export const UPLOAD_SAVE_UPLOADED_FILE_URL = "upload/save/filename"

// Shop api
export const SHOP_ADD_NEW_URL = "/shop/create";
export const SHOP_UPDATE_URL = "/shop/update";
export const SHOP_DELETE_URL = "/shop/delete";
export const SHOP_SEARCH_CATEGORIES_URL = "/shop/search/category";
export const SHOP_GET_ALL_CATEGORIES_URL = "/shop/all/category";
export const SHOP_GET_SINGLE_SHOP_URL = "/shop/item";
export const SHOP_SEND_MESSAGE_URL = "/shop/send/message";
export const SHOP_GET_ALL_URL = "/shop/all";
export const SHOP_GET_ALL_PRODUCT_URL = "/shop/item/products";
export const SHOP_GET_ALL_PRODUCT_CATEGORIES_URL = "/shop/products/categories";

// Product api
export const PRODUCT_ADD_NEW_URL = "/product/create";
export const PRODUCT_GET_MY_PRODUCT_URL = "/product/mine";
export const PRODUCT_GET_MY_CATEGORIES_URL = "/product/mine/categories";
export const PRODUCT_UPDATE_URL = "/product/update";
export const PRODUCT_DELETE_URL = "/product/delete";
export const PRODUCT_GET_ALL_CATEGORIES_URL = "/product/all/category";
export const PRODUCT_GET_ALL_PROPERTIES_VALUES_URL = "/product/all/properties/values";
export const PRODUCT_GET_SINGLE_PRODUCT_URL = "/product/item";
export const PRODUCT_ADDWISHLIST_URL = "/product/add/wishlist";
export const PRODUCT_SEND_ORDER_URL = "/product/send/order";
export const PRODUCT_SEND_REVIEW_URL = "/product/send/review";
export const PRODUCT_GET_PRODUCT_REVIEWS_URL = "/product/all/review";
export const PRODUCT_TOGGLE_LIKE_URL = "/product/toggle/like";
export const PRODUCT_TOGGLE_UNLIKE_URL = "/product/toggle/unlike";
export const PRODUCT_GET_MY_WISHLIST_URL = "/product/mine/wishlist";
export const PRODUCT_REMOVE_FROM_WISHLIST_URL = "/product/remove/wishlist";
export const PRODUCT_GET_DISCOUNT_PRODUCTS_URL = "/product/all/discount";
export const PRODUCT_GET_NEW_PRODUCTS_URL = "/product/all/new";
export const PRODUCT_GET_HISTORY_URL = "/product/mine/history";
export const PRODUCT_REMOVE_FROM_HISTORY_URL = "/product/remove/history";
export const PRODUCT_GET_SINGLE_ITEM_FOR_EDIT_URL = "/product/item/edit";
export const PRODUCT_GET_ALL_PRODUCT_URL = "/products/get/all"

// Notification api
export const NOTIFICATION_GET_ALL_URL = "/notification/mine";
export const NOTIFICATION_MAKE_IT_SEEN_URL = "/notification/make/seen";

// Other api
export const SEARCH_BASED_CATEGORY_URL = "/search/based/category";
export const GET_DASHBOARD_DATA_URL = "/dashboard/all/cagura"

// Affiliate api
export const AFFILIATE_ADD_ITEM_URL = "/affiliate/add/item";
export const AFFILIATE_UPDATE_ITEM_URL = "/affiliate/update/item";
export const AFFILIATE_GET_ITEMS_URL = "/affiliate/get/item";
export const AFFILIATE_DELETE_ITEM_URL = "/affiliate/delete/item";
export const GET_AFFILIATE_PROGRAM_USER_URL = "/affiliate/get/user/in/program"
export const AFFILIATE_PAY_WIN_URL = "/affiliate/pay/user/win";
export const AFFILIATE_SEND_MESSAGE_URL = "/affiliate/send/message";
export const AFFILIATE_VALIDATE_USER_URL = "/affiliate/validate/user";
export const AFFILIATE_CHANGE_USER_STATUS_URL = "/affiliate/change/user/status";

// USER DATA
export const GET_ALL_CAGURA_USER_URL = "/get/cagura/user"

// CONTACTS
export const CONTACTS_ADD_ITEM_URL = "/contacts/add";
export const GET_ALL_CONTACTS_URL = "/contacts/get/all";
export const CONTACTS_UPDATE_ITEM_URL = "/contacts/update";
export const DELETE_CONTACT_ITEM_URL = "/contacts/delete";
export const IMPORT_CONTACTS_URL = "/contacts/import/contacts";
export const SEND_MESSAGE_URL = "/contacts/send/message";

// CUSTOMER
export const DELETE_CUSTOMER_URL = "/customer/delete";
export const GET_ALL_CUSTOMER_URL = "/customer/get/all";
export const CUSTOMER_ADD_ITEM_URL = "/customer/add/new";
export const CUSTOMER_UPDATE_ITEM_URL = "/customer/update";

export const CUSTOMER_SEND_MESSAGE_URL = "/customer/contacts/send/message";
export const CUSTOMER_IMPORT_CONTACTS_URL = "/customer/contacts/import/contacts";
export const CUSTOMER_DELETE_CONTACT_ITEM_URL = "/customer/contacts/delete";
export const CUSTOMER_GET_ALL_CONTACTS_URL = "/customer/contacts/get/all";
export const CUSTOMER_CONTACTS_ADD_ITEM_URL = "/customer/contacts/add";
export const CUSTOMER_CONTACTS_UPDATE_ITEM_URL = "/customer/contacts/update";
export const CUSTOMER_DELETE_ALL_CONTACTS_URL = "/customer/contact/delete/all"

export const CUSTOMER_AFFILIATE_UPDATE_ITEM_URL = "/customer/affiliate/update/item";
export const CUSTOMER_AFFILIATE_ADD_ITEM_URL = "/customer/affiliate/add/item";
export const CUSTOMER_AFFILIATE_GET_ITEMS_URL = "/customer/affiliate/get/item";
export const CUSTOMER_AFFILIATE_DELETE_ITEM_URL = "/customer/affiliate/delete/item";

export const APP_NOTIFICATION_DELETE_ITEM_URL = "/app/notification/delete";
export const APP_NOTIFICATION_GET_ITEMS_URL = "/app/notification/gel/all";
export const APP_NOTIFICATION_ADD_NEW_URL = "/app/notification/add";
export const APP_NOTIFICATION_UPDATE_URL = "/app/notification/update";

export const CUSTOMER_ADS_DELETE_ITEM_URL = "/customer/kwamamaza/delete/item";
export const CUSTOMER_ADS_GET_ITEMS_URL = "/customer/kwamamaza/get/all";
export const CUSTOMER_ADS_ADD_ITEM_URL = "/customer/kwamamaza/add/item";
export const CUSTOMER_ADS_UPDATE_ITEM_URL = "/customer/kwamamaza/update/item";

export const CUSTOMER_SPONSORED_ADD_ITEM_URL = "/customer/sponsored/add/item";
export const CUSTOMER_SPONSORED_UPDATE_ITEM_URL = "/customer/sponsored/update/item";
export const CUSTOMER_SPONSORED_GET_ITEMS_URL = "/customer/sponsored";
export const CUSTOMER_SPONSORED_DELETE_ITEM_URL = "/customer/sponsored/delete/item"

export const CUSTOMER_EMAIL_ADD_TEMPLATE_URL = "/customer/email/add/template";
export const CUSTOMER_EMAIL_GET_TEMPLATE_URL = "/customer/email/get/template";
export const CUSTOMER_EMAIL_UPDATE_TEMPLATE_URL = "/customer/email/update/template";
export const CUSTOMER_EMAIL_DELETE_TEMPLATE_URL = "/customer/email/delete/template";
export const CUSTOMER_EMAIL_MAKE_PREVIEW_URL = "/customer/email/make/preview"

// CATCHYZ
export const CATCHYZ_API_BASE_URL = "https://catchyz.com/catchyz_bkend_services/api";

export const HACK_GET_CATEGORIES_URL = "/listCategories?reqId=8698bb33f1c18a200.118.52.20&reqTimestamp=2015-01-14+01:47:21&deviceType=Web&languageCode=en&countryId=1&browserType=Chrome&user_ip=200.118.52.20";
export const HACK_SAVE_CATEGORIES_URL = "/hack/save/categories";
export const HACK_GET_PRODUCTS_URL = "/ListPosts"
export const HACK_SAVE_PRODUCTS_DATA_URL = "/save/products/data";
export const HACK_MANIPULATE_DATA_URL = "/manipulate/products";
export const HACK_GET_SHOP_DATA_URL = "/listShops?reqId=4ae6a74d04bf80200.118.52.20&reqTimestamp=2015-01-14+01:47:21&deviceType=Web&languageCode=en&countryId=1&browserType=Chrome&pageNumber=1&pageSize=600&user_ip=200.118.52.20";
export const HACK_SAVE_SHOP_DATA_URL = "/save/shop/data"

// Movies
export const MOVIE_ADD_ITEM_URL = "/movie/add"
export const MOVIE_UPDATE_ITEM_URL = "/movie/update"
export const GET_ALL_MOVIES_DATA_URL = "/movies/get/all"
export const DELETE_MOVIE_URL = "/movies/delete"

export const SEASONS_ADD_ITEM_URL = "/movies/seasons/add"
export const SEASONS_UPDATE_ITEM_URL = "/movies/seasons/update"
export const GET_MOVIES_SEASONS_URL = "/movies/seasons/get/all"
export const DELETE_MOVIES_SEASON_ITEM_URL = "/movies/seasons/delete"

export const MOVIE_EPISODE_ADD_ITEM_URL = "/movies/seasons/episodes/add"
export const MOVIE_EPISODE_UPDATE_ITEM_URL = "/movies/seasons/episodes/update";
export const GET_ALL_MOVIES_EPISODE_DATA_URL = "/movies/seasons/episodes/get/all";
export const DELETE_MOVIE_EPISODE_URL = "/movies/seasons/episodes/delete";
export const MOVIES_MOVER_TO_CDN_SERVER_URL = "/movies/move/server/cdn";
export const MOVIES_STOP_PROCESS_ON_SERVER_URL = "/movies/stop/server/process";
export const MOVIES_DELETE_FILES_URL = "/movies/file/delete";
export const MOVIES_SAVE_UPLOADED_MOVIE_INFO_URL = "/movies/save/uploaded/info";
export const MOVIES_DELETE_CDN_FILE_URL = "/movies/cdnfile/delete";
export const MOVIES_DELETE_DB_INFO_URL = "/movies/dbinfo/delete";

export const MOVIES_ADD_CATEGORY_URL = "/movies/category/add";
export const MOVIES_UPDATE_CATEGORY_URL = "/movies/category/update";
export const MOVIES_GET_ALL_CATEGORIES_URL = "/movies/categoies/all";
export const MOVIES_DELETE_CATEGORY_URL = "/movies/category/delete";
export const MOVIES_GET_ALL_NON_CATEGORIZED_URL = "/movies/all/non/categories";
export const MOVIES_ADD_CATEGORIZED_MOVIE_URL = "/movies/categorized/add";
export const MOVIES_REMOVE_CATEGORIZED_MOVIE_URL = "/movies/categorized/remove";
export const MOVIES_PROCESS_WITHDRAW_URL = "/movies/process/withdraw";

// Streaming
export const STREAMING_ADD_ITEM_URL = "/streaming/add";
export const STREAMING_UPDATE_ITEM_URL = "/streaming/update";
export const STREAMING_GET_ALL_URL = "/streaming/get/all";
export const STREAMING_DELETE_ITEM_URL = "/streaming/delete";
export const STREAMING_PROCESS_STREAMING_URL = "/streaming/process/stream";
export const STREAMING_STOP_PROCESS_STREAMING_URL = "/streaming/stop/process/stream";
export const STREAMING_DELETE_PROCESSED_FILES_URL = "/streaming/delete/processed/files";

export const CATEGORIZED_PRODUCT_DELETE_ITEM_URL = "/products/categorized/delete/category";
export const CATEGORIZED_PRODUCT_GET_ITEMS_URL = "/products/categorized/all/category";
export const CATEGORIZED_PRODUCT_ADD_ITEM_URL = "/products/categorized/add/category";
export const CATEGORIZED_PRODUCT_UPDATE_ITEM_URL = "/products/categorized/update/category";
export const CATEGORIZED_PRODUCT_ADD_PRODUCT_URL = "/products/categorized/add/products";
export const CATEGORIZED_PRODUCT_UPDATE_PRODUCT_URL = "/products/categorized/update/products";
export const CATEGORIZED_PRODUCT_REMOVE_PRODUCT_URL = "/products/categorized/remove/products";

// Story
export const STORY_ADD_NEW_URL = "/story/add";
export const STORY_UPDATE_URL = "/story/update"
export const STORY_GET_ALL_URL = "/stories";
export const STORY_DELETE_URL = "/story/delete";

// Popular shop
export const SHOP_POPULAR_DELETE_URL = "/shop/popular/delete";
export const SHOP_POPULAR_GET_ALL_URL = "/shop/popular/all";
export const SHOP_POPULAR_ADD_NEW_URL = "/shop/popular/add";
export const SHOP_POPULAR_UPDATE_URL = "/shop/popular/update";

// POPULAR product
export const PRODUCT_POPULAR_DELETE_URL = "/product/popular/delete";
export const PRODUCT_POPULAR_GET_ALL_URL = "/product/popular/all";
export const PRODUCT_POPULAR_ADD_NEW_URL = "/product/popular/add";
export const PRODUCT_POPULAR_UPDATE_URL = "/product/popular/update";

// Report
export const REPORT_GET_REPORTED_MOVIES_URL = "/report/all/reported/movies";
export const REPORT_DELETE_REPORTED_ITEM_URL = "/report/delete/reported/item";
export const REPORT_DELETE_REPORTED_POST_URL = "/report/delete/reported/post";
export const REPORT_DELETE_POST_URL = "/report/delete/post";
export const REPORT_GET_BAD_POST_URL = "/report/filter/bad/post";
export const REPORT_PUT_ONLINE_POST_URL = "/report/put/back/post";