<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <h3>ALL REPORTED POSTS</h3>
      <div class="call-actions"></div>
    </div>
    <div class="body-widget">
      <div class="table-widget">
        <table>
          <tr>
            <th>Post</th>
            <th width="200">
              <c-button :loading="is_loading" @click="getBadPost()" class="btn-default">
                GET BAD POSTS
              </c-button>
            </th>
          </tr>
          <tr v-for="(item, index) in section_data" :key="index" :class="{ bad: item.details == 'bad_word' }">
            <td>
              <div v-html="item.text_content.slice(0, 300)"></div>
            </td>

            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="false"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" @click.prevent="putOnlineItem(item)">
                    <span class="fa fa-rocket"></span> Put Online Post
                  </a>
                  <a class="dropdown-item" @click.prevent="viewItem(item)">
                    <span class="fa fa-eye"></span> View
                  </a>
                  <a class="dropdown-item" @click.prevent="deleteItem(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete Reported
                  </a>
                  <a class="dropdown-item" @click.prevent="deletePost(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete Post
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- View item -->
    <!-- View movie -->
    <div class="overlay" v-if="show_data_view">
      <div class="item-view-modal">
        <div class="header">
          <h1>POST</h1>
          <button class="btn-close" @click="show_data_view = false">
            &times;
          </button>
        </div>
        <div class="body">
          <div class="row-view">
            <h3>Post content</h3>
            <p v-html="section_item.content"></p>
          </div>

          <div class="row-view" v-if="section_item.file_type == 'image'">
            <h3>Post images</h3>
            <feedImage :imgs="section_item.images"></feedImage>
          </div>

          <div class="report-details">
            <div v-if="section_item.details" class="row-view">
              <h3>Details</h3>
              <p>{{ section_item.details }}</p>
            </div>
            <div class="row-view">
              <h3>Issue Details</h3>
              <div v-for="(item, index) in section_item.issues" :key="index">
                <label>
                  {{ item.label }}
                </label>
                <p>
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    show_data_view: false,
    section_data: [],
    section_item: null,
    show_player: false,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getSectionData();
  },
  methods: {
    putOnlineItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("REPORT_PUT_ONLINE_POST", { id: item.id })
              .then((response) => {
                vm.getSectionData();
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              })
              .catch(({ response }) => {
                console.error("REPORT_PUT_ONLINE_POST", { response });
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.message,
                });
              });
          }
        },
      });
    },
    getBadPost() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("REPORT_GET_BAD_POST")
        .then((response) => {
          vm.is_loading = false;
          vm.getSectionData();
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("REPORT_GET_BAD_POST", { response });
        });
    },
    closePlayer() {
      let vm = this;
      vm.show_player = false;
    },
    viewItem(item) {
      let vm = this;
      vm.section_item = item;
      vm.$store.state.eventBus.$set(
        vm.section_item,
        "images",
        JSON.parse(item.files)
      );
      vm.$store.state.eventBus.$set(
        vm.section_item,
        "issues",
        JSON.parse(item.labels)
      );
      console.log(vm.section_item);
      vm.show_data_view = true;
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("REPORT_DELETE_REPORTED_POST", { id: id })
        .then((response) => {
          vm.getSectionData();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("REPORT_DELETE_REPORTED_POST", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.reported_id);
          }
        },
      });
    },
    deletePost(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.$store
              .dispatch("REPORT_DELETE_POST", { id: item.id })
              .then((response) => {
                vm.getSectionData();
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              })
              .catch(({ response }) => {
                console.error("REPORT_DELETE_POST", { response });
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.message,
                });
              });
          }
        },
      });
    },
    getSectionData() {
      let vm = this;
      vm.$store
        .dispatch("REPORT_GET_REPORTED_MOVIES", { type: "post" })
        .then((response) => {
          vm.section_data = response.data.return;
        })
        .catch((error) => {
          console.log("REPORT_GET_REPORTED_MOVIES", { error });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        tr {
          &.bad {
            background: rgb(247, 145, 201);
          }
        }

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }

    .item-view-modal {
      width: 400px;
      height: 600px;
      border: 1px solid #ddd;
      border-radius: 5px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        height: 60px;
        padding: 0px 15px;

        h1 {
          font-size: 18px;
          margin: 0px;
        }

        .btn-close {
          font-size: 25px;
          background: transparent;
          border: none;
        }
      }

      .body {
        height: calc(100% - 60px);
        padding: 15px 15px;
        overflow-y: auto;

        .report-details {
          .row-view {
            label {}

            p {
              color: #000;
            }
          }
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
