import {
    DELETE_CUSTOMER,
    GET_ALL_CUSTOMER,
    CUSTOMER_ADD_ITEM,
    CUSTOMER_UPDATE_ITEM,
    CUSTOMER_SEND_MESSAGE,
    CUSTOMER_IMPORT_CONTACTS,
    CUSTOMER_DELETE_CONTACT_ITEM,
    CUSTOMER_GET_ALL_CONTACTS,
    CUSTOMER_CONTACTS_ADD_ITEM,
    CUSTOMER_CONTACTS_UPDATE_ITEM,
    CUSTOMER_AFFILIATE_UPDATE_ITEM,
    CUSTOMER_AFFILIATE_ADD_ITEM,
    CUSTOMER_AFFILIATE_GET_ITEMS,
    CUSTOMER_AFFILIATE_DELETE_ITEM,
    CUSTOMER_ADS_DELETE_ITEM,
    CUSTOMER_ADS_GET_ITEMS,
    CUSTOMER_ADS_ADD_ITEM,
    CUSTOMER_ADS_UPDATE_ITEM,
    CUSTOMER_SPONSORED_ADD_ITEM,
    CUSTOMER_SPONSORED_UPDATE_ITEM,
    CUSTOMER_SPONSORED_GET_ITEMS,
    CUSTOMER_SPONSORED_DELETE_ITEM,
    CUSTOMER_DELETE_ALL_CONTACTS,
    CUSTOMER_EMAIL_ADD_TEMPLATE,
    CUSTOMER_EMAIL_GET_TEMPLATE,
    CUSTOMER_EMAIL_UPDATE_TEMPLATE,
    CUSTOMER_EMAIL_DELETE_TEMPLATE,
    CUSTOMER_EMAIL_MAKE_PREVIEW,
    MAILLIST_ADDNEW,
    MAILLIST_UPDATE,
    MAILLIST_GET_ALL,
    MAILLIST_DELETE,
    MAILLIST_ADDNEW_MEMBER,
    MAILLIST_MEMBER_DELETE
} from "../actions/deals";

import {
    API_BASE_URL,
    DELETE_CUSTOMER_URL,
    GET_ALL_CUSTOMER_URL,
    CUSTOMER_ADD_ITEM_URL,
    CUSTOMER_UPDATE_ITEM_URL,
    CUSTOMER_SEND_MESSAGE_URL,
    CUSTOMER_IMPORT_CONTACTS_URL,
    CUSTOMER_DELETE_CONTACT_ITEM_URL,
    CUSTOMER_GET_ALL_CONTACTS_URL,
    CUSTOMER_CONTACTS_ADD_ITEM_URL,
    CUSTOMER_CONTACTS_UPDATE_ITEM_URL,
    CUSTOMER_AFFILIATE_UPDATE_ITEM_URL,
    CUSTOMER_AFFILIATE_ADD_ITEM_URL,
    CUSTOMER_AFFILIATE_GET_ITEMS_URL,
    CUSTOMER_AFFILIATE_DELETE_ITEM_URL,
    CUSTOMER_ADS_DELETE_ITEM_URL,
    CUSTOMER_ADS_GET_ITEMS_URL,
    CUSTOMER_ADS_ADD_ITEM_URL,
    CUSTOMER_ADS_UPDATE_ITEM_URL,
    CUSTOMER_SPONSORED_ADD_ITEM_URL,
    CUSTOMER_SPONSORED_UPDATE_ITEM_URL,
    CUSTOMER_SPONSORED_GET_ITEMS_URL,
    CUSTOMER_SPONSORED_DELETE_ITEM_URL,
    CUSTOMER_DELETE_ALL_CONTACTS_URL,
    CUSTOMER_EMAIL_ADD_TEMPLATE_URL,
    CUSTOMER_EMAIL_GET_TEMPLATE_URL,
    CUSTOMER_EMAIL_UPDATE_TEMPLATE_URL,
    CUSTOMER_EMAIL_DELETE_TEMPLATE_URL,
    CUSTOMER_EMAIL_MAKE_PREVIEW_URL,
    MAILLIST_ADDNEW_URL,
    MAILLIST_UPDATE_URL,
    MAILLIST_GET_ALL_URL,
    MAILLIST_DELETE_URL,
    MAILLIST_ADDNEW_MEMBER_URL,
    MAILLIST_MEMBER_DELETE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [MAILLIST_MEMBER_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_MEMBER_DELETE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MAILLIST_ADDNEW_MEMBER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_ADDNEW_MEMBER_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MAILLIST_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_DELETE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MAILLIST_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_GET_ALL_URL,
            method: "GET",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MAILLIST_ADDNEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_ADDNEW_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [MAILLIST_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + MAILLIST_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_EMAIL_MAKE_PREVIEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_EMAIL_MAKE_PREVIEW_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_EMAIL_DELETE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_EMAIL_DELETE_TEMPLATE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_EMAIL_UPDATE_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_EMAIL_UPDATE_TEMPLATE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_EMAIL_GET_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_EMAIL_GET_TEMPLATE_URL,
            method: "GET",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_EMAIL_ADD_TEMPLATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_EMAIL_ADD_TEMPLATE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_DELETE_ALL_CONTACTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_DELETE_ALL_CONTACTS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_SPONSORED_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_SPONSORED_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_SPONSORED_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_SPONSORED_GET_ITEMS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_SPONSORED_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_SPONSORED_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_SPONSORED_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_SPONSORED_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_ADS_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_ADS_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_ADS_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_ADS_GET_ITEMS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_ADS_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_ADS_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_ADS_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_ADS_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_AFFILIATE_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_AFFILIATE_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_AFFILIATE_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_AFFILIATE_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_AFFILIATE_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_AFFILIATE_GET_ITEMS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_AFFILIATE_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_AFFILIATE_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_SEND_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_SEND_MESSAGE_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_IMPORT_CONTACTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_IMPORT_CONTACTS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_DELETE_CONTACT_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_DELETE_CONTACT_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_GET_ALL_CONTACTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_GET_ALL_CONTACTS_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_CONTACTS_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_CONTACTS_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_CONTACTS_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_CONTACTS_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [CUSTOMER_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CUSTOMER_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [GET_ALL_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_CUSTOMER_URL,
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + getters.getUserAccessToken,
            }
        });
    },
    [DELETE_CUSTOMER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_CUSTOMER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};