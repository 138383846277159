export const PRODUCT_ADD_NEW = "PRODUCT_ADD_NEW";
export const PRODUCT_GET_MY_PRODUCT = "PRODUCT_GET_MY_PRODUCT";
export const PRODUCT_GET_MY_CATEGORIES = "PRODUCT_GET_MY_CATEGORIES";
export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_GET_ALL_CATEGORIES = "PRODUCT_GET_ALL_CATEGORIES";
export const PRODUCT_GET_ALL_PROPERTIES_VALUES = "PRODUCT_GET_ALL_PROPERTIES_VALUES";
export const PRODUCT_GET_SINGLE_PRODUCT = "PRODUCT_GET_SINGLE_PRODUCT";
export const PRODUCT_ADDWISHLIST = "PRODUCT_ADDWISHLIST";
export const PRODUCT_SEND_ORDER = "PRODUCT_SEND_ORDER";
export const PRODUCT_SEND_REVIEW = "PRODUCT_SEND_REVIEW";
export const PRODUCT_GET_PRODUCT_REVIEWS = "PRODUCT_GET_PRODUCT_REVIEWS";
export const PRODUCT_TOGGLE_LIKE = "PRODUCT_TOGGLE_LIKE";
export const PRODUCT_TOGGLE_UNLIKE = "PRODUCT_TOGGLE_UNLIKE";
export const PRODUCT_GET_MY_WISHLIST = "PRODUCT_GET_MY_WISHLIST";
export const PRODUCT_REMOVE_FROM_WISHLIST = "PRODUCT_REMOVE_FROM_WISHLIST";
export const PRODUCT_GET_DISCOUNT_PRODUCTS = "PRODUCT_GET_DISCOUNT_PRODUCTS";
export const PRODUCT_GET_NEW_PRODUCTS = "PRODUCT_GET_NEW_PRODUCTS";
export const PRODUCT_GET_HISTORY = "PRODUCT_GET_HISTORY";
export const PRODUCT_REMOVE_FROM_HISTORY = "PRODUCT_REMOVE_FROM_HISTORY";
export const PRODUCT_GET_SINGLE_ITEM_FOR_EDIT = "PRODUCT_GET_SINGLE_ITEM_FOR_EDIT";
export const PRODUCT_GET_ALL_PRODUCT = "PRODUCT_GET_ALL_PRODUCT";

export const CATEGORIZED_PRODUCT_DELETE_ITEM = "CATEGORIZED_PRODUCT_DELETE_ITEM";
export const CATEGORIZED_PRODUCT_GET_ITEMS = "CATEGORIZED_PRODUCT_GET_ITEMS";
export const CATEGORIZED_PRODUCT_ADD_ITEM = "CATEGORIZED_PRODUCT_ADD_ITEM";
export const CATEGORIZED_PRODUCT_UPDATE_ITEM = "CATEGORIZED_PRODUCT_UPDATE_ITEM";
export const CATEGORIZED_PRODUCT_ADD_PRODUCT = 'CATEGORIZED_PRODUCT_ADD_PRODUCT';
export const CATEGORIZED_PRODUCT_UPDATE_PRODUCT = "CATEGORIZED_PRODUCT_UPDATE_PRODUCT";
export const CATEGORIZED_PRODUCT_REMOVE_PRODUCT = "CATEGORIZED_PRODUCT_REMOVE_PRODUCT";

export const PRODUCT_POPULAR_DELETE = "PRODUCT_POPULAR_DELETE";
export const PRODUCT_POPULAR_GET_ALL = "PRODUCT_POPULAR_GET_ALL";
export const PRODUCT_POPULAR_ADD_NEW = "PRODUCT_POPULAR_ADD_NEW";
export const PRODUCT_POPULAR_UPDATE = "PRODUCT_POPULAR_UPDATE";