var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper-option"},[_c('c-button',{staticClass:"btn-success",attrs:{"loading":_vm.validate_loading},on:{"click":function($event){return _vm.validateUser()}}},[_vm._v("VALIDATE USER")])],1),_c('div',{staticClass:"table-widget"},[_c('table',[_vm._m(0),_vm._l((_vm.user_data),function(item,index){return _c('tr',{key:index,class:{ is_pause: item.is_paused }},[_c('td',[_c('div',{staticClass:"names"},[(
              item.profile_image &&
              item.account_type == 'facebook' &&
              item.account_type == 'google'
            )?_c('img',{attrs:{"src":_vm.$store.state.SITE_ASSETS_BASE_URL +
                'assets/uploaded/' +
                item.profile_image}}):(item.profile_image)?_c('img',{attrs:{"src":_vm.$store.state.SITE_ASSETS_BASE_URL +
              'assets/uploaded/' +
              item.profile_image}}):_c('img',{attrs:{"src":require("@/assets/images/icons/avatar.svg"),"alt":""}}),_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")])]),_c('td',[_vm._v(_vm._s(item.views_count))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',[_vm._v("FRW "+_vm._s(item.final_win_price))]),_c('td',[_vm._v(_vm._s(item.status))]),_c('td',[_c('dropdown-menu',{staticClass:"shop-menu",attrs:{"right":false,"hover":false,"interactive":false},model:{value:(item.is_menu_open),callback:function ($$v) {_vm.$set(item, "is_menu_open", $$v)},expression:"item.is_menu_open"}},[_c('button',{staticClass:"dropdown-toggle"},[_c('img',{attrs:{"src":require("@/assets/images/icons/menu.svg")}})]),_c('div',{staticClass:"drop-down-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.PayUser(item)}}},[_c('span',{staticClass:"fa fa-money"}),_vm._v(" Pay ")]),(!item.is_paused)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.changeUserStatus(item, 'lock')}}},[_c('span',{staticClass:"fa fa-lock"}),_vm._v(" Lock User ")]):_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.changeUserStatus(item, 'unlock')}}},[_c('span',{staticClass:"fa fa-unlock"}),_vm._v(" UnLock User ")])])])],1)])})],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Names")]),_c('th',[_vm._v("Views")]),_c('th',[_vm._v("Phone")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Status")]),_c('th')])
}]

export { render, staticRenderFns }