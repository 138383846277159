<template>
  <div class="wrapper-container">
    <div class="container-widget-header">
      <div class="views"></div>
      <div class="call-actions">
        <button v-if="view == 'contact_view'" class="btn-success" @click="addNew()">
          <img src="@/assets/images/icons/plus.svg" /> CREATE CONTACTS
        </button>
        <button v-if="view == 'contact_view'" class="btn-success" @click="importContact()">
          <img src="@/assets/images/icons/plus.svg" /> IMPORT CONTACTS
        </button>
        <button class="btn-success" @click="showSendForm()">SEND SMS</button>
        <input type="file" style="display: none" id="file" ref="file" accept=".vcf" v-on:change="handleFileUpload()" />
      </div>
    </div>
    <div class="body-widget">
      <!-- Contact -->
      <div class="table-widget" v-if="view == 'contact_view'">
        <table>
          <tr>
            <th>Phone number</th>
            <th>
              <button @click="deleteAllItem()" class="btn-default">
                Delete All
              </button>
            </th>
          </tr>
          <tr v-for="(item, index) in contacts_data" :key="index">
            <td>{{ item.phone }}</td>

            <td>
              <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                class="shop-menu">
                <button class="dropdown-toggle">
                  <img src="@/assets/images/icons/menu.svg" />
                </button>
                <div slot="dropdown" class="drop-down-menu">
                  <a class="dropdown-item" @click.prevent="editItem(item)">
                    <img src="@/assets/images/icons/edit.svg" /> Edit
                  </a>
                  <a class="dropdown-item" @click.prevent="deleteItem(item)">
                    <img src="@/assets/images/icons/trash.svg" /> Delete
                  </a>
                </div>
              </dropdown-menu>
            </td>
          </tr>
        </table>
      </div>

      <!-- Add new contact Modal -->
      <div class="overlay" v-if="show_form">
        <div class="form-widget">
          <div class="header">
            <h3 v-if="!form.id">Add Contact</h3>
            <h3 v-else>Update Contact</h3>
          </div>
          <div class="body">
            <div class="input-group" v-if="!form.id">
              <label>Add Contact Type</label>
              <div class="input-widget">
                <select type="text" v-model="form.type" class="bg-cagura-form-control">
                  <option value="phone">Phone</option>
                  <option value="range">Range</option>
                </select>
              </div>
            </div>
            <div class="input-group" v-if="form.type == 'phone'">
              <label>Phone number</label>
              <div class="input-widget">
                <input type="text" v-model="form.phone_number" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group" v-else>
              <label>Select contacts range to copy from our system</label>
              <div class="input-widget">
                <input type="text" v-model="form.start" class="bg-cagura-form-control" placeholder="Start" />
              </div>
              <div class="input-widget">
                <input type="text" v-model="form.end" placeholder="End" class="bg-cagura-form-control" />
              </div>
            </div>
          </div>
          <div class="footer">
            <c-button :loading="loading" @click="submitForm()" class="btn-success">Save</c-button>
            <button @click="show_form = false" class="btn-default">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <!-- Send sms modal -->
      <div class="overlay" v-if="show_message_form">
        <div class="form-widget" :class="{ 'form-send': message_form.type == 'email' }">
          <div class="header">
            <h3>Send Message</h3>
          </div>
          <div class="body">
            <div class="message-container">
              <div class="main_form">
                <div class="input-group">
                  <label>Messaging Type</label>
                  <div class="input-widget">
                    <select type="text" v-model="message_form.type" class="bg-cagura-form-control">
                      <option value="">Select Type</option>
                      <option value="all_phones">All Users</option>
                      <option value="customer_phones">
                        Customer s' Contacts
                      </option>
                    </select>
                  </div>
                </div>

                <div class="input-group">
                  <label>Message</label>
                  <div class="input-widget">
                    <textarea type="text" class="bg-cagura-textarea-form-control"
                      v-model="message_form.message"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <c-button :loading="send_loading" @click="sendMessage()" class="btn-success">Send</c-button>
            <button @click="show_message_form = false" class="btn-default">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    loading: false,
    form: {
      id: null,
      type: "phone",
      customer_id: null,
      phone_number: "",
      start: 1,
      end: 10,
    },
    show_form: false,
    contacts_data: [],
    file: "",
    show_message_form: false,
    message_form: {
      type: "",
      template_id: null,
      subject: "",
      maillist_id: "",
      temp_keys: [],
      product: [],
      shop: [],
      movies: [],
      has_products: false,
      has_shop: false,
      has_movie: false,
      message: "",
      customer_id: null,
    },
    send_loading: false,
    view: "contact_view",
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getContacts();
  },
  methods: {
    showSendForm() {
      let vm = this;
      vm.message_form = {
        type: "",
        template_id: null,
        subject: "",
        maillist_id: "",
        temp_keys: [],
        product: [],
        shop: [],
        movies: [],
        has_products: false,
        has_shop: false,
        has_movie: false,
        message: "",
        customer_id: vm.$route.params.id,
      };
      vm.show_message_form = true;
    },
    sendMessage() {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? Everything is it fine? Check twice, If Everything is fine then send`,
        button: {
          no: "No",
          yes: "Send",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.send_loading = true;
            vm.$store
              .dispatch("CUSTOMER_SEND_MESSAGE", vm.message_form)
              .then((response) => {
                vm.send_loading = false;
                vm.show_message_form = false;
                vm.message = "";
                vm.$notify({
                  group: "status",
                  type: "success",
                  title: "Important message",
                  text: response.data.message,
                });
              })
              .catch(({ response }) => {
                vm.send_loading = false;
                console.error("CUSTOMER_SEND_MESSAGE", { response });
                vm.$notify({
                  group: "status",
                  type: "warn",
                  title: "OOPS!!!",
                  text: response.data.message,
                });
              });
          }
        },
      });
    },
    importContact() {
      document.querySelector("#file").click();
    },
    handleFileUpload() {
      let vm = this;
      vm.file = vm.$refs.file.files[0];
      if (vm.file) {
        let formData = new FormData();
        formData.append("file", vm.file);
        formData.append("customer_id", vm.$route.params.id);
        vm.$store
          .dispatch("CUSTOMER_IMPORT_CONTACTS", formData)
          .then((response) => {
            vm.getContacts();
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            console.error("CUSTOMER_IMPORT_CONTACTS", { response });
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
          });
      }
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_DELETE_CONTACT_ITEM", { id: id })
        .then((response) => {
          vm.getContacts();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CUSTOMER_DELETE_CONTACT_ITEM", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.id);
          }
        },
      });
    },
    confirmDeleteAll(customer_id) {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_DELETE_ALL_CONTACTS", { id: customer_id })
        .then((response) => {
          vm.getContacts();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("CUSTOMER_DELETE_ALL_CONTACTS", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteAllItem() {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure? You wand to delete all contact`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDeleteAll(vm.$route.params.id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.customer_id = item.customer_id;
      vm.form.phone_number = item.phone;
      vm.show_form = true;
    },
    getContacts() {
      let vm = this;
      vm.$store
        .dispatch("CUSTOMER_GET_ALL_CONTACTS", {
          customer_id: vm.$route.params.id,
        })
        .then((response) => {
          vm.contacts_data = response.data.return.map((item) => {
            vm.$store.state.eventBus.$set(item, "is_menu_open", false);
            return item;
          });
        })
        .catch(({ response }) => {
          console.log("CUSTOMER_GET_ALL_CONTACTS", { error });
        });
    },
    submitForm() {
      let vm = this;
      vm.loading = true;
      if (!vm.form.id) {
        vm.$store
          .dispatch("CUSTOMER_CONTACTS_ADD_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getContacts();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_CONTACTS_ADD_ITEM", { error });
          });
      } else {
        vm.$store
          .dispatch("CUSTOMER_CONTACTS_UPDATE_ITEM", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getContacts();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("CUSTOMER_CONTACTS_UPDATE_ITEM", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_form = true;
      vm.form = {
        id: null,
        type: "phone",
        customer_id: null,
        phone_number: "",
        start: 1,
        end: 10,
      };
      if (vm.$route.params.id) {
        vm.form.customer_id = vm.$route.params.id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .call-actions {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .options {
            display: flex;
            align-items: center;

            button {
              margin-right: 8px;
            }
          }

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 61px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;
    z-index: 999999;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      &.form-mail-edito {
        width: 98% !important;
        height: 98% !important;
      }

      &.form-send {
        width: 98% !important;
        height: 98% !important;
      }

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }

        .options {
          position: relative;

          .keys-btn {
            .email-temp-keys-widget {
              position: absolute;
              top: 45px;
              right: 0px;
              background: #fff;
              box-shadow: 0px 0px 18px #000;
              padding: 10px;
              border-radius: 5px;
              height: 400px;
              overflow-y: auto;

              ul {
                padding-left: 0px;
                list-style: none;

                li {
                  padding: 5px 10px;
                }
              }
            }
          }
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
        display: flex;
        flex-direction: column;

        .email-item {
          display: flex;
          padding: 5px;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #ddd;
          margin-bottom: 10px;
          justify-content: space-between;
        }

        .message-container {
          height: 100%;
          display: flex;

          .form {
            width: 50%;
            padding: 0px 10px;
            overflow-y: auto;
          }

          .main_form {
            width: 100%;
          }

          .preview-email {
            width: 50%;
            overflow-y: auto;
            border: 2px solid #000;
          }
        }

        .code-preview {
          height: 100%;
          display: flex;

          .code {
            width: 50%;
          }

          .preview {
            width: 50%;
            overflow-y: auto;
            border: 2px solid #000;
          }
        }
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
