<template>
  <div class="cagura-wrapper">
    <div class="body">
      <div class="login-wrapper">
        <div class="login-section">
          <div class="logo-section">
            <img src="@/assets/images/logo.png" alt="Cagura Logo" class="cagura-logo" />
          </div>
          <div class="login-header">
            <h2>ADMIN LOGIN</h2>
          </div>
          <div class="form-widget">
            <div class="input-group">
              <div class="input-widget">
                <input type="text" class="bg-cagura-form-control" name="email" placeholder="Phone or Email"
                  v-model="form.email_phone" />
              </div>
            </div>
            <div class="input-group">
              <div class="">
                <VuePassword v-model="form.password" placeholder="Password" class="password-input"
                  :disableStrength="false" type="password" @keypress.enter="onLogin()" />
              </div>
            </div>
            <div class="form-option">
              <c-button class="login-btn" :loading="loading" @click="onLogin()">LOG IN</c-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VuePassword from "vue-password";
export default {
  name: "cagura-login",
  components: {
    VuePassword,
  },
  data() {
    return {
      loading: false,
      form: {
        email_phone: "",
        password: "",
      },
    };
  },
  methods: {
    onLogin() {
      let vm = this;
      vm.loading = true;
      vm.$store
        .dispatch("ACCOUNT_LOGIN", vm.form)
        .then((response) => {
          vm.loading = false;
          let user = {
            access_token: response.data.access_token,
            user: response.data.user,
          };
          vm.$saveLoginInfo(user);
          vm.$appRedirect();
        })
        .catch(({ response }) => {
          vm.loading = false;
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
          console.log("ACCOUNT_LOGIN", { error });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  padding: 30px 0px;
  overflow: auto;
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .cagura-nav-bar {
    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
    }

    .cagura-logo {
      width: 200px;
      margin-left: -10px;

      @media screen and (max-width: 1040px) {
        margin-left: -26px;
      }
    }
  }

  .body {
    margin-top: 30px;

    @media screen and (max-width: 1040px) {
      display: flex;
      justify-content: center;
      padding: 20px;
    }

    .login-wrapper {
      display: flex;
      justify-content: center;

      .login-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .logo-section {
          margin-bottom: 30px;

          img {
            width: 230px;
            object-fit: contain;
          }
        }

        .login-header {
          h2 {
            font-size: 20px;
            color: #8dc73f;
            margin-top: -11px;
            text-align: center;

            @media screen and (max-width: 1040px) {
              font-size: 18px;
              text-align: center;
            }
          }
        }

        .form-widget {
          border: 1px solid #ddd;
          padding: 30px 30px;
          border-radius: 4px;
          width: 350px;

          @media screen and (max-width: 1040px) {
            padding: 10px 0px;
            border: none;
          }

          .form-option {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 1040px) {
              flex-direction: column;
            }

            .login-btn {
              width: 154px;
              height: 45px;
              background: #8dc73f;
              border-radius: 5px;
              color: #fff;
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>
