<template>
  <AdminContainer>
    <div class="wrapper-container">
      <div class="container-widget-header"></div>
      <div class="body-widget">
        <h1>Welcome here again!!!</h1>
      </div>
    </div>
  </AdminContainer>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  watch: {},
  mounted() {
    let vm = this;
    vm.$store
      .dispatch("ADMIN_GET_PERMSSION_LIST")
      .then((response) => {
        vm.$localStorage.set(
          "permission_list",
          JSON.stringify(response.data.return)
        );
      })
      .catch((error) => {});
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;
  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }
  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .welcome-image {
      width: 70%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
</style>
