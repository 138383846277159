<template>
  <prism-editor
    class="my-editor"
    v-model="code"
    :highlight="highlighter"
    line-numbers
  ></prism-editor>
</template>
 
<script>
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-tomorrow.css";

export default {
  components: {
    PrismEditor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({ code: "" }),
  watch: {
    value(val) {},
  },
  beforeMount() {},
  mounted() {
    let vm = this;
    vm.code = this.value;
  },
  methods: {
    highlighter(code) {
      let vm = this;
      let html_content = highlight(code, languages.js);
      vm.$emit("input", code);
      vm.$emit("onChange", code);
      return html_content;
    },
  },
};
</script> 
 
<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;
  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  width: 100%;
  height: 100%;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style> 