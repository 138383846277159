import {
    AFFILIATE_ADD_ITEM,
    AFFILIATE_UPDATE_ITEM,
    AFFILIATE_GET_ITEMS,
    AFFILIATE_DELETE_ITEM,
    GET_AFFILIATE_PROGRAM_USER,
    AFFILIATE_PAY_WIN,
    AFFILIATE_SEND_MESSAGE,
    AFFILIATE_VALIDATE_USER,
    AFFILIATE_CHANGE_USER_STATUS
} from "../actions/affiliate";

import {
    API_BASE_URL,
    AFFILIATE_ADD_ITEM_URL,
    AFFILIATE_UPDATE_ITEM_URL,
    AFFILIATE_GET_ITEMS_URL,
    AFFILIATE_DELETE_ITEM_URL,
    GET_AFFILIATE_PROGRAM_USER_URL,
    AFFILIATE_PAY_WIN_URL,
    AFFILIATE_SEND_MESSAGE_URL,
    AFFILIATE_VALIDATE_USER_URL,
    AFFILIATE_CHANGE_USER_STATUS_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [AFFILIATE_CHANGE_USER_STATUS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_CHANGE_USER_STATUS_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_VALIDATE_USER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_VALIDATE_USER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_SEND_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_SEND_MESSAGE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_PAY_WIN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_PAY_WIN_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_AFFILIATE_PROGRAM_USER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_AFFILIATE_PROGRAM_USER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_GET_ITEMS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_DELETE_ITEM_URL,
            method: "DELETE",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [AFFILIATE_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + AFFILIATE_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};