<template>
  <div class="images-wrapper">
    <img
      :src="$store.state.ASSETS_BASE_URL + '/feeds/' + item"
      v-for="(item, index) in imgs.slice(0, 4)"
      :key="index"
      :style="{
        width: findWidth(imgs.slice(0, 4).length, index),
        height: findheight(imgs.slice(0, 4).length, index),
      }"
      @click="viewMoreImages()"
    />
    <div v-if="imgs.length > 4" @click="viewMoreImages()" class="more-images">
      +{{ imgs.length - 4 }}
    </div>
  </div>
</template>
<script>
export default {
  name: "cagura-button",
  props: {
    imgs: {
      type: Array,
      default: [],
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {
    // files: {
    //   handler: (files) => {
    //     let vm = this;
    //     vm.imgs = JSON.parse(files);
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    let vm = this;
  },
  methods: {
    findWidth(length, index) {
      if (length == 1) {
        return "100%";
      } else if (length == 2) {
        return "50%";
      } else if (length == 3) {
        return "33.333%";
      } else if (length == 4) {
        return "25%";
      }
    },
    findheight(length, index) {
      if (length == 1) {
        return "100%";
      } else if (length == 2) {
        return "200px";
      } else if (length == 3) {
        return "150px";
      } else if (length == 4) {
        return "150px";
      }
    },
    viewMoreImages() {
      let vm = this;
      vm.$emit("onViewMore", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  img {
    object-fit: cover;
    background: rgb(252, 250, 250);
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    cursor: pointer;
  }
  .more-images {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #fff;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 6px #979797;
    cursor: pointer;
  }
}
</style>
