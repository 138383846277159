import {
    PRODUCT_ADD_NEW,
    PRODUCT_GET_MY_PRODUCT,
    PRODUCT_GET_MY_CATEGORIES,
    PRODUCT_UPDATE,
    PRODUCT_DELETE,
    PRODUCT_GET_ALL_CATEGORIES,
    PRODUCT_GET_ALL_PROPERTIES_VALUES,
    PRODUCT_GET_SINGLE_PRODUCT,
    PRODUCT_ADDWISHLIST,
    PRODUCT_SEND_ORDER,
    PRODUCT_SEND_REVIEW,
    PRODUCT_GET_PRODUCT_REVIEWS,
    PRODUCT_TOGGLE_LIKE,
    PRODUCT_TOGGLE_UNLIKE,
    PRODUCT_GET_MY_WISHLIST,
    PRODUCT_REMOVE_FROM_WISHLIST,
    PRODUCT_GET_DISCOUNT_PRODUCTS,
    PRODUCT_GET_NEW_PRODUCTS,
    PRODUCT_GET_HISTORY,
    PRODUCT_REMOVE_FROM_HISTORY,
    PRODUCT_GET_SINGLE_ITEM_FOR_EDIT,
    PRODUCT_GET_ALL_PRODUCT,
    CATEGORIZED_PRODUCT_DELETE_ITEM,
    CATEGORIZED_PRODUCT_GET_ITEMS,
    CATEGORIZED_PRODUCT_ADD_ITEM,
    CATEGORIZED_PRODUCT_UPDATE_ITEM,
    CATEGORIZED_PRODUCT_ADD_PRODUCT,
    CATEGORIZED_PRODUCT_UPDATE_PRODUCT,
    CATEGORIZED_PRODUCT_REMOVE_PRODUCT,
    PRODUCT_POPULAR_DELETE,
    PRODUCT_POPULAR_GET_ALL,
    PRODUCT_POPULAR_ADD_NEW,
    PRODUCT_POPULAR_UPDATE
} from "../actions/product";

import {
    API_BASE_URL,
    PRODUCT_ADD_NEW_URL,
    PRODUCT_GET_MY_PRODUCT_URL,
    PRODUCT_GET_MY_CATEGORIES_URL,
    PRODUCT_UPDATE_URL,
    PRODUCT_DELETE_URL,
    PRODUCT_GET_ALL_CATEGORIES_URL,
    PRODUCT_GET_ALL_PROPERTIES_VALUES_URL,
    PRODUCT_GET_SINGLE_PRODUCT_URL,
    PRODUCT_ADDWISHLIST_URL,
    PRODUCT_SEND_ORDER_URL,
    PRODUCT_SEND_REVIEW_URL,
    PRODUCT_GET_PRODUCT_REVIEWS_URL,
    PRODUCT_TOGGLE_LIKE_URL,
    PRODUCT_TOGGLE_UNLIKE_URL,
    PRODUCT_GET_MY_WISHLIST_URL,
    PRODUCT_REMOVE_FROM_WISHLIST_URL,
    PRODUCT_GET_DISCOUNT_PRODUCTS_URL,
    PRODUCT_GET_NEW_PRODUCTS_URL,
    PRODUCT_GET_HISTORY_URL,
    PRODUCT_REMOVE_FROM_HISTORY_URL,
    PRODUCT_GET_SINGLE_ITEM_FOR_EDIT_URL,
    PRODUCT_GET_ALL_PRODUCT_URL,
    CATEGORIZED_PRODUCT_DELETE_ITEM_URL,
    CATEGORIZED_PRODUCT_GET_ITEMS_URL,
    CATEGORIZED_PRODUCT_ADD_ITEM_URL,
    CATEGORIZED_PRODUCT_UPDATE_ITEM_URL,
    CATEGORIZED_PRODUCT_ADD_PRODUCT_URL,
    CATEGORIZED_PRODUCT_UPDATE_PRODUCT_URL,
    CATEGORIZED_PRODUCT_REMOVE_PRODUCT_URL,
    PRODUCT_POPULAR_DELETE_URL,
    PRODUCT_POPULAR_GET_ALL_URL,
    PRODUCT_POPULAR_ADD_NEW_URL,
    PRODUCT_POPULAR_UPDATE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [PRODUCT_POPULAR_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_POPULAR_DELETE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_POPULAR_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_POPULAR_GET_ALL_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_POPULAR_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_POPULAR_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_POPULAR_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_POPULAR_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_UPDATE_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_UPDATE_PRODUCT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_REMOVE_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_REMOVE_PRODUCT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_ADD_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_ADD_PRODUCT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_GET_ITEMS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_ADD_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_ADD_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [CATEGORIZED_PRODUCT_UPDATE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CATEGORIZED_PRODUCT_UPDATE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_ALL_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_ALL_PRODUCT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_SINGLE_ITEM_FOR_EDIT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_SINGLE_ITEM_FOR_EDIT_URL + '/' + payload.id,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_REMOVE_FROM_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_REMOVE_FROM_HISTORY_URL + '/' + payload.id,
            method: "DELETE",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_HISTORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_HISTORY_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_NEW_PRODUCTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_NEW_PRODUCTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_DISCOUNT_PRODUCTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_DISCOUNT_PRODUCTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_REMOVE_FROM_WISHLIST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_REMOVE_FROM_WISHLIST_URL + '/' + payload.id,
            method: "DELETE",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_MY_WISHLIST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_MY_WISHLIST_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_TOGGLE_LIKE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_TOGGLE_LIKE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_TOGGLE_UNLIKE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_TOGGLE_UNLIKE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_PRODUCT_REVIEWS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_PRODUCT_REVIEWS_URL + '/' + payload.id,
            method: "GET",
            data: payload,
        });
    },
    [PRODUCT_SEND_REVIEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_SEND_REVIEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_SEND_ORDER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_SEND_ORDER_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_ADDWISHLIST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_ADDWISHLIST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_SINGLE_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_SINGLE_PRODUCT_URL + '/' + payload.id,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_ALL_PROPERTIES_VALUES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_ALL_PROPERTIES_VALUES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_ALL_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_ALL_CATEGORIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_DELETE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_DELETE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_MY_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_MY_CATEGORIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [PRODUCT_GET_MY_PRODUCT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + PRODUCT_GET_MY_PRODUCT_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};