<template>
  <AdminContainer>
    <div class="wrapper" v-if="dashboard_data">
      <div class="card-items">
        <div class="item">
          <div class="card-icon" style="background-color: rgb(103, 119, 239)">
            <span class="fa fa-user"></span>
          </div>
          <div class="card-content">
            <h4>Our user</h4>
            <span>{{ dashboard_data.total_user }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #fc544b">
            <span class="fa fa-shopping-cart"></span>
          </div>
          <div class="card-content">
            <h4>Total Orders</h4>
            <span>{{ dashboard_data.total_orders }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #ffa426">
            <span class="fa fa-shopping-basket"></span>
          </div>
          <div class="card-content">
            <h4>Our shops</h4>
            <span>{{ dashboard_data.total_shops }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #47c363">
            <span class="fa fa-diamond"></span>
          </div>
          <div class="card-content">
            <h4>Our products</h4>
            <span>{{ dashboard_data.total_products }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #0e9594">
            <span class="fa fa-home"></span>
          </div>
          <div class="card-content">
            <h4>Our Seller</h4>
            <span>{{ dashboard_data.total_seller }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #562c2c">
            <span class="fa fa-handshake-o"></span>
          </div>
          <div class="card-content">
            <h4>Our Client</h4>
            <span>{{ dashboard_data.total_client }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #0a2463">
            <span class="fa fa-facebook"></span>
          </div>
          <div class="card-content">
            <h4>Facebook</h4>
            <span>{{ dashboard_data.total_facebook }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #eac435">
            <span class="fa fa-google"></span>
          </div>
          <div class="card-content">
            <h4>Google</h4>
            <span>{{ dashboard_data.total_google }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #000">
            <span class="fa fa-money"></span>
          </div>
          <div class="card-content">
            <h4>OUR Affiliate</h4>
            <span>{{ dashboard_data.total_affiliated }}</span>
          </div>
        </div>
        <div class="item">
          <div class="card-icon" style="background-color: #9d17a6">
            <span class="fa fa-phone"></span>
          </div>
          <div class="card-content">
            <h4>OUR Contacts</h4>
            <span>{{ dashboard_data.total_contacts }}</span>
          </div>
        </div>

        <div class="item">
          <div class="card-icon" style="background-color: #687909">
            <span class="fa fa-envelope"></span>
          </div>
          <div class="card-content">
            <h4>EMAIL IN QUEUE</h4>
            <span>{{ dashboard_data.email_in_queue }}</span>
          </div>
        </div>
      </div>
      <div class="statistics-items">
        <div class="statistic-item" style="border: 1px solid #47c363">
          <div class="item-header" style="background: #47c363">
            This week orders
          </div>
          <div class="item-body">
            <apexchart width="99%" type="bar" :options="orderOptions" :series="order_data"></apexchart>
          </div>
        </div>
        <div class="statistic-item" style="border: 1px solid #6777ef">
          <div class="item-header" style="background: #6777ef">
            This week shops
          </div>
          <div class="item-body">
            <apexchart width="99%" type="area" :options="shopOptions" :series="shops_data"></apexchart>
          </div>
        </div>
        <div class="statistic-item" style="border: 1px solid rgb(252, 84, 75)">
          <div class="item-header" style="background: rgb(252, 84, 75)">
            Last registered user
          </div>
          <div class="item-body">
            <ul class="user">
              <li v-for="(item, index) in dashboard_data.last_created_user" :key="index">
                <div class="names">
                  <img v-if="
                    (item.profile_image && item.account_type == 'facebook') ||
                    item.account_type == 'google'
                  " :src="$store.state.SITE_ASSETS_BASE_URL +
                      'assets/uploaded/' +
                      item.profile_image
                      " />
                  <img v-else-if="item.profile_image" :src="$store.state.SITE_ASSETS_BASE_URL +
                    'assets/uploaded/' +
                    item.profile_image
                    " />
                  <img v-else src="@/assets/images/icons/avatar.svg" alt="" />
                  {{ item.first_name }} {{ item.last_name }}
                </div>
                <div class="time">{{ item.created_at }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="statistic-item" style="border: 1px solid #ffa426">
          <div class="item-header" style="background: #ffa426">Last order</div>
          <div class="item-body">
            <ul class="orders">
              <li v-for="(item, index) in dashboard_data.last_orders" :key="index">
                <div class="names" v-if="item.receiver_user">
                  <img v-if="
                    (item.receiver_user.profile_image &&
                      item.receiver_user.account_type == 'facebook') ||
                    item.receiver_user.account_type == 'google'
                  " :src="$store.state.SITE_ASSETS_BASE_URL +
                      'assets/uploaded/' +
                      item.receiver_user.profile_image
                      " />
                  <img v-else-if="item.receiver_user.profile_image" :src="$store.state.SITE_ASSETS_BASE_URL +
                    'assets/uploaded/' +
                    item.receiver_user.profile_image
                    " />
                  <img v-else src="@/assets/images/icons/avatar.svg" alt="" /> to
                  <b>
                    {{ item.receiver_user.first_name }}
                    {{ item.receiver_user.last_name }}</b>
                </div>
                <div class="message">
                  {{ item.notification_message }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </AdminContainer>
</template>
<script>
import AdminContainer from "../views/AdminContainer.vue";
export default {
  components: {
    AdminContainer,
  },
  data: () => ({
    orderOptions: {
      colors: ["#47c363"],
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wensday",
          "Thusday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
    },
    shopOptions: {
      colors: ["#6777ef"],
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      xaxis: {
        categories: [
          "Monday",
          "Tuesday",
          "Wensday",
          "Thusday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
      },
    },
    order_data: [
      {
        name: "Orders",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    shops_data: [
      {
        name: "Shops",
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    dashboard_data: null,
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getDashbaordData();
    setInterval(() => {
      if (vm.$route.name == "dashboard") {
        vm.getDashbaordData();
      }
    }, 10000);
  },
  methods: {
    getDashbaordData() {
      let vm = this;
      vm.$store
        .dispatch("GET_DASHBOARD_DATA")
        .then((response) => {
          vm.dashboard_data = response.data.return;
          vm.order_data[0].data = vm.dashboard_data.this_week_orders;
          vm.shops_data[0].data = vm.dashboard_data.this_week_created_shops;
        })
        .catch((error) => {
          console.log("GET_DASHBOARD_DATA", { error });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  height: 100%;
  overflow-y: auto;

  .card-items {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    grid-gap: 20px;
    justify-content: space-between;

    .item {
      border: 1px solid #e4e3e3;
      border-radius: 5px;
      background: #fbfbfb;
      height: 100px;
      display: flex;
      align-items: center;
      padding: 0px 20px;

      .card-icon {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        .fa {
          color: #fff;
          font-size: 30px;
        }
      }

      .card-content {
        padding: 0px 7px;

        h4 {
          font-size: 13px;
          margin: 0px;
          font-weight: 800;
          text-transform: uppercase;
        }

        span {
          font-size: 14px;
          margin: 0px;
        }
      }
    }
  }

  .statistics-items {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 20px;
    justify-content: space-between;

    .statistic-item {
      border: 1px solid rgb(103, 119, 239);
      border-radius: 5px;

      .item-header {
        height: 40px;
        background: rgb(103, 119, 239);
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0px 20px;
      }

      .item-body {
        height: 320px;
        overflow-y: auto;

        ul.user {
          padding-left: 0px;
          margin-bottom: 0px;

          li {
            display: flex;
            align-items: center;
            padding: 10px 10px;
            border-bottom: 1px solid #ddd;

            .names {
              display: flex;
              align-items: center;
              margin-right: 10px;

              img {
                height: 40px;
                width: 40px;
                margin-right: 10px;
              }
            }
          }
        }

        ul.orders {
          padding-left: 0px;
          margin-bottom: 0px;

          li {
            display: flex;
            padding: 10px 10px;
            border-bottom: 1px solid #ddd;
            flex-direction: column;

            .names {
              display: flex;
              align-items: center;
              margin-right: 10px;

              b {
                padding-left: 4px;
              }

              img {
                height: 40px;
                width: 40px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .order-statistics {
    margin: 20px 0px;
    border: 1px solid #47c363;
    border-radius: 5px;

    .item-header {
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0px 20px;
    }
  }
}
</style>
