import {
    ACCOUNT_LOGIN,
    NOTIFICATION_GET_ALL,
    NOTIFICATION_MAKE_IT_SEEN,
    VERIFY_TOKEN,
    GET_DASHBOARD_DATA,
    GET_ALL_CAGURA_USER,
    ADMIN_USER_DELETE_ITEM,
    ADMIN_USER_GET_USERS,
    ADMIN_USER_ADD_NEW,
    ADMIN_USER_UPDATE,
    SAVE_PERMISSION_ITEM,
    ADMIN_GET_PERMSSION_LIST,
    ADMIN_CHANGE_PERMSSION,
    REPORT_GET_REPORTED_MOVIES,
    REPORT_DELETE_REPORTED_ITEM,
    REPORT_DELETE_REPORTED_POST,
    REPORT_DELETE_POST,
    REPORT_GET_BAD_POST,
    REPORT_PUT_ONLINE_POST
} from "../actions/user";

import {
    API_BASE_URL,
    ACCOUNT_LOGIN_URL,
    NOTIFICATION_GET_ALL_URL,
    NOTIFICATION_MAKE_IT_SEEN_URL,
    VERIFY_TOKEN_URL,
    GET_DASHBOARD_DATA_URL,
    GET_ALL_CAGURA_USER_URL,
    ADMIN_USER_DELETE_ITEM_URL,
    ADMIN_USER_GET_USERS_URL,
    ADMIN_USER_ADD_NEW_URL,
    ADMIN_USER_UPDATE_URL,
    SAVE_PERMISSION_ITEM_URL,
    ADMIN_GET_PERMSSION_LIST_URL,
    ADMIN_CHANGE_PERMSSION_URL,
    REPORT_GET_REPORTED_MOVIES_URL,
    REPORT_DELETE_REPORTED_ITEM_URL,
    REPORT_DELETE_REPORTED_POST_URL,
    REPORT_DELETE_POST_URL,
    REPORT_GET_BAD_POST_URL,
    REPORT_PUT_ONLINE_POST_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [REPORT_PUT_ONLINE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_PUT_ONLINE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REPORT_GET_BAD_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_GET_BAD_POST_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REPORT_DELETE_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_DELETE_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REPORT_DELETE_REPORTED_POST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_DELETE_REPORTED_POST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REPORT_DELETE_REPORTED_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_DELETE_REPORTED_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [REPORT_GET_REPORTED_MOVIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + REPORT_GET_REPORTED_MOVIES_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_CHANGE_PERMSSION]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_CHANGE_PERMSSION_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_GET_PERMSSION_LIST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_GET_PERMSSION_LIST_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [SAVE_PERMISSION_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SAVE_PERMISSION_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_USER_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_USER_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_USER_GET_USERS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_USER_GET_USERS_URL,
            method: "GET",
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_USER_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_USER_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ADMIN_USER_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + ADMIN_USER_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_ALL_CAGURA_USER]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_CAGURA_USER_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [GET_DASHBOARD_DATA]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_DASHBOARD_DATA_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [VERIFY_TOKEN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + VERIFY_TOKEN_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_MAKE_IT_SEEN]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_MAKE_IT_SEEN_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [NOTIFICATION_GET_ALL]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + NOTIFICATION_GET_ALL_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [ACCOUNT_LOGIN]: ({ commit, dispatch }, payload) => {
        return axios({
            url: API_BASE_URL + ACCOUNT_LOGIN_URL,
            method: "POST",
            data: payload,
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};