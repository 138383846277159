import {
    APP_NOTIFICATION_DELETE_ITEM,
    APP_NOTIFICATION_GET_ITEMS,
    APP_NOTIFICATION_ADD_NEW,
    APP_NOTIFICATION_UPDATE
} from "../actions/notification";

import {
    API_BASE_URL,
    APP_NOTIFICATION_DELETE_ITEM_URL,
    APP_NOTIFICATION_GET_ITEMS_URL,
    APP_NOTIFICATION_ADD_NEW_URL,
    APP_NOTIFICATION_UPDATE_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [APP_NOTIFICATION_DELETE_ITEM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + APP_NOTIFICATION_DELETE_ITEM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [APP_NOTIFICATION_GET_ITEMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + APP_NOTIFICATION_GET_ITEMS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [APP_NOTIFICATION_ADD_NEW]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + APP_NOTIFICATION_ADD_NEW_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
    [APP_NOTIFICATION_UPDATE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + APP_NOTIFICATION_UPDATE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.getUserAccessToken }
        });
    },
};


export default {
    mutations,
    actions,
    getters,
    state
};