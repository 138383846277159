<template>
  <div class="cagura-wrapper">
    <div class="selected-product" @click="showProduct()">
      <span v-if="!selected_product">No Product Selected</span>
      <span class="selected-product-image" v-else>
        <img
          :src="
            $store.state.ASSETS_BASE_URL +
            'assets/uploaded/' +
            selected_product.main_image
          "
        />
        {{ selected_product.product_name }}</span
      >
    </div>
    <div class="product-container" v-if="show_products_modal">
      <div class="products-modal">
        <div class="header">
          <input
            type="text"
            @keydown="searchProducts"
            placeholder="Search products"
          />
        </div>
        <div class="body">
          <div
            class="product-item"
            v-for="(item, index) in products_data"
            :key="index"
            :class="{ active: value == item.id }"
            @click="selectProduct(item)"
          >
            <div class="product-image">
              <img
                :src="
                  $store.state.ASSETS_BASE_URL +
                  'assets/uploaded/' +
                  item.main_image
                "
              />
            </div>
            <div class="product-content">
              <h3>{{ item.product_name }}</h3>
              <p class="price">FRW {{ item.price }}</p>
            </div>
          </div>
        </div>
        <div class="footer">
          <button @click="closeProductModal()" class="btn-success btn-close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "select-product",
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      products_data: [],
      products_data_tmp: [],
      show_products_modal: false,
      selected_product: null,
    };
  },
  watch: {
    show_products_modal(val) {
      let vm = this;
      if (val) {
        vm.getAllProducts();
      }
    },
  },
  mounted() {
    let vm = this;
    vm.selected_product = null;
    vm.getAllProducts();
  },
  destroyed() {},
  methods: {
    closeProductModal() {
      let vm = this;
      vm.show_products_modal = false;
    },
    showProduct() {
      let vm = this;
      vm.show_products_modal = true;
    },
    selectProduct(item) {
      let vm = this;
      vm.show_products_modal = false;
      vm.selected_product = item;
      vm.$emit("input", item.id);
      vm.$emit("onClose", null);
      vm.$emit("onSubmit", item);
    },
    searchProducts({ target }) {
      let vm = this;
      let quel = target.value;
      vm.products_data = vm.products_data_tmp.filter((item) => {
        return item.product_name.toLowerCase().indexOf(quel.toLowerCase()) >= 0;
      });
    },
    getAllProducts() {
      let vm = this;
      vm.$store
        .dispatch("PRODUCT_GET_ALL_PRODUCT", { type: vm.type })
        .then((response) => {
          if (response.data.status == "ok") {
            vm.products_data = response.data.return.map((item) => {
              vm.$store.state.eventBus.$set(item, "is_menu_open", false);
              if (item.id == vm.value) {
                vm.selected_product = item;
              }
              return item;
            });
            vm.products_data_tmp = vm.products_data;
          }
        })
        .catch(({ response }) => {
          console.error("PRODUCT_GET_ALL_PRODUCT", response);
        });
    },
  },
};
</script>


<style lang="scss" scoped>
.cagura-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .selected-product {
    border: 1px solid #ddd;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    .selected-product-image {
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
  }
  .product-container {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    background: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    justify-content: center;
    padding-top: 60px;
    .products-modal {
      width: 700px;
      height: 95%;
      background: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .header {
        padding: 10px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding: 10px;
        input {
          height: 40px;
          width: 100%;
          padding: 0px 10px;
          border-radius: 4px;
          border: 1px solid #ddd;
        }
      }
      .body {
        display: grid;
        grid-template-columns: 31.333% 31.333% 31.333%;
        grid-gap: 20px;
        height: calc(100% - 120px);
        padding: 10px;
        overflow-y: auto;
        .product-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid #ddd;
          cursor: pointer;
          border-radius: 5px;
          height: 280px;
          &:hover {
            background: rgb(241, 241, 241);
          }
          &.active {
            border: 1px solid #8cc63e;
            background: #e6ffc6;
          }
          .product-image {
            padding: 15px;
            img {
              width: 100%;
              height: 170px;
              object-fit: cover;
            }
          }
          .product-content {
            padding: 15px;
            width: 100%;
            h3 {
              overflow: hidden;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }
            p {
              color: #8cc63e;
            }
          }
        }
      }
      .footer {
        height: 50px;
        border-top: 1px solid #ddd;
        display: flex;
        align-items: center;
        padding: 0px 10px;
      }
    }
  }
}
</style>

