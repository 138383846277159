<template>
  <AdminContainer>
    <div class="wrapper-container">
      <div class="container-widget-header">
        <h3>ALL STORIES</h3>
        <div class="call-actions">
          <button class="btn-success" @click="addNew()">
            <img src="@/assets/images/icons/plus.svg" /> CREATE STORY
          </button>
        </div>
      </div>
      <div class="body-widget">
        <div class="table-widget">
          <table>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr v-for="(item, index) in stories_data" :key="index" :class="{ active: item.status == 'published' }">
              <td>{{ item.title }}</td>
              <td>
                <img width="80" :src="$store.state.SITE_ASSETS_BASE_URL +
                  'assets/uploaded/' +
                  item.image
                  " />
              </td>
              <td>{{ item.status }}</td>
              <td>
                <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="true"
                  class="shop-menu">
                  <button class="dropdown-toggle">
                    <img src="@/assets/images/icons/menu.svg" />
                  </button>
                  <div slot="dropdown" class="drop-down-menu">
                    <a class="dropdown-item" @click.prevent="editItem(item)">
                      <img src="@/assets/images/icons/edit.svg" /> Edit
                    </a>
                    <a class="dropdown-item" @click.prevent="deleteItem(item)">
                      <img src="@/assets/images/icons/trash.svg" /> Delete
                    </a>
                  </div>
                </dropdown-menu>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Add Modal -->
      <div class="overlay" v-if="show_form">
        <div class="form-widget">
          <div class="header">
            <h3 v-if="!form.id">Add new story</h3>
            <h3 v-else>Update story</h3>
          </div>
          <div class="body">
            <div class="input-group">
              <label>Title</label>
              <div class="input-widget">
                <input type="text" v-model="form.title" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group">
              <label>Description</label>
              <div class="input-widget">
                <textarea type="text" v-model="form.description" class="bg-cagura-textarea-form-control"></textarea>
              </div>
            </div>
            <div class="input-group">
              <label>Main image</label>
              <div class="input-widget">
                <upload-trigger :open="false" :enableinput="false" :required="false" :multiple="false"
                  location="assets/uploaded/" :url="$store.state.SITE_ASSETS_BASE_URL"
                  :uploadurl="$store.state.BASE_URL + 'upload'" name="main-image" :value="form.main_image"
                  @onFileSelected="(file) => {
                      if (file) {
                        form.main_image = file;
                      }
                    }
                    ">
                  <div class="image-upload-widget">Choose Image to upload</div>
                </upload-trigger>
              </div>
            </div>
            <div class="input-group">
              <label>Story items</label>
              <div class="story-widget">
                <table>
                  <tr>
                    <th>Image</th>
                    <th>Type</th>
                    <th>Link</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="(item, index) in story_items" :key="index">
                    <td>
                      <img width="80" :src="$store.state.SITE_ASSETS_BASE_URL +
                        'assets/uploaded/' +
                        item.image
                        " />
                    </td>
                    <td>{{ item.type }}</td>
                    <td>
                      <a v-if="item.type == 'shop'" target="brank"
                        :href="`https://www.cagura.rw/shop/view/${item.ref_id}/shop`">Visit</a>
                      <a v-if="item.type == 'product'" target="brank"
                        :href="`https://www.cagura.rw/product/view/${item.ref_id}/product`">Visit</a>
                      <a v-if="item.type == 'movie'" target="brank"
                        :href="`https://www.cagura.rw/movies/watch/act/${item.ref_id}`">Visit</a>
                    </td>
                    <td>
                      <button @click="removeStory(index)" class="btn-default">
                        Remove
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <upload-trigger :open="false" :enableinput="false" :required="false" :multiple="false"
                        location="assets/uploaded/" :url="$store.state.SITE_ASSETS_BASE_URL"
                        :uploadurl="$store.state.BASE_URL + 'upload'" name="main-image" value="" @onFileSelected="(file) => {
                            if (file) {
                              story_form.image = file;
                            }
                          }
                          ">
                        <button class="btn-default">Add Image +</button>
                      </upload-trigger>
                    </td>
                    <td>
                      <select v-model="story_form.type" class="bg-cagura-form-control">
                        <option value="movie">Movie</option>
                        <option value="product">Products</option>
                        <option value="shop">Shop</option>
                      </select>
                    </td>
                    <td>
                      <!-- All Movies -->
                      <select v-model="story_form.ref_id" class="bg-cagura-form-control"
                        v-if="story_form.type == 'movie'">
                        <option v-for="(item, index) in movies_data" :key="index" :value="item.id">
                          {{ item.title }}
                        </option>
                      </select>
                      <!-- All Shop -->
                      <select v-model="story_form.ref_id" class="bg-cagura-form-control"
                        v-if="story_form.type == 'shop'">
                        <option v-for="(item, index) in shops_data" :key="index" :value="item.id">
                          {{ item.shop_name }}
                        </option>
                      </select>
                      <!-- Products -->
                      <selectProductsModal v-if="story_form.type == 'product'" v-model="story_form.ref_id">
                      </selectProductsModal>
                    </td>
                    <td>
                      <button class="btn-success" @click="addStory()">
                        Add +
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="input-group">
              <label>Story Prio</label>
              <div class="input-widget">
                <input type="number" v-model="form.prio" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group">
              <label>Story Status</label>
              <div class="input-widget">
                <select v-model="form.status" class="bg-cagura-form-control">
                  <option value="pending">Pending</option>
                  <option value="published">Published</option>
                </select>
              </div>
            </div>
          </div>
          <div class="footer">
            <c-button :loading="loading" @click="submitForm()" class="btn-success">Save</c-button>
            <button @click="show_form = false" class="btn-default">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AdminContainer>
</template>

<script>
export default {
  components: {},
  data: () => ({
    loading: false,
    form: {
      id: null,
      title: "",
      description: "",
      main_image: "",
      story_items: "",
      prio: "1",
      status: "pending",
    },
    story_items: [],
    story_form: {
      image: "",
      type: "shop",
      ref_id: null,
    },
    stories_data: [],
    show_form: false,
    movies_data: [],
    shops_data: [],
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getMovies();
    vm.getShops();
    vm.getSectionData();
  },
  methods: {
    removeStory(index) {
      let vm = this;
      vm.story_items.splice(index, 1);
    },
    addStory() {
      let vm = this;
      const rules = {
        image: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Please add story image";
          },
        },
        ref_id: {
          run: (value) => {
            if (value) {
              return false;
            }
            return "Please select story link";
          },
        },
      };
      if (vm.$isFieldsValidated(vm.story_form, rules)) {
        let obj = {
          image: vm.story_form.image,
          type: vm.story_form.type,
          ref_id: vm.story_form.ref_id,
        };
        vm.story_items.push(obj);
      }
    },
    getShops() {
      let vm = this;
      vm.is_loading = true;
      vm.$store
        .dispatch("SHOP_GET_ALL")
        .then((response) => {
          vm.is_loading = false;
          if (response.data.status == "ok") {
            vm.shops_data = response.data.return;
          }
        })
        .catch(({ response }) => {
          vm.is_loading = false;
          console.error("SHOP_GET_ALL", response);
        });
    },
    getMovies() {
      let vm = this;
      vm.$store
        .dispatch("GET_ALL_MOVIES_DATA")
        .then((response) => {
          vm.movies_data = response.data.return;
        })
        .catch(({ response }) => {
          console.log("GET_ALL_MOVIES_DATA", { error });
        });
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("STORY_DELETE", { id: id })
        .then((response) => {
          vm.getSectionData();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("STORY_DELETE", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.title = item.title;
      vm.form.description = item.description;
      vm.form.main_image = item.image;
      vm.form.story_items = item.story_items;
      vm.story_items = JSON.parse(item.story_items);
      vm.form.prio = item.prio;
      vm.form.status = item.status;
      vm.show_form = true;
    },
    getSectionData() {
      let vm = this;
      vm.$store
        .dispatch("STORY_GET_ALL")
        .then((response) => {
          vm.stories_data = response.data.return.map((item) => {
            vm.$store.state.eventBus.$set(item, "is_menu_open", false);
            return item;
          });
        })
        .catch(({ response }) => {
          console.log("STORY_GET_ALL", { error });
        });
    },
    submitForm() {
      let vm = this;
      vm.loading = true;
      vm.form.story_items = JSON.stringify(vm.story_items);
      if (!vm.form.id) {
        vm.$store
          .dispatch("STORY_ADD_NEW", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getSectionData();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("STORY_ADD_NEW", { error });
          });
      } else {
        vm.$store
          .dispatch("STORY_UPDATE", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getSectionData();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("STORY_UPDATE", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_form = true;
      vm.story_items = [];
      vm.form = {
        id: null,
        title: "",
        description: "",
        main_image: "",
        story_items: "",
        prio: 1,
        status: "pending",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .call-actions {
      display: flex;

      button {
        margin-left: 10px;
      }
    }
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        tr {
          &.active {
            background: rgb(79, 238, 217);
          }
        }

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 800px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;

        .story-widget {
          table {
            width: 100%;

            tr {
              th {
                border: 1px solid #ddd;
                padding: 5px;
              }

              td {
                border: 1px solid #ddd;
                padding: 5px;

                select {
                  height: 40px;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
