<template>
  <AdminContainer>
    <div class="wrapper-container">
      <div class="container-widget-header">
        <h3>APP NOTIFICATION</h3>
        <div class="call-actions">
          <button class="btn-success" @click="addNew()">
            <img src="@/assets/images/icons/plus.svg" /> CREATE NOTIFICATION
          </button>
        </div>
      </div>
      <div class="body-widget">
        <div class="table-widget">
          <table>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Status</th>
              <th>Start date</th>
              <th></th>
            </tr>
            <tr v-for="(item, index) in notification_data" :key="index">
              <td>{{ item.title }}</td>
              <td>
                <img class="affiliate-image" :src="$store.state.ASSETS_BASE_URL +
                  'assets/uploaded/' +
                  item.image
                  " />
              </td>
              <td>{{ item.status }}</td>
              <td>{{ item.active_time }}</td>
              <td>
                <dropdown-menu v-model="item.is_menu_open" :right="false" :hover="false" :interactive="false"
                  class="shop-menu">
                  <button class="dropdown-toggle">
                    <img src="@/assets/images/icons/menu.svg" />
                  </button>
                  <div slot="dropdown" class="drop-down-menu">
                    <a class="dropdown-item" @click.prevent="editItem(item)">
                      <img src="@/assets/images/icons/edit.svg" /> Edit
                    </a>
                    <a class="dropdown-item" @click.prevent="deleteItem(item)">
                      <img src="@/assets/images/icons/trash.svg" /> Delete
                    </a>
                  </div>
                </dropdown-menu>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- Add Modal -->
      <div class="overlay" v-if="show_form">
        <div class="form-widget">
          <div class="header">
            <h3 v-if="!form.id">Add new notification</h3>
            <h3 v-else>Update notification</h3>
          </div>
          <div class="body">
            <div class="input-group">
              <label>Title</label>
              <div class="input-widget">
                <input type="text" v-model="form.title" class="bg-cagura-form-control" />
              </div>
            </div>
            <div class="input-group">
              <label>Message</label>
              <div class="input-widget">
                <textarea type="text" v-model="form.message" class="bg-cagura-textarea-form-control"></textarea>
              </div>
            </div>
            <div class="input-group">
              <label>Image</label>
              <div class="input-widget">
                <upload-trigger :open="false" :enableinput="false" :required="false" :multiple="false"
                  location="assets/uploaded/" :url="$store.state.SITE_ASSETS_BASE_URL"
                  :uploadurl="$store.state.BASE_URL + 'upload'" name="main-image" :value="form.main_image"
                  @onFileSelected="onImageSelected">
                  <div class="image-upload-widget">Choose Image to upload</div>
                </upload-trigger>
              </div>
            </div>
            <div class="input-group">
              <label>Link</label>
              <div class="input-widget">
                <input type="text" v-model="form.link" class="bg-cagura-form-control" />
              </div>
            </div>

            <div class="input-group">
              <label>Type</label>
              <div class="input-widget">
                <select type="text" v-model="form.type" class="bg-cagura-form-control">
                  <option value="youtube">Youtube</option>
                  <option value="link">Link</option>
                  <option value="announcement">Announcement</option>
                </select>
              </div>
            </div>

            <div class="input-group">
              <label>Status</label>
              <div class="input-widget">
                <select type="text" v-model="form.status" class="bg-cagura-form-control">
                  <option value="active">Active</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <div class="input-group">
              <label>Start Date</label>
              <div class="input-widget">
                <a-date-picker v-model="start_date" showTime size="large" @change="onChangeStartDate" />
              </div>
            </div>
          </div>
          <div class="footer">
            <c-button :loading="loading" @click="submitForm()" class="btn-success">Save</c-button>
            <button @click="show_form = false" class="btn-default">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </AdminContainer>
</template>

<script>
export default {
  components: {},
  data: () => ({
    menu: false,
    loading: false,
    form: {
      id: null,
      title: "",
      message: "",
      main_image: "",
      link: "",
      type: "",
      status: "",
      start_date: "",
    },
    start_date: "",
    show_form: false,
    notification_data: [],
  }),
  watch: {},
  mounted() {
    let vm = this;
    vm.getAllNotification();
  },
  methods: {
    onChangeStartDate(date, dateString) {
      let vm = this;
      vm.form.start_date = dateString;
    },
    confirmDelete(id) {
      let vm = this;
      vm.$store
        .dispatch("APP_NOTIFICATION_DELETE_ITEM", { id: id })
        .then((response) => {
          vm.getAllNotification();
          vm.$notify({
            group: "status",
            type: "success",
            title: "Important message",
            text: response.data.message,
          });
        })
        .catch(({ response }) => {
          console.error("APP_NOTIFICATION_DELETE_ITEM", { response });
          vm.$notify({
            group: "status",
            type: "warn",
            title: "OOPS!!!",
            text: response.data.message,
          });
        });
    },
    deleteItem(item) {
      let vm = this;
      vm.$confirm({
        title: "Confirm",
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            vm.confirmDelete(item.id);
          }
        },
      });
    },
    editItem(item) {
      let vm = this;
      vm.form.id = item.id;
      vm.form.title = item.title;
      vm.form.message = item.message;
      vm.form.main_image = item.image;
      vm.form.link = item.link;
      vm.form.type = item.type;
      vm.form.status = item.status;
      vm.form.start_date = item.active_time;
      vm.start_date = vm.moment(item.active_time);
      vm.show_form = true;
    },
    getAllNotification() {
      let vm = this;
      vm.$store
        .dispatch("APP_NOTIFICATION_GET_ITEMS")
        .then((response) => {
          vm.notification_data = response.data.return.map((item) => {
            vm.$store.state.eventBus.$set(item, "is_menu_open", false);
            return item;
          });
        })
        .catch(({ response }) => {
          console.log("APP_NOTIFICATION_GET_ITEMS", { error });
        });
    },
    submitForm() {
      let vm = this;
      vm.loading = true;
      if (!vm.form.id) {
        vm.$store
          .dispatch("APP_NOTIFICATION_ADD_NEW", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getAllNotification();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("APP_NOTIFICATION_ADD_NEW", { error });
          });
      } else {
        vm.$store
          .dispatch("APP_NOTIFICATION_UPDATE", vm.form)
          .then((response) => {
            vm.loading = false;
            vm.getAllNotification();
            vm.show_form = false;
            vm.$notify({
              group: "status",
              type: "success",
              title: "Important message",
              text: response.data.message,
            });
          })
          .catch(({ response }) => {
            vm.loading = false;
            vm.$notify({
              group: "status",
              type: "warn",
              title: "OOPS!!!",
              text: response.data.message,
            });
            console.log("APP_NOTIFICATION_UPDATE", { error });
          });
      }
    },
    addNew() {
      let vm = this;
      vm.show_form = true;
      vm.form = {
        id: null,
        title: "",
        message: "",
        main_image: "",
        link: "",
        type: "",
        status: "",
        start_date: "",
      };
    },
    onImageSelected(file) {
      let vm = this;
      vm.form.main_image = file;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;

  .container-widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .body-widget {
    height: calc(100vh - 122px);
    overflow-y: auto;

    .table-widget {
      width: 100%;
      padding: 0px 10px;

      table {
        width: 100%;

        th {
          height: 45px;
          background: #ddd;
          border: 1px solid #fff;
          padding: 15px;
        }

        td {
          border: 1px solid #ddd;
          padding: 15px;

          .shop-menu {
            position: relative;
          }

          .affiliate-image {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    padding-top: 80px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: unset;

    .form-widget {
      height: 600px;
      width: 400px;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;

      .header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;
        border-bottom: 1px solid #ccc;

        h3 {
          margin: 0px;
        }
      }

      .body {
        height: calc(100% - 120px);
        overflow-y: auto;
        padding: 15px;
      }

      .footer {
        background: rgb(250, 250, 250);
        border-top: 1px solid #ccc;
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0px 15px;

        button {
          margin-right: 20px;
        }
      }
    }
  }

  .on-fly-options {
    position: fixed;
    bottom: 20px;
    right: 20px;

    .btn-cycle {
      height: 50px !important;
      width: 50px !important;
      min-width: 50px !important;
      border-radius: 50%;
      font-size: 29px;
      background: #fff;
      box-shadow: 0px 0px 6px #000;
    }
  }
}
</style>
