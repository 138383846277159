import Vue from 'vue'
import Vuex from 'vuex'
import {
    BASE_URL,
    GOOGLE_API_KEY,
    GOOGLE_MAP_KEY,
    API_KITCHEN_BASE_URL
} from "./variables";
import User from './modules/user.js'
import Upload from './modules/upload.js'
import Shop from './modules/shop.js'
import Product from './modules/product.js'
import Affiliate from './modules/affiliate.js'
import Contacts from './modules/contacts.js'
import Deals from './modules/deals.js'
import Notification from "./modules/notification.js"
import Hack from "./modules/hack.js"
import Movie from "./modules/movie.js"
import Story from "./modules/story.js"



Vue.use(Vuex)
export default new Vuex.Store({
    state: () => ({
        sidebar: true,
        eventBus: new Vue(),
        access_token: null,
        BASE_URL: BASE_URL,
        SITE_ASSETS_BASE_URL: 'https://cagura-assets.b-cdn.net/',
        ASSETS_BASE_URL: 'https://cagura-assets.b-cdn.net/',
        API_KITCHEN_BASE_URL: API_KITCHEN_BASE_URL,
        GOOGLE_API_KEY: GOOGLE_API_KEY,
        GOOGLE_MAP_KEY: GOOGLE_MAP_KEY,
        main_loader: false,
    }),
    mutations: {
        ["SET_TOKEN"]: (state, payload) => {
            state.access_token = payload;
        },
        ["START_LOADER"]: (state) => {
            state.main_loader = true;
        },
        ["STOP_LOADER"]: (state) => {
            state.main_loader = false;
        },
    },
    getters: {
        getUserAccessToken(state) {
            return state.access_token;
        }
    },
    actions: {},
    modules: {
        User,
        Upload,
        Shop,
        Product,
        Affiliate,
        Contacts,
        Deals,
        Notification,
        Hack,
        Movie,
        Story
    }
})